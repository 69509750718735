import always from "./always";
import assoc from "./assoc";
import assocWith from "./assocWith";
import compose from "./compose";
import concat from "./concat";
import prop from "./prop";
import unless from "./unless";

const ensureUrlPropExists = unless(prop("url"), assoc("url", ""));

// ({k: v} -> String) -> {k: v} -> {k: v}
const extendURL = (fun) =>
  compose(
    assocWith(({ url }) => always(url), "toString"),
    assocWith(({ url, ...props }) => concat(fun(props))(url), "url"),
    ensureUrlPropExists
  );

export default extendURL;

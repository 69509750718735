import gql from "graphql-tag";

export default gql`
  mutation SetNotificationRead($id: ID!, $isRead: Boolean!) {
    setNotificationRead(input: { id: $id, isRead: $isRead }) {
      notification {
        id
        isRead
      }
    }
  }
`;

import React from "react";
import PropTypes from "prop-types";
import { Badge } from "antd";
import { propOr } from "../misc/fp";
import { TWO_TONE_COLORS, COLORS } from "../misc/constants";

const MAX_COUNT = 500;

const DEFAULT_COLOR = "white";

const CustomBadge = ({ theme, color, style, ...props }) => {
  let styleWithColors;
  if (theme === "twoTone") {
    styleWithColors = {
      ...style,
      ...propOr(TWO_TONE_COLORS[DEFAULT_COLOR], color, TWO_TONE_COLORS),
    };
  } else {
    styleWithColors = {
      ...style,
      backgroundColor: propOr(COLORS[DEFAULT_COLOR], color, COLORS),
    };
  }
  return <Badge style={styleWithColors} {...props} />;
};
CustomBadge.defaultProps = {
  showZero: true,
  overflowCount: MAX_COUNT,
  theme: "filled",
  style: {},
  color: undefined,
};
CustomBadge.propTypes = {
  showZero: PropTypes.bool,
  overflowCount: PropTypes.number,
  style: PropTypes.shape({}),
  color: PropTypes.string,
  theme: PropTypes.oneOf(["filled", "twoTone"]),
};

export const AntBadge = Badge;

export default CustomBadge;

import gql from "graphql-tag";
import { defaultTo, lensPath, json, view, compose } from "../misc/fp";

const colorPaletteLens = lensPath(["settings", "highchartsColorPalette"]);
export const getColorPalette = view(colorPaletteLens);

export const getXAxisDefaults = compose(
  json.parse,
  defaultTo("{}"),
  view(lensPath(["settings", "highchartsXAxisDefaultOptions"]))
);
export const getYAxisDefaults = compose(
  json.parse,
  defaultTo("{}"),
  view(lensPath(["settings", "highchartsYAxisDefaultOptions"]))
);

export default gql`
  query ChartSettingsQuery {
    settings {
      highchartsColorPalette
      highchartsXAxisDefaultOptions
      highchartsYAxisDefaultOptions
    }
  }
`;

import { PERMISSION_MESSAGES_KEY } from "./internal/constants";
import errorsLens from "./errorsLens";
import { compose, lensPath, traversed, listOf, reject, isNil } from "../fp";

export const permissionMessagesLens = compose(
  errorsLens,
  traversed,
  lensPath([PERMISSION_MESSAGES_KEY])
);

// {k: v} -> {a: [String]}
const getPermissionErrorMessage = compose(
  reject(isNil),
  // if error is not permission error it will still return undefined from
  // permissionMessageLens
  listOf(permissionMessagesLens)
);

export default getPermissionErrorMessage;

import gql from "graphql-tag";

export default gql`
  fragment AppSearchFragment on SearchConnection {
    edges {
      node {
        __typename
        ... on Asset {
          id
          description: Description
        }
        ... on Registry {
          id
          description: Description
        }
        ... on DataSet {
          id
          type {
            id
            uuid
          }
          description: Description
        }
        ... on Chart {
          id
          description: Description
        }
        ... on Dashboard {
          id
          description: Description
        }
        ... on ExternalDataset {
          id
          description: Description
        }
        ... on DataConnection {
          id
          description: Description
        }
        ... on Sample {
          id
          description: Description
        }
        ... on TimeSeries {
          id
          description: Description
        }
        ... on ParameterTemp {
          id
          description: Description
        }
        ... on BoreholeMeasurementSeries {
          id
          description: Description
        }
        ... on BoreholeMeasurementBaseCurve {
          id
          description: Description
        }
      }
    }
  }
`;

import { useEffect, useRef } from "react";

import { noop } from "../misc/fp";

const SOURCE =
  "https://hugfimi.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-rfrvv0/b/6/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=221fa4e3";

const useIssueCollectorTrigger = () => {
  const dialogRef = useRef(noop);
  useEffect(() => {
    let showCollectorDialog;

    window.ATL_JQ_PAGE_PROPS = {
      triggerFunction: (dialog) => {
        showCollectorDialog = dialog;
      },
    };

    dialogRef.current = () => {
      showCollectorDialog();
    };

    const script = document.createElement("script");
    script.src = SOURCE;
    document.head.appendChild(script);
  });

  return () => dialogRef.current();
};

export default useIssueCollectorTrigger;

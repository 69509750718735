import gql from "graphql-tag";
import { path, isNotNil, compose } from "../misc/fp";

export const resultsKey = "notificationCreated";

export const hasNewNotification = compose(isNotNil, path([resultsKey, "id"]));
export const getTitle = path([resultsKey, "title"]);
export const getBody = path([resultsKey, "body"]);
export const getTimestamp = path([resultsKey, "timestamp"]);

export default gql`
  subscription NotificationCreated($userId: ID!) {
    notificationCreated(userId: $userId) {
      id
      isRead
      title
      body
      timestamp
      __typename
    }
  }
`;

import { ConfigProvider } from "antd";

import antdIs from "antd/lib/locale-provider/is_IS";
import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import { compose, withProps } from "recompose";
import enTranslations from "../../../translations/locales/en.json";

import isTranslations from "../../../translations/locales/is.json";

import Spin from "../../components/Spin";
import useMutation from "../../hooks/useMutation";

import useQuery from "../../hooks/useQuery";
import Date from "../../misc/Date";
import SET_LANGUAGE_MUTATION from "../../mutations/SetLanguageMutation";

import CURRENT_LANGUAGE_QUERY, {
  getCurrentLanguage,
} from "../../queries/CurrentLanguageQuery";

export const LocaleContext = React.createContext();

const LanguageContext = ({ locale, children }) => {
  const [setLocale] = useMutation(SET_LANGUAGE_MUTATION, {
    onCompleted: () => window.location.reload(),
  });
  const icelandic = (
    <FormattedMessage id="locale.icelandic" defaultMessage="íslensku" />
  );
  const english = (
    <FormattedMessage id="locale.english" defaultMessage="ensku" />
  );
  const currentLanguage = locale === "is" ? icelandic : english;
  const otherLanguage = locale === "is" ? english : icelandic;
  const otherLocale = locale === "is" ? "en" : "is";
  return (
    <LocaleContext.Provider
      value={{
        currentLanguage,
        otherLanguage,
        locale,
        otherLocale,
        toggleLocale: () => setLocale({ variables: { language: otherLocale } }),
      }}
    >
      {React.Children.only(children)}
    </LocaleContext.Provider>
  );
};

const LanguageProvider = ({ messages, children }) => {
  const { data, loading } = useQuery(CURRENT_LANGUAGE_QUERY);
  if (loading) return <Spin.Page />;

  const locale = getCurrentLanguage(data);
  Date.locale(locale); // set locale for Date (moment)
  return (
    <ConfigProvider locale={locale === "is" ? antdIs : undefined}>
      <IntlProvider
        textComponent="span"
        locale={locale}
        key={locale}
        messages={messages[locale]}
      >
        <LanguageContext locale={locale}>
          {React.Children.only(children)}
        </LanguageContext>
      </IntlProvider>
    </ConfigProvider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

const translations = {
  en: enTranslations,
  is: isTranslations,
};

const enhance = compose(
  withProps({
    messages: translations,
  })
);

export default enhance(LanguageProvider);

import assoc from "./assoc";
import curry from "./curry";

/*
Note:
  There is a PR for this function in ramda. That functionality is
  implemented so that `assoc` uses `assocWith` in it's implementation which
  is more natural than this setup.
  Hence use ramda provided function once it has been implemented and we have
  upgraded.
 */

// (a -> b) -> String -> {k: v} -> {k: v}
const assocWith = curry((func, prop, o) => assoc(prop, func(o), o));

export default assocWith;

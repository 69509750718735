import Highcharts from "highcharts";
import React from "react";
import { publicClient } from "../../../services/client";
import useIntl from "../../hooks/useIntl";
import useQuery from "../../hooks/useQuery";
import CHART_SETTINGS_QUERY, {
  getColorPalette,
  getXAxisDefaults,
  getYAxisDefaults,
} from "../../queries/ChartSettingsQuery";
import { globalOptions } from "./defaults";

const HighchartsOptions = ({
  intl,
  colorPalette,
  xAxisDefaults,
  yAxisDefaults,
  children,
}) => {
  Highcharts.setOptions(
    globalOptions({ intl, colorPalette, xAxisDefaults, yAxisDefaults })
  );
  return children;
};

const ChartProvider = ({ children }) => {
  const intl = useIntl();
  const { data } = useQuery(CHART_SETTINGS_QUERY, {
    client: publicClient,
  });
  return (
    <HighchartsOptions
      intl={intl}
      colorPalette={getColorPalette(data)}
      xAxisDefaults={getXAxisDefaults(data)}
      yAxisDefaults={getYAxisDefaults(data)}
    >
      {children}
    </HighchartsOptions>
  );
};

export default ChartProvider;

import { EyeOutlined, NotificationOutlined } from "@ant-design/icons";
import {urlPrefix} from "../../constants";
import { color } from "../../constants";
import Badge from "./Badge";
import Markdown from "./Markdown";
import React from "react";
import Tabs from "./Tabs";
import { defineMessages, FormattedMessage } from "react-intl";
import { isNil, prop } from "../misc/fp";
import useEffect from "../hooks/useEffect";
import useMemo from "../hooks/useMemo";
import useTranslations from "../hooks/useTranslations";
import styled from "styled-components";
import sendNotification from "./notification";
import MARK_ALL_NOTIFICATIONS_READ_MUTATION from "../mutations/MarkAllNotificationsReadMutation";
import NOTIFICATIONS_QUERY, {
  nodes,
  resultsKey,
  totalCount,
} from "../queries/NotificationsQuery";
import NOTIFICATION_CREATED, {
  resultsKey as notificationCreatedResultsKey,
} from "../subscriptions/NotificationCreatedSubscription";
import usePaginatedQuery from "../hooks/usePaginatedQuery";
import useMutation from "../hooks/useMutation";
import NotificationsList from "./NotificationsList";
import { BrowserRouter } from "react-router-dom";
import Drawer from "./Drawer";
import { width, height } from "./GlobalNav";

const PAGE_SIZE = 15;

const { TabPane } = Tabs;

const TabFooterHeight = "49px";
const TabFooter = styled.div`
  height: ${TabFooterHeight};
  line-height: ${TabFooterHeight};
  text-align: center;
  color: ${(props) => (props.disabled ? color.textMuted : color.text)};
  transition: all 0.3s;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    color: ${color.text};
  }
`;

const useNotificationsQuery = ({ userId, isRead = undefined }) => {
  const { variables, ...rest } = usePaginatedQuery({
    query: NOTIFICATIONS_QUERY,
    resultsKey,
    variables: { userId, first: PAGE_SIZE, isRead },
    skip: isNil(userId),
  });

  const refetch = useMemo(() => [{ query: NOTIFICATIONS_QUERY, variables }]); //eslint-disable-line react-hooks/exhaustive-deps

  return { ...rest, variables, refetch };
};

export const useNumUnreadNotifications = (userId) => {
  const unreadNotificationsQuery = useNotificationsQuery({
    userId,
    isRead: false,
  });
  return totalCount(unreadNotificationsQuery.data);
};

const NotificationsDrawer = ({ userId, placement, ...props }) => {
  const messages = useTranslations(
    defineMessages({
      unreadTitle: {
        id: "notifications.tab.title.unread",
        defaultMessage: "Unread ({count})",
      },
      unreadEmpty: {
        id: "notifications.tab.empty.unread",
        defaultMessage: "No unread notifications",
      },
      allTitle: {
        id: "notifications.tab.title.all",
        defaultMessage: "All ({count})",
      },
      allEmpty: {
        id: "notifications.tab.empty.all",
        defaultMessage: "No notifications",
      },
    })
  );

  const queryData = useNotificationsQuery({ userId });
  const unreadQueryData = useNotificationsQuery({ userId, isRead: false });
  const refetchAll = useMemo(
    () => [...queryData.refetch, ...unreadQueryData.refetch],
    [queryData.refetch, unreadQueryData.refetch]
  );

  useEffect(() => {
    // TODO figure out why messages are duplicated if user has multiple tabs open
    // TODO figure out how to fix, links don't work in notifs
    const unsubscribe = unreadQueryData.subscribeToMore(NOTIFICATION_CREATED, {
      subscriptionResultKey: notificationCreatedResultsKey,
      onNewResult: (notification) =>
        sendNotification.info({
          placement: "topLeft",
          style: placement === "left" ? { left: width } : { bottom: height },
          message: notification.title,
          description: (
            <BrowserRouter basename={urlPrefix}>
              <Markdown source={notification.body} />
            </BrowserRouter>
          ),
        }),
    });
    const unsubscribeRead = queryData.subscribeToMore(NOTIFICATION_CREATED, {
      subscriptionResultKey: notificationCreatedResultsKey,
    });

    return () => {
      unsubscribe();
      unsubscribeRead();
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const [markAllRead, { loading: markAllReadLoading }] = useMutation(
    MARK_ALL_NOTIFICATIONS_READ_MUTATION,
    {
      refetchQueries: refetchAll,
      name: "markAllNotificationsRead",
    }
  );

  const tabStyle = { maxHeight: "100vh" };
  return (
    <Drawer
      placement={placement}
      noPadding
      footer={
        <TabFooter
          onClick={markAllRead}
          disabled={totalCount(unreadQueryData.data) === 0}
        >
          <span>
            <EyeOutlined style={{ marginRight: "8px" }} />
            <FormattedMessage
              id="notifications.unread.markallread"
              defaultMessage="Mark all read"
            />
          </span>
        </TabFooter>
      }
      {...props}
    >
      <Tabs
        centered
        tabBarStyle={{ marginBottom: 0 }}
        tabBarGutter={48}
        style={{ height: "100%" }}
      >
        <TabPane
          tab={
            <span>
              <span style={{ marginRight: "8px" }}>
                <Badge dot color="red">
                  <NotificationOutlined style={{ marginRight: 0 }} />
                </Badge>
              </span>
              {messages.unreadTitle({
                count: totalCount(unreadQueryData.data),
              })}
            </span>
          }
          key="0"
          style={tabStyle}
        >
          <NotificationsList
            dataSource={nodes(unreadQueryData.data)}
            fetchMore={unreadQueryData.fetchMore}
            hasMore={unreadQueryData.hasMore}
            networkStatus={unreadQueryData.networkStatus}
            loading={unreadQueryData.loading}
            pageSize={prop("first", unreadQueryData.variables)}
            locale={{
              emptyText: messages.unreadEmpty(),
            }}
            refetch={refetchAll}
            itemsLoading={markAllReadLoading}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <NotificationOutlined />
              {messages.allTitle({ count: totalCount(queryData.data) })}
            </span>
          }
          key="1"
          style={tabStyle}
        >
          <NotificationsList
            dataSource={nodes(queryData.data)}
            fetchMore={queryData.fetchMore}
            hasMore={queryData.hasMore}
            networkStatus={queryData.networkStatus}
            loading={queryData.loading}
            pageSize={prop("first", queryData.variables)}
            locale={{
              emptyText: messages.allEmpty(),
            }}
            itemsLoading={markAllReadLoading}
            refetch={unreadQueryData.refetch}
          />
        </TabPane>
      </Tabs>
    </Drawer>
  );
};

export default NotificationsDrawer;

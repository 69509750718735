import React from "react";
import useIntl from "./useIntl";

import { map } from "../misc/fp";

const defaultVariables = {
  p: (...chunks) => <p>{chunks}</p>,
  b: (...chunks) => <b>{chunks}</b>,
  br: <br />,
};

const useTranslations = (translations) => {
  const intl = useIntl();
  return map(
    (message) =>
      (variables = {}) =>
        intl.formatMessage(message, { ...defaultVariables, ...variables })
  )(translations);
};

export default useTranslations;

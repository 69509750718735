import both from "../../both";
import converge from "../../converge";
import equals from "../../equals";
import identity from "../../identity";
import isFinite from "../../isFinite";

const isIntegerPolyfill = both(
  isFinite,
  converge(equals, [Math.floor, identity])
);

export default isIntegerPolyfill;

import both from "./both";
import cond from "./cond";
import has from "./has";
import identity from "./identity";
import isArray from "./isArray";
import isObj from "./isObj";
import map from "./map";
import prop from "./prop";
import T from "./T";
import when from "./when";

const extractKeysFromObjects = map(
  cond([
    [isArray, map(when(has("key"), prop("key")))], // [{key: "k", ...}]
    [both(isObj, has("key")), prop("key")], // {key: "b", ...}
    [T, identity], // do nothing
  ])
);

export default extractKeysFromObjects;

import React from "react";
import PropTypes from "prop-types";
import { defineMessages } from "react-intl";

import Tooltip from "./Tooltip";

import { isNil } from "../misc/fp";

import Search from "./Input/SearchInput/index";
import useTranslations from "../hooks/useTranslations";
import useDebouncedCallback from "../hooks/useDebouncedCallback";

const SearchFilter = React.forwardRef(
  ({ updateValue, value, tooltip, placeholder, ...rest }, ref) => {
    const [handleChange] = useDebouncedCallback(updateValue, 300);
    const messages = useTranslations(
      defineMessages({
        defaultPlaceholder: {
          id: "searchFilter.defaultPlaceholder",
          defaultMessage: "Search...",
        },
      })
    );
    return (
      <Tooltip placement="bottom" title={tooltip}>
        <Search
          ref={ref}
          placeholder={
            isNil(placeholder) ? messages.defaultPlaceholder() : placeholder
          }
          style={{ width: "200px" }}
          handleChange={(e) => handleChange(e.target.value)}
          defaultValue={value}
          {...rest}
        />
      </Tooltip>
    );
  }
);

SearchFilter.propTypes = {
  value: PropTypes.string.isRequired,
  updateValue: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  placeholder: PropTypes.string,
};

SearchFilter.defaultProps = {
  tooltip: "",
  placeholder: undefined,
};

export default SearchFilter;

import gql from "graphql-tag";

export default gql`
  query MonitorBreadcrumbQuery($monitorId: ID!) {
    item: monitor(gid: $monitorId) {
      id
      description: Description
    }
  }
`;

import React from "react";
import { Spin as AntdSpin } from "antd";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const Spin = ({ style, ...props }) => (
  <Container {...props}>
    <AntdSpin style={style} />
  </Container>
);

export default Spin;

import gql from "graphql-tag";

export default gql`
  query RegistryBreadcrumbQuery($id: ID!) {
    item: registry(id: $id) {
      id
      description: Description
    }
  }
`;

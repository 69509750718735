import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import Button from "./Button";

export const SubmitMessage = (
  <FormattedMessage id="button.submit" defaultMessage="Submit" />
);

const SubmitButton = (props) => <Button {...props} />;
SubmitButton.propTypes = {
  ...Button.propTypes,
  type: PropTypes.string,
  icon: PropTypes.string,
  htmlType: PropTypes.string,
};
SubmitButton.defaultProps = {
  children: SubmitMessage,
  type: "primary",
  htmlType: "submit",
};

export default SubmitButton;

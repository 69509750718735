import { HomeOutlined } from "@ant-design/icons";
import React from "react";
import { Redirect } from "react-router-dom";

import { compose, liftKey, map, pick } from "../../shared/misc/fp";
// Breadcrumb description queries
import ASSET_BREADCRUMB_QUERY from "../../shared/queries/AssetBreadcrumbQuery";
import CHART_BREADCRUMB_QUERY from "../../shared/queries/ChartBreadcrumbQuery";
import COMPANY_BREADCRUMB_QUERY from "../../shared/queries/CompanyBreadcrumbQuery";
import COMPANY_MEMBERSHIP_BREADCRUMB_QUERY from "../../shared/queries/CompanyMembershipBreadcrumbQuery";
import DASHBOARD_BREADCRUMB_QUERY from "../../shared/queries/DashboardBreadcrumbQuery";
import DATA_CONNECTION_BREADCRUMB_QUERY from "../../shared/queries/DataConnectionBreadcrumbQuery";
import EXTERNAL_DATASET_BREADCRUMB_QUERY from "../../shared/queries/ExternalDatasetBreadcrumbQuery";
import REGISTRY_BREADCRUMB_QUERY from "../../shared/queries/RegistryBreadcrumbQuery";
import MONITOR_BREADCRUMB_QUERY from "../../shared/queries/MonitorBreadcrumbQuery";
import REGISTRY_TYPE_BREADCRUMB_QUERY from "../../shared/queries/RegistryTypeBreadcrumbQuery";
import TASK_BREADCRUMB_QUERY from "../../shared/queries/TaskBreadcrumbQuery";
import breadcrumbTranslations from "./breadcrumbTranslations";
import { timeSeriesModule } from "./modules";

/* eslint-disable */
const AddDataPage = React.lazy(() => import("../../screens/AddDataPage"));
const AssetOverview = React.lazy(() => import("../../screens/AssetOverview"));
const AssetPage = React.lazy(() => import("../../screens/AssetProfile"));
const LocatorDatasetPages = React.lazy(() =>
  import("../../screens/LocatorDatasetPages")
);
const AssetPageRedirect = React.lazy(() =>
  import("../../screens/AssetProfile/Redirect")
);
const ChartProfile = React.lazy(() => import("../../screens/ChartProfile"));
const ChartsPage = React.lazy(() => import("../../screens/ChartsPage"));
const CompanyPage = React.lazy(() => import("../../screens/CompanyPage"));
const CompanyMembershipPage = React.lazy(() =>
  import("../../screens/CompanyMembershipPage")
);
const DashboardPage = React.lazy(() => import("../../screens/DashboardPage"));
const DashboardsPage = React.lazy(() => import("../../screens/DashboardsPage"));
const DataConnectionPage = React.lazy(() =>
  import("../../screens/DataConnectionPage")
);
const DataConnectionsPage = React.lazy(() =>
  import("../../screens/DataConnectionsPage")
);
const DatasetPage = React.lazy(() => import("../../screens/DatasetPage"));
const DatasetPages = React.lazy(() => import("../../screens/DatasetPages"));
const DatasetsPage = React.lazy(() => import("../../screens/DatasetsPage"));
const ExternalDatasetPage = React.lazy(() =>
  import("../../screens/ExternalDatasetPage")
);
const ExternalDatasetsPage = React.lazy(() =>
  import("../../screens/ExternalDatasetsPage")
);
const Faces = React.lazy(() => import("../../screens/Faces"));
const ImageMap = React.lazy(() => import("../../screens/ImageMap"));
const QuakeMap = React.lazy(() => import("../../screens/QuakeMap"));
const RedirectToParameterProfile = React.lazy(() =>
  import("../../screens/SamplesParameterProfile/Redirect")
);
const RedirectToSampleProfile = React.lazy(() =>
  import("../../screens/SampleProfile/Redirect")
);
const RedirectToTimeSeriesProfile = React.lazy(() =>
  import("../../screens/TimeSeriesProfile/Redirect")
);
const RedirectToWellTestProfile = React.lazy(() =>
  import("../../screens/WellTestProfile/Redirect")
);
const RegistryOverview = React.lazy(() =>
  import("../../screens/RegistryOverview")
);
const RegistryProfilePage = React.lazy(() =>
  import("../../screens/RegistryProfilePage")
);
const RegistryTypesPage = React.lazy(() =>
  import("../../screens/RegistryTypesPage")
);
const RegistryTypePage = React.lazy(() =>
  import("../../screens/RegistryTypePage")
);
const MonitorsPage = React.lazy(() => import("../../screens/MonitorsPage"));
const MonitorPage = React.lazy(() => import("../../screens/MonitorPage"));
const TaskPage = React.lazy(() => import("../../screens/TaskPage"));
const TasksPage = React.lazy(() => import("../../screens/TasksPage"));
const TestingPage = React.lazy(() => import("../../screens/TestingPage"));
const TimeSeriesPage = React.lazy(() =>
  import("../../shared/components/TimeSeriesPage")
);
const AccountPage = React.lazy(() => import("../../screens/AccountPage"));
/* eslint-enable */

const paths = [
  // root
  {
    path: "/",
    breadcrumb: { icon: <HomeOutlined /> },
    route: { exact: true, component: Faces },
  },
  {
    path: "/faces/",
    route: { component: () => <Redirect to="/" /> },
  },

  {
    path: "/account/",
    breadcrumb: {
      description: breadcrumbTranslations.account,
    },
    route: { exact: true, component: AccountPage },
  },
  {
    path: "/account/:companyId/",
    breadcrumb: {
      descriptionQuery: COMPANY_BREADCRUMB_QUERY,
      queryVariables: { id: "companyId" },
      fallbackDescription: "Company",
    },
    route: { exact: true, component: CompanyPage },
  },
  {
    path: "/account/:companyId/:membershipId/",
    breadcrumb: {
      descriptionQuery: COMPANY_MEMBERSHIP_BREADCRUMB_QUERY,
      queryVariables: { id: "membershipId" },
      fallbackDescription: "Member",
    },
    route: { exact: true, component: CompanyMembershipPage },
  },

  // asset pages
  {
    path: "/assets/",
    breadcrumb: {
      description: breadcrumbTranslations.assets,
    },
    route: { exact: true, component: AssetOverview },
  },
  {
    path: "/assets/:parentAssetId/layer/",
    breadcrumb: {
      description: breadcrumbTranslations.baseLayer,
    },
    route: { component: AssetOverview },
  },
  {
    path: "/assets/:assetPk([0-9]+)/",
    route: {
      component: AssetPageRedirect,
      exact: true,
    },
  },
  {
    path: "/assets/:assetId/",
    breadcrumb: {
      descriptionQuery: ASSET_BREADCRUMB_QUERY,
      queryVariables: { id: "assetId" },
      fallbackDescription: "Asset",
    },
    route: {
      component: AssetPage,
      exact: true,
    },
  },
  {
    path: "/assets/:locatorId/:datasetId",
    route: {
      component: LocatorDatasetPages,
    },
  },

  // registries
  {
    path: "/registries/",
    breadcrumb: {
      description: breadcrumbTranslations.registries,
    },
    route: { exact: true, component: RegistryOverview },
  },
  {
    path: "/registries/:registryId/",
    breadcrumb: {
      descriptionQuery: REGISTRY_BREADCRUMB_QUERY,
      queryVariables: { id: "registryId" },
      fallbackDescription: "Registry",
    },
    route: { exact: true, component: RegistryProfilePage },
  },
  {
    path: "/registries/:locatorId/:datasetId",
    route: {
      component: LocatorDatasetPages,
    },
  },

  // registry types
  {
    path: "/registryTypes/",
    breadcrumb: {
      description: breadcrumbTranslations.registryTypes,
    },
    route: { exact: true, component: RegistryTypesPage },
  },
  {
    path: "/registryTypes/:typeId",
    breadcrumb: {
      descriptionQuery: REGISTRY_TYPE_BREADCRUMB_QUERY,
      queryVariables: { id: "typeId" },
      fallbackDescription: "Registry type",
    },
    route: { component: RegistryTypePage },
  },

  // extra overview screens
  {
    path: "/imageMap/",
    breadcrumb: {
      description: breadcrumbTranslations.imageMap,
    },
    route: { component: ImageMap },
  },
  {
    path: "/quakes/",
    breadcrumb: {
      description: breadcrumbTranslations.quakes,
    },
    route: { component: QuakeMap },
  },

  // monitors
  {
    path: "/monitor/",
    breadcrumb: {
      description: breadcrumbTranslations.monitors,
    },
    route: {
      component: MonitorsPage,
      exact: true,
    },
  },
  {
    path: "/monitor/:monitorId/",
    breadcrumb: {
      descriptionQuery: MONITOR_BREADCRUMB_QUERY,
      queryVariables: { monitorId: "monitorId" },
      fallbackDescription: "Monitor",
    },
    route: {
      component: MonitorPage,
      exact: true,
    },
  },

  // datasets
  {
    path: "/datasets/",
    breadcrumb: {
      description: breadcrumbTranslations.datasets,
    },
    route: {
      component: DatasetsPage,
      exact: true,
    },
  },

  // datasets pages
  {
    path: "/datasets/:datasetId",
    route: {
      component: DatasetPages,
    },
  },
  {
    path: "/add-data/",
    breadcrumb: {
      description: breadcrumbTranslations.addData,
    },
    route: { exact: true, component: AddDataPage },
  },
  {
    path: "/time-series/",
    breadcrumb: {
      description: breadcrumbTranslations.timeSeries,
    },
    route: { exact: true, component: TimeSeriesPage },
  },
  ...timeSeriesModule("/time-series/"),

  {
    path: "/testing/abcdefgh/",
    route: {
      exact: true,
      component: TestingPage,
    },
  },

  // Dashboards
  {
    path: "/dashboards/",
    breadcrumb: {
      description: breadcrumbTranslations.dashboards,
    },
    route: { exact: true, component: DashboardsPage },
  },
  {
    path: "/dashboards/:dashboardId/",
    breadcrumb: {
      descriptionQuery: DASHBOARD_BREADCRUMB_QUERY,
      queryVariables: { id: "dashboardId" },
      fallbackDescription: "Dashboard",
    },
    route: {
      component: DashboardPage,
      exact: true,
    },
  },

  // DataConnections
  {
    path: "/data-connections/",
    breadcrumb: {
      description: breadcrumbTranslations.dataConnections,
    },
    route: { exact: true, component: DataConnectionsPage },
  },
  {
    path: "/data-connections/:dataConnectionId/",
    breadcrumb: {
      descriptionQuery: DATA_CONNECTION_BREADCRUMB_QUERY,
      queryVariables: { id: "dataConnectionId" },
      fallbackDescription: "Data connection",
    },
    route: {
      component: DataConnectionPage,
      exact: true,
    },
  },
  {
    path: "/data-connections/:dataConnectionId/:taskId/",
    breadcrumb: {
      descriptionQuery: TASK_BREADCRUMB_QUERY,
      queryVariables: { id: "taskId" },
      fallbackDescription: "Task",
    },
    route: {
      component: TaskPage,
      exact: true,
    },
  },
  {
    path: "/task/",
    breadcrumb: {
      description: breadcrumbTranslations.tasks,
    },
    route: {
      component: TasksPage,
      exact: true,
    },
  },
  {
    path: "/task/:taskId/",
    breadcrumb: {
      descriptionQuery: TASK_BREADCRUMB_QUERY,
      queryVariables: { id: "taskId" },
      fallbackDescription: "Task",
    },
    route: {
      component: TaskPage,
      exact: true,
    },
  },
  {
    path: "/external-datasets/",
    breadcrumb: {
      description: breadcrumbTranslations.externalDatasets,
    },
    route: {
      component: ExternalDatasetsPage,
      exact: true,
    },
  },
  {
    path: "/external-datasets/:externalDatasetId/",

    breadcrumb: {
      descriptionQuery: EXTERNAL_DATASET_BREADCRUMB_QUERY,
      queryVariables: { id: "externalDatasetId" },
      fallbackDescription: "External dataset",
    },
    route: { component: ExternalDatasetPage },
  },

  // Charts
  {
    path: "/charts/",
    breadcrumb: {
      description: breadcrumbTranslations.charts,
    },
    route: { exact: true, component: ChartsPage },
  },
  {
    path: "/charts/:chartId/",
    breadcrumb: {
      descriptionQuery: CHART_BREADCRUMB_QUERY,
      queryVariables: { id: "chartId" },
      fallbackDescription: "Chart",
    },
    route: {
      component: ChartProfile,
      exact: true,
    },
  },
  // Redirects
  {
    path: "/sample/:sampleId/",
    route: { component: RedirectToSampleProfile },
  },
  {
    path: "/parameter/:parameterId",
    route: { component: RedirectToParameterProfile },
  },
  {
    path: "/time-series/:timeSeriesId",
    route: { component: RedirectToTimeSeriesProfile },
  },
  {
    path: "/well-test/:wellTestId",
    route: { component: RedirectToWellTestProfile },
  },
  {
    path: "/data-monitor/",
    route: { component: () => <Redirect to="/dashboards" /> },
  },
  // Redirect up one level if path is unresolved
  {
    path: "*",
    route: {
      render: (props) => <Redirect to="../" />,
    },
  },
];

export const routes = map(compose(liftKey("route"), pick(["path", "route"])))(
  paths
);

export default paths;

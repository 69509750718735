import React from "react";
import PropTypes from "prop-types";
import { Drawer as AntdDrawer } from "antd";
import styled from "styled-components";
import Flex from "./Flex";
import { not } from "../misc/fp";
import { DRAWER_Z_INDEX } from "../misc/constants";
import CancelButton from "./CancelButton";
import SubmitButton from "./SubmitButton";

const Footer = styled.div`
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: ${(props) => (props.noPadding ? undefined : "10px 16px")};
  text-align: right;
  background: #fff;
  border-radius: 0 0 4px 4px;
`;

const StyledDrawer = styled(AntdDrawer)`
  & .ant-drawer-header {
    border-color: ${(props) => !props.separator && "transparent"};
  }
  & .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
  }
  & .ant-drawer-body {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
  }
`;

const Body = styled(Flex.Column)`
  flex: 1;
  overflow-y: auto;
  padding: ${(props) => not(props.noPadding) && "24px"};
`;

const Drawer = ({ footer, children, noPadding, onOk, zIndex, ...props }) => (
  <StyledDrawer zIndex={zIndex || DRAWER_Z_INDEX} {...props}>
    <Body noPadding={noPadding}>{children}</Body>
    {footer ? (
      <Footer noPadding={noPadding}>{footer}</Footer>
    ) : (
      onOk && (
        <Footer>
          {props.onClose && (
            <CancelButton onClick={props.onClose} style={{ marginRight: 8 }} />
          )}
          {<SubmitButton onClick={onOk} />}
        </Footer>
      )
    )}
  </StyledDrawer>
);
Drawer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  footer: PropTypes.node,
  separator: PropTypes.bool,
};

Drawer.defaultProps = {
  footer: undefined,
  noPadding: false,
  separator: true,
};

Drawer.Footer = Footer;
export default Drawer;

import { Link as RouterLink } from "react-router-dom";
import React from "react";

const Link = ({ openInNewTab, ...props }) => (
  <RouterLink
    // stop propagation to avoid causing on click events on components higher up in dom
    onClick={(e) => {
      e.stopPropagation();
    }}
    {...(openInNewTab ? { target: "_blank", rel: "noopener noreferrer" } : {})}
    {...props}
  />
);

export default Link;

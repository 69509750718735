import React from "react";
import {
  AimOutlined,
  FunnelPlotOutlined,
  ExperimentOutlined,
  PictureOutlined,
  LineChartOutlined,
  DatabaseOutlined,
  BarChartOutlined,
  DashboardOutlined,
  ApartmentOutlined,
  TagOutlined,
  ApiOutlined,
} from "@ant-design/icons";
import { EnvironmentOutlined } from "../components/Icon";
import { inc, map, maxIn, prop } from "./fp";
import { ISN93, WGS84 } from "./gis/constants";
import { color as appColor } from "../../constants";

/* ------------------- Z-indexes --------------------------- */
export const SIDEBAR_Z_INDEX = 1002;
export const GLOBAL_HEADER_Z_INDEX = 1003;
export const DRAWER_Z_INDEX = inc(
  maxIn([SIDEBAR_Z_INDEX, GLOBAL_HEADER_Z_INDEX])
);
/* ------------------- Dates --------------------------- */
export const MOMENT_WEEK_ONLY_FORMAT = "WW";
export const MOMENT_WEEK_FORMAT = "YYYY-wo";
export const MOMENT_MONTH_READABLE_FORMAT = "MMM";
export const MOMENT_MONTH_FORMAT = "MM-DD";
export const MOMENT_YEAR_FORMAT = "YYYY";
export const MOMENT_DATE_FORMAT = "YYYY-MM-DD";
export const MOMENT_TIME_FORMAT = "HH:mm";
export const MOMENT_DATE_TIME_FORMAT = `${MOMENT_DATE_FORMAT} ${MOMENT_TIME_FORMAT}`;
export const MOMENT_DATE_TIME_WITH_SECONDS_FORMAT = `${MOMENT_DATE_FORMAT} ${MOMENT_TIME_FORMAT}:ss`;

export const PERMISSION_VALUE_MAPPING = {
  /*  this needs to match values of RankedPermissionEnum in eyk/authorization/permissions.py */
  read: 10,
  write: 20,
  full: 30,
};

/* ------------------- Forms --------------------------- */
export const FORM_ROOT_KEY = "forms";

export const HTTP_HEADERS = {
  comment: "X-REVISION-COMMENT",
};

export const APOLLO_NETWORK_STATUS = {
  /**
   * The query has never been run before and the query is now currently running. A query will still
   * have this network status even if a partial data result was returned from the cache, but a
   * query was dispatched anyway.
   */
  loading: 1,

  /**
   * If `setVariables` was called and a query was fired because of that then the network status
   * will be `setVariables` until the result of that query comes back.
   */
  setVariables: 2,

  /**
   * Indicates that `fetchMore` was called on this query and that the query created is currently in
   * flight.
   */
  fetchMore: 3,

  /**
   * Similar to the `setVariables` network status. It means that `refetch` was called on a query
   * and the refetch request is currently in flight.
   */
  refetch: 4,

  /**
   * Indicates that a polling query is currently in flight. So for example if you are polling a
   * query every 10 seconds then the network status will switch to `poll` every 10 seconds whenever
   * a poll request has been sent but not resolved.
   */
  poll: 6,

  /**
   * No request is in flight for this query, and no errors happened. Everything is OK.
   */
  ready: 7,

  /**
   * No request is in flight for this query, but one or more errors were detected.
   */
  error: 8,
};
export const NO_ID = -1;
export const NEW_ID = null;

export const NAVBAR_HEIGHT = "50px";

/* ------------- React Bootstrap Table -------------------------- */
export const REACT_BOOTSTRAP_TABLE_TOOLBAR_HEIGHT = "34px";

/* -------------- UUID Constants ----------------------- */
export const DATASET_TYPE_UUID = {
  ASSET: "cb8ae5e9-31f8-4648-a905-667ce4a0f452",
  WELL_TEST: "89bb816a-8f88-4d2e-919f-18153affb290",
  EARTHQUAKE: "2acefbcf-ad23-4c5d-b48b-1b2e99fc2239",
  IMAGE: "0d05f375-6084-4ab7-9001-109b1c926d4e",
  SAMPLE: "ce7dedf8-e7a0-4b80-b1cb-057351cda28e",
  TIME_SERIES: "b991ec68-52b3-4f39-9f1f-a710982a0d74",
  TRACER_SAMPLE: "2349d308-74dc-4bb7-b746-1088aeea6ad8",
};

export const REGISTRY_TYPE_UUID = {
  OS_BOREHOLE_REGISTRY: "89e9e248-320f-4b41-8c8c-20484d53779e",
  PRIMARY_REGISTRY: "7409c65f-816b-430c-928d-d47a093e36db",
  OR_SITE_REGISTRY: "1c4e9732-5783-4854-bf4f-8b9f39d0cc82",
  OR_KKS_REGISTRY: "ca0e5e9f-cfa8-4212-acca-041c9ef58ed8",
  LV_KKS_REGISTRY: "5f63d43e-cb63-484c-b0cf-a4e773e2a6f6",
  LV_VIEWDATA_REGISTRY: "7dc7f4bb-ce12-42e7-b271-235b4207ae99",
  MV_WISKI_REGISTRY: "45e40611-d289-4459-a683-3768d6c9b71b",
  MV_PROJECT_REGISTRY: "b8cab394-6309-499f-975a-c709eb69736f",
  LV_REXCHANGE_REGISTRY: "13d3b73f-44ef-4daf-b68a-3ac1d2e11343",
  UST_AIR_QUALITY_MEASURER_REGISTRY: "9faa1ce3-031a-43b4-ab33-5dd64dfa08c5",
};

export const DATA_TYPE_UUID = {
  ...DATASET_TYPE_UUID,
  DATASET: "008819f9-85ab-424f-8864-d1c6a9a84303",
  DASHBOARD: "28a9dfee-70ce-4cec-9470-4b0475877175",
  CHART: "bd3d3c09-cb28-4dcc-ace4-ee0eafad4f65",
  DATA_CONNECTION: "6299d2bb-0375-4d3e-9ed1-283abffcf1cd",
  EXTERNAL_DATASET: "7d04dc38-6059-4096-aba4-6ff95ba67a8c",
  REGISTRY: "9f4b7a46-f6b7-4188-a6ae-dafb6a938d1e",
  PARAMETER: "4a195c37-7797-441a-aa7c-9bd506b1b3f7",
  WELL_BASE_CURVE: "468924ee-d49a-4e0e-9e1f-678f2b1916b0",
};

export const ICON_TYPE_BY_DATASET_TYPE = {
  [DATASET_TYPE_UUID.ASSET]: <EnvironmentOutlined />,
  [DATASET_TYPE_UUID.WELL_TEST]: <FunnelPlotOutlined />,
  [DATASET_TYPE_UUID.EARTHQUAKE]: <AimOutlined />,
  [DATASET_TYPE_UUID.IMAGE]: <PictureOutlined />,
  [DATASET_TYPE_UUID.SAMPLE]: <ExperimentOutlined />,
  [DATASET_TYPE_UUID.TIME_SERIES]: <LineChartOutlined />,
  [DATASET_TYPE_UUID.TRACER_SAMPLE]: <ExperimentOutlined />,
};

export const ICON_TYPE_BY_DATA_TYPE = {
  ...ICON_TYPE_BY_DATASET_TYPE,
  [DATA_TYPE_UUID.DATASET]: <DatabaseOutlined />,
  [DATA_TYPE_UUID.DASHBOARD]: <DashboardOutlined />,
  [DATA_TYPE_UUID.CHART]: <BarChartOutlined />,
  [DATA_TYPE_UUID.DATA_CONNECTION]: <ApiOutlined />,
  [DATA_TYPE_UUID.EXTERNAL_DATASET]: <ApartmentOutlined />,
  [DATA_TYPE_UUID.REGISTRY]: <TagOutlined />,
  [DATA_TYPE_UUID.PARAMETER]: <ExperimentOutlined />,
  [DATA_TYPE_UUID.WELL_BASE_CURVE]: <FunnelPlotOutlined />,
};

/* -------------- Cooordinates ------------------------- */
export const ICELAND_CENTER = [65, -19];
export const ICELAND_ZOOM = 5;
export { ISN93 };
export { WGS84 };
export const COORDINATE_LABELS = {
  ISN93: ["X", "Y"],
  WGS84: ["Lat", "Long"],
};
export const COORDINATE_DECIMALS = {
  ISN93: 3,
  WGS84: 6,
};

export const TWO_TONE_COLORS = {
  orange: {
    background: "rgba(249,187,162,0.3)",
    borderColor: "#f58d67",
    color: appColor.primary,
  },
  volcano: {
    background: "#fff2e8",
    borderColor: "#ffbb96",
    color: "#fa541c",
  },
  gold: {
    background: "rgba(253,212,162,0.3)",
    borderColor: "#fbb767",
    color: "#f9a542",
  },
  pink: {
    background: "rgba(235,187,212,0.3)",
    borderColor: "#e4a5c5",
    color: "#dd8eb7",
  },
  green: {
    background: "rgba(174,217,180,0.3)",
    borderColor: "#7fc687",
    color: "#3db44b",
  },
  blue: {
    background: "rgba(169,219,246,0.3)",
    borderColor: "#73c3ed",
    color: appColor.secondary,
  },
  purple: {
    background: "rgba(198,197,226,0.3)",
    borderColor: "#9e9ece",
    color: "#7773b4",
  },
  gray: {
    background: "#FAFAFA",
    borderColor: "#D9D9D9",
    color: "rgba(0, 0, 0, 0.85)",
  },
  white: {
    background: "#FFF",
    borderColor: "#EEE",
    color: "#bbb",
  },
};

export const COLORS = map(prop(["color"]), TWO_TONE_COLORS);

/* -------------- TAG colors by dataset types ------------------------- */

export const DATASET_COLORS = {
  [DATASET_TYPE_UUID.ASSET]: "gold",
  [DATASET_TYPE_UUID.WELL_TEST]: `green`,
  [DATASET_TYPE_UUID.EARTHQUAKE]: "pink",
  [DATASET_TYPE_UUID.IMAGE]: "purple",
  [DATASET_TYPE_UUID.SAMPLE]: "blue",
  [DATASET_TYPE_UUID.TIME_SERIES]: "orange",
  [DATASET_TYPE_UUID.TRACER_SAMPLE]: "volcano", // todo remove
};

export const DATASET_TYPE_COLOR = {
  [DATASET_TYPE_UUID.ASSET]: TWO_TONE_COLORS["gold"].color,
  [DATASET_TYPE_UUID.WELL_TEST]: TWO_TONE_COLORS["green"].color,
  [DATASET_TYPE_UUID.EARTHQUAKE]: TWO_TONE_COLORS["pink"].color,
  [DATASET_TYPE_UUID.IMAGE]: TWO_TONE_COLORS["purple"].color,
  [DATASET_TYPE_UUID.SAMPLE]: TWO_TONE_COLORS["blue"].color,
  [DATASET_TYPE_UUID.TIME_SERIES]: TWO_TONE_COLORS["orange"].color,
  [DATASET_TYPE_UUID.TRACER_SAMPLE]: TWO_TONE_COLORS["volcano"].color, // todo remove
};

export const color = appColor;

export const COLOR_PALETTE = {
  "primary-0": "#4C4D4F",
  "primary-1": "#797b7d",
  "primary-2": "#696A6D",
  "primary-3": "#2B2C2E",
  "primary-4": "#0E0F11",
  "complement-0": color.primary,
  "complement-1": "#eb8865",
  "complement-2": "#efb39d",
  "complement-3": "#A23513",
  "complement-4": "#7A1D00",
};

export const TASK_STATUS = {
  done: "DONE",
  active: "ACTIVE",
  error: "ERROR",
  pending: "PENDING",
};

export const TASK_MESSAGE_TYPE = {
  info: "INFO",
  success: "SUCCESS",
  warning: "WARNING",
  error: "ERROR",
};

export const TASK_BUSY_STATUSES = [TASK_STATUS.active, TASK_STATUS.pending];

export const MONITOR_STATE = {
  ok: "OK",
  bad: "BAD",
  disabled: "DISABLED",
};

export const GLOBAL_HEADER_HEIGHT = 64;
export const ROW_COL_GUTTER_SIZE = 24;

export const ASPECT_RATIO_A4 = 210 / 297;

export const EXTERNAL_LINKS = {
  HOW_TO_IMPORT_SAMPLES:
    "https://hugfimi.atlassian.net/wiki/spaces/UH/pages/731807745/How+to+Import+samples?atlOrigin=eyJpIjoiMmI3ZjViZDczOWMwNDNjNDk2NzJkOGQ5YTg3ZWQwMDUiLCJwIjoiYyJ9",
  HOW_TO_IMPORT_SAMPLES__FILE_STRUCTURE:
    "https://hugfimi.atlassian.net/wiki/spaces/UH/pages/731807745/How+to+Import+samples#File-structure",
  HOW_TO_IMPORT_WELL_TESTS:
    "https://hugfimi.atlassian.net/wiki/spaces/UH/pages/734298176/How+to%3A+Import+well+tests",
};

export const SCREEN_SIZE = {
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1600px",
};

export default {
  MOMENT_DATE_TIME_FORMAT,
  MOMENT_DATE_FORMAT,
  MOMENT_TIME_FORMAT,
  HTTP_HEADERS,
  EXTERNAL_LINKS,
};

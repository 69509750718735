import { parse } from "query-string";
import always from "./always";
import compose from "./compose";

import isString from "./isString";
import map from "./map";
import tryCatch from "./tryCatch";
import when from "./when";
import isArray from "./isArray";

const parseStringsToObjects = map(
  when(isString, (value) => tryCatch(window.JSON.parse, always(value))(value))
);
const parseStringsInArraysToObjects = map(when(isArray, parseStringsToObjects));

// String -> {k: v}
const qsParse = compose(
  parseStringsInArraysToObjects,
  parseStringsToObjects,
  parse
);

export default qsParse;

import gql from "graphql-tag";

export default gql`
  query DataSetTypes {
    datasetTypes: dataSetTypes {
      edges {
        node {
          id
          uuid
        }
      }
    }
  }
`;

import { enableErrorTrackers } from "./errorReporting";
import React from "react";
import ReactDOM from "react-dom";
import { version } from "../package.json";
import App from "./app";
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV === "production") {
  enableErrorTrackers({ version });
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import PropTypes from "prop-types";
import React from "react";
import ChartProvider from "../components/Chart/ChartProvider";
import LanguageProvider from "./LanguageProvider";
import StoreProvider from "./StoreProvider";

const Providers = ({ children }) => (
  <React.Fragment>
    <StoreProvider>
      <LanguageProvider>
        <ChartProvider>{React.Children.only(children)}</ChartProvider>
      </LanguageProvider>
    </StoreProvider>
  </React.Fragment>
);

Providers.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Providers;

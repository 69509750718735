import gql from "graphql-tag";
import { path } from "../misc/fp";
import { user as GLOBAL_SIDER_USER_FRAGMENT } from "../fragments/GlobalSiderFragments";

const resultsKey = "loginUser";
export const getUser = path([resultsKey, "user"]);
export const isFirstLogin = path([resultsKey, "isFirstLogin"]);

export default gql`
  mutation Login($email: String!, $password: String!) {
    loginUser(input: { email: $email, password: $password }) {
      user {
        ...GlobalSiderUserFragment
      }
      isFirstLogin
    }
  }
  ${GLOBAL_SIDER_USER_FRAGMENT}
`;

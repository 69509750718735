import { LockOutlined, UserOutlined, WindowsOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";

import { publicClient } from "../services/client";
import Button from "../shared/components/Button";
import Card from "../shared/components/Card";
import Flex from "../shared/components/Flex";
import Form from "../shared/components/Form";
import Input from "../shared/components/Input";
import Link from "../shared/components/Link";
import LogoPageHeader from "../shared/components/LogoPageHeader";
import Margin from "../shared/components/Margin";
import message from "../shared/components/message";
import Skeleton from "../shared/components/Skeleton";
import Typography from "../shared/components/Typography";

import useDocumentTitle from "../shared/hooks/useDocumentTitle";
import useEffect from "../shared/hooks/useEffect";
import useMemo from "../shared/hooks/useMemo";
import useMutation from "../shared/hooks/useMutation";

import useQuery from "../shared/hooks/useQuery";
import useQueryStringValue from "../shared/hooks/useQueryStringValue";
import useState from "../shared/hooks/useState";
import useTranslations from "../shared/hooks/useTranslations";
import useWelcomeModal from "../shared/hooks/useWelcomeModal";
import { TWO_TONE_COLORS } from "../shared/misc/constants";
import { all, any, identity, isNotNil } from "../shared/misc/fp";
import ACTIVATE_ACCOUNT_MUTATION from "../shared/mutations/ActivateAccountMutation";
import LOGIN_MUTATION, {
  isFirstLogin,
} from "../shared/mutations/LoginMutation";

import APP_SETTINGS_QUERY, {
  getAdfsAuthUrl,
} from "../shared/queries/AppSettingsQuery";
import PageTemplate from "../shared/templates/PageTemplate";
import { isIE } from "../shared/utils";
import { path as registerPath } from "./RegisterPage";

import { path as resetPasswordPath } from "./ResetPasswordPage";

const CenteredText = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const IEWarning = () => (
  <Flex.Column flex="auto" justifyContent="space-around">
    <CenteredText>
      <Flex alignItems="center">
        <Margin right="10px">
          <span className="fa-stack fa-2x">
            <i className="fa fa-internet-explorer fa-stack-1x"></i>
            <i
              style={{ color: TWO_TONE_COLORS.gold.borderColor }}
              className="fa fa-ban fa-stack-2x text-danger"
            ></i>
          </span>
        </Margin>
        <Typography.Text strong>
          <FormattedMessage
            id="loginPage.noIESupport"
            defaultMessage="Internet Explorer is not supported."
          />
        </Typography.Text>
      </Flex>
    </CenteredText>
    <CenteredText>
      <Flex alignItems="center">
        <Margin right="10px">
          <span className="fa-stack fa-2x">
            <i
              style={{ color: TWO_TONE_COLORS.blue.borderColor }}
              className="fa fa-circle-o fa-stack-2x"
            ></i>
            <i className="fa fa-chrome fa-stack-1x"></i>
          </span>
        </Margin>
        <Typography.Text strong>
          <FormattedMessage
            id="loginPage.recommendChrome"
            defaultMessage="Chrome is recommended"
          />
        </Typography.Text>
      </Flex>
    </CenteredText>
  </Flex.Column>
);

const LoginForm = ({ refetchQueries }) => {
  const messages = useTranslations(
    defineMessages({
      loginUnsuccessful: {
        id: "loginPage.loginUnsuccessfulErrorMessage",
        defaultMessage: "Login unsuccessful",
      },
      emailPlaceholder: {
        id: "loginPage.email.placeholder",
        defaultMessage: "Email",
      },
      passwordPlaceholder: {
        id: "loginPage.password.placeholder",
        defaultMessage: "Password",
      },
      accountActive: {
        id: "loginPage.accountActive.message",
        defaultMessage: "Account activated. You can now log in",
      },
    })
  );
  const { modal, setVisible: setWelcomeModalVisible } = useWelcomeModal();
  const [next] = useQueryStringValue("next");
  const [email, setEmail] = useQueryStringValue("email");
  const [password, setPassword] = useQueryStringValue("password");
  const [token] = useQueryStringValue("token");
  const [uid] = useQueryStringValue("uid");

  const [loginValidation, setLoginValidation] = useState({});
  const [loginMutation, { loading: submissionLoading }] = useMutation(
    LOGIN_MUTATION,
    {
      client: publicClient,
      refetchQueries,
      onError: (data) => {
        setLoginValidation({
          validateStatus: "error",
          errorMsg: messages.loginUnsuccessful(),
        });
      },
      onCompleted: (data) => {
        if (isNotNil(next)) {
          window.location = next;
        }
        if (isFirstLogin(data)) {
          setWelcomeModalVisible(true);
        }
        // clear possible entries of email and password from qs
        setEmail();
        setPassword();
      },
    }
  );
  const [activateAccount, { loading: activationLoading }] = useMutation(
    ACTIVATE_ACCOUNT_MUTATION,
    {
      client: publicClient,
      onCompleted: (data) => {
        message.success(messages.accountActive());
      },
    }
  );

  const loading = useMemo(
    () => any(identity, [submissionLoading, activationLoading]),
    [submissionLoading, activationLoading]
  );

  // Activate account if token and uid is provided in QS
  useEffect(() => {
    if (all(isNotNil, [token, uid])) {
      activateAccount({ variables: { token, uid } });
    }
  }, [token, uid, activateAccount]);

  // Autologin if email and password is provided in QS
  if (all(isNotNil, [email, password])) {
    loginMutation({
      variables: {
        email,
        password,
      },
    });
  }

  return (
    <React.Fragment>
      {modal}
      <Form
        size="large"
        onFinish={(variables) => {
          setLoginValidation({});
          loginMutation({ variables });
        }}
      >
        <Form.Item
          name="email"
          rules={[{ required: true }]}
          hasFeedback
          validateStatus={
            loading ? "validating" : loginValidation.validateStatus
          }
          help={loginValidation.errorMsg}
        >
          <Input
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="email"
            placeholder={messages.emailPlaceholder()}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true }]}
          hasFeedback
          validateStatus={
            loading ? "validating" : loginValidation.validateStatus
          }
          help={loginValidation.errorMsg}
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder={messages.passwordPlaceholder()}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button block type="primary" htmlType="submit" loading={loading}>
            <FormattedMessage id="loginPage.logIn" defaultMessage="Log in" />
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

const WindowsLogin = ({ loading, loginUrl }) =>
  loading ? (
    <Skeleton />
  ) : (
    <Button block type="primary" href={loginUrl}>
      <FormattedMessage
        id="loginPage.windowslogIn"
        defaultMessage="Log in with windows credentials"
      />
    </Button>
  );

const EYKLogin = ({ refetchQueries }) => (
  <React.Fragment>
    {isIE() ? <IEWarning /> : <LoginForm refetchQueries={refetchQueries} />}
  </React.Fragment>
);

const LoginTabSwitch = ({ tabKey, ...tabProps }) => {
  switch (tabKey) {
    case "windows":
      return <WindowsLogin {...tabProps} />;
    default:
      return <EYKLogin {...tabProps} />;
  }
};

const LoginPane = ({ refetchQueries }) => {
  const [activeTab, setActiveTab] = useState("eyk");

  const { data, loading } = useQuery(APP_SETTINGS_QUERY, {
    client: publicClient,
  });
  const adfsAuthUrl = useMemo(() => getAdfsAuthUrl(data), [data]);

  const cardProps = useMemo(() => {
    if (isNotNil(adfsAuthUrl)) {
      return {
        tabList: [
          {
            key: "eyk",
            tab: (
              <span>
                <UserOutlined />
                <FormattedMessage
                  defaultMessage="EYK login"
                  id="loginPage.tabTitle.eyklogin"
                />
              </span>
            ),
          },
          {
            key: "windows",
            tab: (
              <span>
                <WindowsOutlined />
                <FormattedMessage
                  defaultMessage="Windows login"
                  id="loginPage.tabTitle.windowslogin"
                />
              </span>
            ),
          },
        ],
        activeTabKey: activeTab,
        onTabChange: setActiveTab,
      };
    }
    return {};
  }, [activeTab, setActiveTab, adfsAuthUrl]);

  return (
    <Card loading={loading} style={{ width: "400px" }} {...cardProps}>
      <LoginTabSwitch
        tabKey={activeTab}
        refetchQueries={refetchQueries}
        adfsAuthUrl={adfsAuthUrl}
      />
    </Card>
  );
};

const Login = ({ refetchQueries }) => {
  const messages = useTranslations(
    defineMessages({
      pageTitle: { id: "login.pageTitle", defaultMessage: "Login" },
      title: { id: "login.command", defaultMessage: "Log in to EYK" },
    })
  );

  useDocumentTitle(messages.pageTitle());

  return (
    <PageTemplate
      header={<LogoPageHeader />}
      content={
        <Flex justifyContent="center">
          <div>
            <Margin bottom="16px">
              <CenteredText>
                <Typography.Title style={{ fontWeight: 200 }}>
                  {messages.title()}
                </Typography.Title>
              </CenteredText>
            </Margin>
            <LoginPane refetchQueries={refetchQueries} />
            <Flex justifyContent="center">
              <Margin betweenChildren="16px" top="16px">
                <Link to={resetPasswordPath}>
                  <FormattedMessage
                    id="loginPage.forgotPassword"
                    defaultMessage="Forgot password?"
                  />
                </Link>
                <Link to={registerPath}>
                  <FormattedMessage
                    id="loginPage.register"
                    defaultMessage="Register"
                  />
                </Link>
              </Margin>
            </Flex>
          </div>
        </Flex>
      }
    />
  );
};

Login.propTypes = {
  refetchQueries: PropTypes.arrayOf(
    PropTypes.shape({
      query: PropTypes.any.isRequired,
      variables: PropTypes.object.isRequired,
    })
  ),
};

export default Login;

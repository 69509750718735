import gql from "graphql-tag";
import { lensPath, view } from "../misc/fp";
import { user as GLOBAL_SIDER_USER_FRAGMENT } from "../fragments/GlobalSiderFragments";

const resultsKey = "registerUser";
const userLens = lensPath([resultsKey, "user"]);

export const getUser = view(userLens);

export default gql`
  mutation Register(
      $firstName: String!,
      $lastName: String!,
      $email: String!,
      $password: String!,
      $confirmationPassword: String!
  ) {
    ${resultsKey}(input: { 
        firstName: $firstName,
        lastName: $lastName,
        email: $email,
        password: $password,
        confirmationPassword: $confirmationPassword
    }) {
      user {
          nameOrEmail
        ...GlobalSiderUserFragment
      }
    }
  }
  ${GLOBAL_SIDER_USER_FRAGMENT}
`;

import {
  FrownOutlined,
  WarningOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import React from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { reportError } from "../../errorReporting";
import Button from "./Button";
import Space from "./Space";
import useIssueCollectorTrigger from "../hooks/useIssueCollectorTrigger";

const Wrapper = styled.div`
  padding-top: 300px;
  text-align: center;
`;
const Text = styled.div`
  padding: 12px;
`;
const InnerText = styled.div`
  margin-top: 5px;
`;
const initialState = {
  error: false,
  info: null,
};

const WithIssueCollector = ({ children }) => {
  const showIssueCollector = useIssueCollectorTrigger();
  return children({
    showIssueCollector,
  });
};
class ErrorBoundary extends React.Component {
  state = initialState;
  componentDidCatch(error, info) {
    this.setState({
      error: !!error,
      info: info.componentStack,
    });
    reportError({ error, info });
  }
  reset = () => {
    this.setState(initialState);
  };
  renderContent = () => {
    return (
      <WithIssueCollector>
        {({ showIssueCollector }) => (
          <Wrapper>
            <Text>
              <FrownOutlined style={{ fontSize: "2em" }} />
              <InnerText>
                <FormattedMessage
                  id="errorboundary.message"
                  description="Error message when an React error is catched"
                  defaultMessage="Sorry, an error occured."
                />
              </InnerText>
            </Text>
            <Space size="large">
              <Button
                type="primary"
                icon={<ReloadOutlined />}
                onClick={this.reset}
              >
                <FormattedMessage
                  id="errorboundary.button.reload"
                  description="Label on the reload button"
                  defaultMessage="Reload"
                />
              </Button>
              <Button onClick={showIssueCollector}>
                <WarningOutlined />
                <FormattedMessage
                  id="errorboundary.button.reportIssue"
                  description="label on the issue collector button"
                  defaultMessage="Report Bug"
                />
              </Button>
            </Space>
          </Wrapper>
        )}
      </WithIssueCollector>
    );
  };
  render() {
    const { children } = this.props;
    if (typeof children === "function") {
      return children({
        ...this.state,
        reset: this.reset,
        component: this.renderContent(),
      });
    }
    const { error } = this.state;
    return error ? this.renderContent() : children;
  }
}
export default ErrorBoundary;

import gql from "graphql-tag";
import APP_SEARCH_FRAGMENT from "../fragments/AppSearchFragment";

const LIMIT = 5;

export default gql`
  query AppSearchQuery($text: String) {
    search(text: $text, limit: ${LIMIT}) {
      ...AppSearchFragment
    }
  }
  ${APP_SEARCH_FRAGMENT}
`;

import { Form } from "antd";
import getValidationMessages from "../misc/api/getValidationMessages";
import {
  all,
  always,
  complement,
  compose,
  isNil,
  keys,
  mapObjKeys,
  reduce,
  snakeToCamelCase,
  values,
} from "../misc/fp";

const toFieldData = (errors) =>
  reduce(
    (fieldDataList, fieldName) => [
      ...fieldDataList,
      {
        name: fieldName,
        errors: errors[fieldName],
      },
    ],
    [],
    keys(errors)
  );

// Form f => f -> [{k: v}] -> void
const setFieldsErrors = (form) => compose(form.setFields, toFieldData);

const isValid = (form) =>
  compose(all(isNil), values, always(form.getFieldsError()));

const useForm = (props) => {
  const [form] = Form.useForm(props);

  return [
    {
      ...form,
      setFieldsErrors: setFieldsErrors(form),
      setFieldsValidationErrors: compose(
        setFieldsErrors(form),
        mapObjKeys(snakeToCamelCase),
        getValidationMessages
      ),
      hasErrors: complement(isValid(form)),
      isValid: isValid(form),
    },
  ];
};

export default useForm;

import PropTypes from "prop-types";
import React from "react";
import { Link, matchPath } from "react-router-dom";
import breadcrumbTranslations from "../../apps/EYK/breadcrumbTranslations";
import { color } from "../../constants";
import useDocumentTitle from "../hooks/useDocumentTitle";
import useEffect from "../hooks/useEffect";

import useMemo from "../hooks/useMemo";
import useQuery from "../hooks/useQuery";
import useReducer from "../hooks/useReducer";
import useRouter from "../hooks/useRouter";
import useTranslations from "../hooks/useTranslations";

import {
  addIndex,
  append,
  compose,
  equals,
  filter,
  has,
  isNil,
  join,
  length,
  lensPath,
  liftKey,
  map,
  nth,
  over,
  path,
  pick,
  reduce,
  renameKeys,
  repeat,
  slice,
  update,
} from "../misc/fp";

import Breadcrumb from "./Breadcrumb";
import styled from "styled-components";

const BreadcrumbLink = ({ index, dispatch, linkPath, icon, description }) => {
  useEffect(() => {
    dispatch({ type: "update", payload: { value: description, index } });
  }, [description, index]); //eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Link to={linkPath}>
      {icon && icon}
      {icon && description && <span>&nbsp;</span>}
      {description && description}
    </Link>
  );
};

BreadcrumbLink.propTypes = {
  dispatch: PropTypes.func.isRequired,
  linkPath: PropTypes.string.isRequired,
  icon: PropTypes.string,
  description: PropTypes.node,
};

BreadcrumbLink.defaultProps = {
  icon: undefined,
  description: undefined,
};

const composeQueryVariables = (variablesSchema, match) => {
  const variables = {};
  Object.keys(variablesSchema).map((key) => {
    variables[key] = match.params[variablesSchema[key]];
    return true;
  });
  return variables;
};

const resolveDescription = (data, fallback) => {
  try {
    return data.item.description;
  } catch (_) {
    return fallback;
  }
};

const BreadcrumbLinkWithQuery = ({
  query,
  queryVariables,
  match,
  fallbackDescription,
  ...linkProps
}) => {
  const { loading, data } = useQuery(query, {
    variables: composeQueryVariables(queryVariables, match),
  });
  return (
    <BreadcrumbLink
      description={loading ? "" : resolveDescription(data, fallbackDescription)}
      linkPath={match.url}
      {...linkProps}
    />
  );
};

const useBreadcrumbs = (paths) => {
  const {
    location: { pathname },
  } = useRouter();
  const messages = useTranslations(breadcrumbTranslations);

  const breadcrumbs = compose(
    map(
      compose(
        over(lensPath(["description"]), (desc) => {
          if (has("key", desc)) {
            return messages[desc.key]();
          }
          return desc;
        }),
        liftKey("breadcrumb"),
        renameKeys({ route: "routeProps" }),
        pick(["path", "route", "breadcrumb"])
      )
    ),
    filter(has("breadcrumb"))
  )(paths);

  // Gather breadcrumbs that match the URL parts in successive order
  const homeBreadcrumb = getMatchingBreadcrumb("/", breadcrumbs);
  const activeBreadcrumbs = homeBreadcrumb ? [homeBreadcrumb] : [];

  const pathSnippets = pathname.split("/").filter((snippet) => snippet !== "");

  compose(
    reduce((lastUrl, pathSnippet) => {
      const url = `${lastUrl}/${pathSnippet}`;

      const breadcrumb = getMatchingBreadcrumb(url, breadcrumbs);
      if (breadcrumb) {
        activeBreadcrumbs.push(breadcrumb);
      }

      return url;
    }, ""),
    filter((breadcrumb) => !isNil(breadcrumb))
  )(pathSnippets);

  return activeBreadcrumbs;
};

const getBreadcrumbIds = map(path(["match", "url"]));
const breadcrumbDescriptionUpdateFunction = (index) => (value) => (state) => {
  const listLength = state.length;
  if (listLength > index + 1) {
    return compose(update(index, value), slice(0, listLength))(state);
  } else if (listLength < index + 1) {
    return compose(
      addIndex(reduce)((prev, curr, idx) => {
        if (idx === index) return append(value)(prev);
        return append(nth(idx)(state))(prev);
      }, [])
    )(repeat(null, index + 1));
  } else {
    return compose(update(index, value))(state);
  }
};

const reducer = (state, action) => {
  if (action.type === "update") {
    const { index, value } = action.payload;
    return breadcrumbDescriptionUpdateFunction(index)(value)(state);
  } else {
    return state;
  }
};

const Container = styled.div`
  background-color: ${color.componentBackground};
  padding: 18px 24px 14px;
`;

const BreadcrumbsComponent = React.memo(
  ({ ids, activeBreadcrumbs }) => {
    const [breadcrumbsState, dispatch] = useReducer(reducer, []);
    const documentTitle = compose(
      join(" / "),
      slice(0, length(ids))
    )(breadcrumbsState);
    useDocumentTitle(documentTitle);

    if (activeBreadcrumbs.length === 1) {
      // don't show breadcrumbs on top level page
      return null;
    }
    return (
      <Container>
        <Breadcrumb>
          {activeBreadcrumbs.map(
            (
              { match, icon, description, descriptionQuery, ...queryProps },
              index
            ) => (
              <Breadcrumb.Item key={match.url}>
                {isNil(descriptionQuery) ? (
                  <BreadcrumbLink
                    index={index}
                    dispatch={dispatch}
                    linkPath={match.url}
                    icon={icon}
                    description={description}
                  />
                ) : (
                  <BreadcrumbLinkWithQuery
                    index={index}
                    dispatch={dispatch}
                    match={match}
                    icon={icon}
                    query={descriptionQuery}
                    {...queryProps}
                  />
                )}
              </Breadcrumb.Item>
            )
          )}
        </Breadcrumb>
      </Container>
    );
  },
  (prevProps, nextProps) => equals(prevProps.ids, nextProps.ids)
);

const getMatchingBreadcrumb = (path, breadcrumbs) => {
  let matchingBreadcrumb;
  breadcrumbs.find((b) => {
    const match = matchPath(path, { path: b.path, ...b.routeProps });
    if (match) {
      matchingBreadcrumb = {
        ...b,
        match,
      };
      return true;
    }
    return false;
  });
  return matchingBreadcrumb;
};

const Breadcrumbs = ({ paths, ...props }) => {
  const activeBreadcrumbs = useBreadcrumbs(paths);
  const ids = useMemo(() => {
    return getBreadcrumbIds(activeBreadcrumbs);
  }, [activeBreadcrumbs]);
  return (
    <BreadcrumbsComponent
      ids={ids}
      activeBreadcrumbs={activeBreadcrumbs}
      {...props}
    />
  );
};

Breadcrumbs.propTypes = {
  paths: PropTypes.arrayOf(
    PropTypes.shape({
      breadcrumb: PropTypes.string,
      route: PropTypes.string,
      path: PropTypes.string,
    })
  ).isRequired,
};

export default Breadcrumbs;

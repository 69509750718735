import Tooltip from "./Tooltip";
import { red } from "../colorPalettes";
import React from "react";

const FlagDot = ({ reason, show }) => (
  <Tooltip title={reason}>
    <div
      style={{
        borderRadius: "50%",
        backgroundColor: red[4],
        boxShadow: "0 0 0 1px #fff",
        display: show ? undefined : "none",
        position: "absolute",
        height: "6px",
        width: "6px",
        top: "8px",
        right: "8px",
      }}
    />
  </Tooltip>
);

export default FlagDot;

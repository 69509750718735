import { defineMessages } from "react-intl";

const breadcrumbTranslations = defineMessages({
  monitors: {
    key: "monitors",
    id: "breadcrumb.monitors",
    defaultMessage: "Monitors",
  },
  account: {
    key: "account",
    id: "breadcrumb.account",
    defaultMessage: "Account",
  },
  tasks: {
    key: "tasks",
    id: "breadcrumb.tasks",
    defaultMessage: "Tasks",
  },
  wellTests: {
    key: "wellTests",
    id: "breadcrumb.measurementseries",
    description: "breadcrumb.measurementseries",
    defaultMessage: "Well tests",
  },
  chart: {
    key: "chart",
    id: "breadcrumb.chart",
    description: "breadcrumb.chart",
    defaultMessage: "Chart",
  },
  charts: {
    key: "charts",
    id: "breadcrumb.charts",
    description: "breadcrumb.charts",
    defaultMessage: "Charts",
  },
  timeSeries: {
    key: "timeSeries",
    id: "breadcrumb.timeseries",
    description: "breadcrumb.timeseries",
    defaultMessage: "Time series",
  },
  table: {
    key: "table",
    id: "breadcrumb.table",
    description: "breadcrumb.table",
    defaultMessage: "Table",
  },
  parameters: {
    key: "parameters",
    id: "breadcrumb.parameters",
    description: "breadcrumb.parameters",
    defaultMessage: "Parameters",
  },
  samples: {
    key: "samples",
    id: "breadcrumb.samples",
    description: "breadcrumb.samples",
    defaultMessage: "Samples",
  },
  templates: {
    key: "templates",
    id: "breadcrumb.sampleTemplates",
    description: "breadcrumb.sampleTemplates",
    defaultMessage: "Templates",
  },
  tracerparameters: {
    key: "tracerparameters",
    id: "breadcrumb.tracerParameters",
    description: "breadcrumb.tracerParameters",
    defaultMessage: "Tracer Parameters",
  },
  images: {
    key: "images",
    id: "breadcrumb.images",
    description: "breadcrumb.images",
    defaultMessage: "Images",
  },
  quakes: {
    key: "quakes",
    id: "breadcrumb.quakes",
    description: "breadcrumb.quakes",
    defaultMessage: "Quakes",
  },
  assets: {
    key: "assets",
    id: "breadcrumb.assets",
    description: "breadcrumb.assets",
    defaultMessage: "Assets",
  },
  users: {
    key: "users",
    id: "breadcrumb.users",
    description: "breadcrumb.users",
    defaultMessage: "Users",
  },
  baseLayer: {
    key: "baseLayer",
    id: "breadcrumb.baseLayer",
    description: "breadcrumb.baseLayer",
    defaultMessage: "Base layer",
  },
  registryTypes: {
    key: "registryTypes",
    id: "breadcrumb.registryTypesPage",
    description: "breadcrumb.registryTypes",
    defaultMessage: "Registry Types",
  },
  registries: {
    key: "registries",
    id: "breadcrumb.registryOverview",
    description: "breadcrumb.registryOverview",
    defaultMessage: "Registries",
  },
  imageMap: {
    key: "imageMap",
    id: "breadcrumb.imageMap",
    description: "breadcrumb.imageMap",
    defaultMessage: "Image map",
  },
  datasets: {
    key: "datasets",
    id: "breadcrumb.datasets",
    description: "breadcrumb.datasets",
    defaultMessage: "Datasets",
  },
  addData: {
    key: "addData",
    id: "breadcrumb.addData",
    defaultMessage: "Upload",
  },
  sampleImport: {
    key: "sampleImport",
    id: "breadcrumb.sample.import",
    description: "breadcrumb.sample.import",
    defaultMessage: "Sample import",
  },
  dashboards: {
    key: "dashboards",
    id: "breadcrumb.dashboards",
    description: "breadcrumb.dashboards",
    defaultMessage: "Dashboards",
  },
  dataConnections: {
    key: "dataConnections",
    id: "breadcrumb.dataConnections",
    description: "breadcrumb.dataConnections",
    defaultMessage: "Data connections",
  },
  externalDatasets: {
    key: "externalDatasets",
    id: "breadcrumb.externalDatasets",
    defaultMessage: "External datasets",
  },
});

export default breadcrumbTranslations;

import DATASET_TYPE_QUERY from "../../shared/queries/FacesActiveDatasetTypesQuery";
import useQuery from "../../shared/hooks/useQuery";
import { FACES } from "./constants";
import { compose, map, pathOr } from "../../shared/misc/fp";

const activeFaces = (data) => {
  const activeDatasetTypeUUIDs = compose(
    map((edge) => edge.node.uuid),
    pathOr([], ["datasetTypes", "edges"])
  )(data);
  return Object.keys(FACES)
    .map((faceKey) => FACES[faceKey])
    .filter((face) => {
      if (face.datasetType) {
        return activeDatasetTypeUUIDs.includes(face.datasetType);
      }
      return true;
    });
};

const useActiveFaces = () => {
  const { data, loading } = useQuery(DATASET_TYPE_QUERY);
  return loading ? [] : activeFaces(data);
};

export default useActiveFaces;

import compose from "./compose";
import converge from "./converge";
import head from "./head";
import identity from "./identity";
import isNil from "./isNil";
import min from "./min";
import reduce from "./reduce";
import reject from "./reject";

// Ord a => [a] -> a
const minIn = compose(converge(reduce(min), [head, identity]), reject(isNil));

export default minIn;

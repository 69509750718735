import {
  compose,
  identity,
  ifElse,
  isArray,
  isEven,
  isNil,
  isNonEmptyArray,
  isNotNil,
  lensPath,
  map,
  mapIndexed,
  mapped,
  mergeDeepRight,
  not,
  over,
  path,
  set,
  slugify,
  traversed,
  uniqBy,
  view,
  when,
} from "../../misc/fp";

// Generic lenses (relative lenses)
const colorLens = lensPath(["color"]);
const xAxisLens = lensPath(["xAxis"]);
const yAxisLens = lensPath(["yAxis"]);
const eventsLens = lensPath(["events"]);
const setAfterSetExtremes = set(
  compose(eventsLens, lensPath(["afterSetExtremes"]))
);

const updateWith = (update) =>
  when(
    isNotNil,
    map((current) => mergeDeepRight(current, update(current)))
  );

// chart
const chartLens = lensPath(["chart"]);
const setChart = set(chartLens);

// chart > height
const chartHeightLens = compose(chartLens, lensPath(["height"]));
const getChartHeight = view(chartHeightLens);
const setChartHeight = set(chartHeightLens);

// chart > width
const chartWidthLens = compose(chartLens, lensPath(["width"]));
const getChartWidth = view(chartWidthLens);
const setChartWidth = set(chartWidthLens);

// chart > type
const chartTypeLens = compose(chartLens, lensPath(["type"]));
const setChartType = set(chartTypeLens);

// chart > inverted
const invertedLens = compose(chartLens, lensPath(["inverted"]));
const setInverted = set(invertedLens, true);

// chart > zoomtype
const zoomTypeLens = compose(chartLens, lensPath(["zoomType"]));
const setZoomType = set(zoomTypeLens);

// chart > events
const chartEventsLens = compose(chartLens, eventsLens);

// chart > events > render
const chartRenderEventLens = compose(chartEventsLens, lensPath(["render"]));
const setChartRenderEvent = set(chartRenderEventLens);

// chart > events > load
const loadEventLens = compose(chartEventsLens, lensPath(["load"]));
const setLoadEvent = set(loadEventLens);

// chart > events > selection
const selectionEventLens = compose(chartEventsLens, lensPath(["selection"]));
const setSelectionEvent = set(selectionEventLens);

// chart > events > selection > event
const selectionEventPrimaryXAxis = path(["xAxis", 0]);
const selectionEventPrimaryYAxis = path(["yAxis", 0]);

// exporting
const exportingLens = lensPath(["exporting"]);

// exporting > buttons > contextButton
const exportingContextButtonLens = compose(
  exportingLens,
  lensPath(["buttons", "contextButton"])
);

// exporting > buttons > contextbutton > onclick
const contextButtonOnClickLens = compose(
  exportingContextButtonLens,
  lensPath(["onclick"])
);
const setOnClickContextButton = set(contextButtonOnClickLens);

// exporting > enabled
const exportingEnabledLens = compose(exportingLens, lensPath(["enabled"]));
const setExportingEnabled = set(exportingEnabledLens);

// exporting > filename
const exportingFilenameLens = compose(exportingLens, lensPath(["filename"]));
const setExportingFilename = (title) =>
  compose(set(exportingFilenameLens), slugify)(title);

// credits
const creditsLens = lensPath(["credits"]);
const setCredits = set(creditsLens);
const disableCredits = set(creditsLens, { enabled: false });

// legend
const legendLens = lensPath(["legend"]);

// legend > itemStyle
const legendItemStyleLens = compose(legendLens, lensPath(["itemStyle"]));
const setLegendItemStyle = set(legendItemStyleLens);

// plotOptions
const plotOptionsLens = lensPath(["plotOptions"]);
const setPlotOptions = set(plotOptionsLens);
const overPlotOptions = over(plotOptionsLens);

// series
const seriesLens = lensPath(["series"]);
const setSeries = set(seriesLens);
const overSeries = over(seriesLens);
const viewSeries = view(seriesLens);

// tooltip
const tooltipLens = lensPath(["tooltip"]);
const setTooltip = set(tooltipLens);

// tooltip > crosshairs
const tooltipCrosshairsLens = compose(tooltipLens, lensPath(["crosshairs"]));
const setTooltipCrosshairs = set(tooltipCrosshairsLens);

// tooltip > shared
const tooltipSharedLens = compose(tooltipLens, lensPath(["shared"]));
const setTooltipShared = set(tooltipSharedLens);

// tooltip > header format
const tooltipHeaderFormat = compose(tooltipLens, lensPath(["headerFormat"]));
const setTooltipHeaderFormat = set(tooltipHeaderFormat);

// title
const titleLens = lensPath(["title"]);
const titleTextLens = compose(titleLens, lensPath(["text"]));
const setTitleText = set(titleTextLens);

const setChartTitle = (title) =>
  compose(setTitleText(title), setExportingFilename(title));

// title > style
const titleStyleLens = compose(titleLens, lensPath(["style"]));
const setTitleStyle = set(titleStyleLens);

// - Axis (generic)
const oppositeLens = lensPath(["opposite"]);
const setOpposite = set(oppositeLens, true);
const alternatingOpposite = ifElse(
  isNil,
  identity,
  mapIndexed((o, idx) => (isEven(idx) ? o : setOpposite(o)))
);

// xAxis
/*
  Appends value to an existing array at lens path. If it does not exist, a new
  array is created and value appended to it.
  Furthermore, if the current value at lens path is not an array nor nil, a new
  array will be created that contains the current value and the new value is
  appended to that array.

  Note: Primarily used to append new axis to axis arrays
 */
const appendOrCreateArray = (lens) => (value) => (data) => {
  const currValue = view(lens, data);
  if (isNil(currValue)) {
    return set(lens, [value], data);
  } else if (not(isArray(currValue))) {
    return set(lens, [currValue, value], data);
  }
  currValue.push(value);
  return set(lens, currValue, data);
};

const axisTypeLens = lensPath(["type"]);
const setAxisType = set(axisTypeLens);

const axisMinLens = lensPath(["min"]);
const setAxisMin = set(axisMinLens);

const axisSoftMinLens = lensPath(["softMin"]);
const setAxisSoftMin = set(axisSoftMinLens);

const axisMaxLens = lensPath(["max"]);
const setAxisMax = set(axisMaxLens);

const axisSoftMaxLens = lensPath(["softMax"]);
const setAxisSoftMax = set(axisSoftMaxLens);

const axisTitle = lensPath(["title"]);
const setAxisTitle = set(axisTitle);

const setXAxisTitleStyle = set(
  compose(xAxisLens, mapped, lensPath(["title", "style"]))
);
const setXAxisOffset = set(compose(xAxisLens, mapped, lensPath(["offset"])));
const setXAxisLabels = set(compose(xAxisLens, mapped, lensPath(["labels"])));
const setXAxisMin = set(compose(xAxisLens, mapped, lensPath(["min"])));
const setXAxisMax = set(compose(xAxisLens, mapped, lensPath(["max"])));
const viewXAxis = view(xAxisLens);
const setXAxis = set(xAxisLens);

const overXAxis = (fn) =>
  when(
    compose(isNonEmptyArray, viewXAxis),
    over(compose(xAxisLens, traversed), fn)
  );
const setXAxisSetExtremesEvent = set(
  compose(xAxisLens, traversed, eventsLens, lensPath(["setExtremes"]))
);
const setXAxisAfterSetExtremesEvent = set(
  compose(xAxisLens, traversed, eventsLens, lensPath(["afterSetExtremes"]))
);
// const setXAxisAfterSetExtremesEvent = event =>
//   over(xAxisLens, map(setAfterSetExtremes(event)));

// yAxis
const viewYAxis = view(yAxisLens);
const setYAxis = set(yAxisLens);
const setAlternatingYAxes = over(yAxisLens, alternatingOpposite);
const overYAxis = (fn) =>
  when(
    compose(isNonEmptyArray, viewYAxis),
    over(compose(yAxisLens, traversed), fn)
  );

const overYAxisPlotBands = over(
  compose(yAxisLens, traversed, lensPath(["plotBands"]), traversed)
);

const setAlternatingAxes = compose(
  over(yAxisLens, alternatingOpposite),
  over(xAxisLens, alternatingOpposite)
);

const axesUniqueById = compose(
  over(yAxisLens, ifElse(isNil, identity, uniqBy(path(["id"])))),
  over(xAxisLens, ifElse(isNil, identity, uniqBy(path(["id"]))))
);

const setYAxisAfterSetExtremesEvent = (event) =>
  over(yAxisLens, map(setAfterSetExtremes(event)));

/*
  Globals

  Settings that are applied to all charts.

  Note
  ----
  Must be set before any chart is
  initialised.
*/

// lang
const langLens = lensPath(["lang"]);
const setLang = set(langLens);

const colorsLens = lensPath(["colors"]);
const setColors = set(colorsLens);

export { appendOrCreateArray };
export { axesUniqueById };
export { colorLens };
export { disableCredits };
export { exportingEnabledLens };
export { getChartHeight };
export { getChartWidth };
export { overPlotOptions };
export { overSeries };
export { overXAxis };
export { overYAxis };
export { plotOptionsLens };
export { selectionEventPrimaryXAxis };
export { selectionEventPrimaryYAxis };
export { seriesLens };
export { setAfterSetExtremes };
export { setAlternatingAxes };
export { setAlternatingYAxes };
export { setAxisMax };
export { setAxisMin };
export { setAxisSoftMax };
export { setAxisSoftMin };
export { setAxisTitle };
export { setAxisType };
export { setChart };
export { setChartHeight };
export { setChartRenderEvent };
export { setChartTitle };
export { setChartType };
export { setChartWidth };
export { setColors };
export { setCredits };
export { setExportingEnabled };
export { setExportingFilename };
export { setInverted };
export { setLang };
export { setLegendItemStyle };
export { setLoadEvent };
export { setOnClickContextButton };
export { setPlotOptions };
export { setSelectionEvent };
export { setSeries };
export { setTitleStyle };
export { setTooltip };
export { setTooltipCrosshairs };
export { setTooltipHeaderFormat };
export { setTooltipShared };
export { setXAxisAfterSetExtremesEvent };
export { setXAxisLabels };
export { setXAxisMax };
export { setXAxisMin };
export { setXAxisOffset };
export { setXAxisSetExtremesEvent };
export { setXAxisTitleStyle };
export { setXAxis };
export { setYAxis };
export { overYAxisPlotBands };
export { setYAxisAfterSetExtremesEvent };
export { setZoomType };
export { updateWith };
export { viewSeries };
export { viewXAxis };
export { viewYAxis };
export { xAxisLens };
export { yAxisLens };

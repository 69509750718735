import { injectIntl } from "react-intl";
import { withProps } from "recompose";
import map from "./map";
import compose from "./compose";

const translateMessages = (messages) =>
  compose(
    injectIntl,
    withProps(({ intl, messageVariables }) => ({
      messages: map((message) => intl.formatMessage(message, messageVariables))(
        messages
      ),
    }))
  );

export default translateMessages;

import React, { useCallback } from "react";
import styled from "styled-components";

import { color, size } from "../../constants";
import { map, partition, prop } from "../misc/fp";
import useState from "../hooks/useState";
import Popover from "./Popover";
import Flex from "./Flex";
import Link from "./Link";

const columnWidth = 300;
const padding = 24;
const borderWidth = 1;
const twoColumnWidth = 2 * columnWidth + padding + 2 * borderWidth;
const oneColumnWidth = columnWidth + padding + 2 * borderWidth;

const OverlayMenu = styled.div`
  margin: -12px -16px;
  overflow-y: auto;
  max-height: 90vh;
  border: ${size.borderWidth} solid ${color.border};
  border-radius: ${size.borderRaidus};
`;

const Container = styled(Flex)`
  flex-wrap: wrap;
  border-radius: ${size.borderRaidus};
  padding-left: ${padding}px;
  padding-top: ${padding}px;
  padding-bottom: ${padding / 2}px;
`;

const FooterContainer = styled(Container)`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: ${size.borderWidth}px solid ${color.border};
  background-color: #f6f9fc;
  padding-top: ${padding * 1.5}px;
`;

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-basis: ${(props) => (props.focus ? "300px" : "200px")};
  min-width: min-content;
  color: unset;
  font-size: ${(props) => (props.focus ? "16px" : "14px")};
  font-weight: ${(props) => (props.focus ? 500 : 300)};
  padding-bottom: ${(props) => (props.focus ? `${padding}px` : "24px")};
  padding-right: ${padding}px;
`;

const IconContainer = styled.span`
  color: ${(props) => (props.focus ? color.primary : color.textMuted)};
  font-size: ${(props) => (props.focus ? "28px" : "24px")};
  margin-right: ${(props) => (props.focus ? "10px" : "6px")};
  width: 32px;
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled.div`
  font-size: 20px;
  width: 100%;
  font-weight: 600;
`;

const TitleSeparator = styled.div`
  width: 50px;
  margin: 4px 0;
  border-top: 1px solid;
  color: ${color.primary};
`;

const Subtitle = styled.span`
  color: ${color.textMuted};
  font-size: 12px;
  font-weight: 300;
`;

const mapLinks = (onClose) =>
  map((link) => (
    <MenuLink to={link.path} focus={link.focus} onClick={onClose}>
      <IconContainer focus={link.focus}>{link.icon}</IconContainer>
      <Flex.Column>
        {link.title}
        {link.focus && <Subtitle>{link.subTitle}</Subtitle>}
      </Flex.Column>
    </MenuLink>
  ));

const MegaMenu = ({
  links,
  title,
  subtitle,
  isVertical,
  children,
  ...props
}) => {
  const [visible, setVisible] = useState(false);
  const [focusLinks, smallLinks] = partition(prop("focus"), links);
  const onClose = useCallback(() => setVisible(false), [setVisible]);
  const overlay = (
    <OverlayMenu
      style={{ width: `${isVertical ? twoColumnWidth : oneColumnWidth}px` }}
      {...props}
    >
      <Container>
        <TitleContainer>
          {title}
          <span
            style={{ marginLeft: "4px", fontSize: "16px", fontWeight: "300" }}
          >
            {subtitle}
          </span>
        </TitleContainer>
        <div style={{ width: "100%", marginBottom: `${padding}px` }}>
          <TitleSeparator />
        </div>
        {mapLinks(onClose)(focusLinks)}
      </Container>
      <FooterContainer>{mapLinks(onClose)(smallLinks)}</FooterContainer>
    </OverlayMenu>
  );
  return (
    <Popover
      content={overlay}
      visible={visible}
      onVisibleChange={setVisible}
      placement={isVertical ? "rightTop" : "topLeft"}
    >
      {children({ visible, setVisible })}
    </Popover>
  );
};

export default MegaMenu;

import curryN from "./curryN";
import has from "./has";
import head from "./head";
import isObj from "./isObj";
import length from "./length";
import path from "./path";
import tail from "./tail";

const hasPath = curryN(2, (objPath, obj) => {
  const prop = head(objPath);
  // termination conditions
  if (length(objPath) === 0 || !isObj(obj)) {
    return false;
  }
  if (length(objPath) === 1) {
    return has(prop, obj);
  }
  return hasPath(tail(objPath), path([prop], obj)); // base case
});

export default hasPath;

import gql from "graphql-tag";

export default gql`
  query CompanyBreadcrumbQuery($id: ID!) {
    item: organization(id: $id) {
      id
      description: name
    }
  }
`;

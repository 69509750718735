import React from "react";
import ReactMarkdown from "react-markdown";
import Link from "./Link";

const internalLinkPrefix = "/";

const LinkRenderer = ({ href, children }) => {
  if (href.startsWith(internalLinkPrefix)) {
    return <Link to={href}>{children}</Link>;
  } else {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
};

const Markdown = ({ source }) => {
  return <ReactMarkdown children={source} components={{ a: LinkRenderer }} />;
};

export default Markdown;

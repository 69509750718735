import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import APP_SEARCH_FRAGMENT from "../fragments/AppSearchFragment";
import useDebouncedCallback from "../hooks/useDebouncedCallback";
import useRef from "../hooks/useRef";
import useQuery from "../hooks/useQuery";
import useRouter from "../hooks/useRouter";
import useTranslations from "../hooks/useTranslations";
import {
  assoc,
  compose,
  evolve,
  flip,
  groupBy,
  indexOf,
  map,
  objOf,
  pathOr,
  pick,
  prop,
  sortBy,
  values,
} from "../misc/fp";
import { DATA_TYPE_UUID, ICON_TYPE_BY_DATA_TYPE } from "../misc/constants";
import APP_SEARCH_QUERY from "../queries/AppSearchQuery";
import Flex from "./Flex";
import Link from "./Link";
import List from "./List";
import SearchFilter from "./SearchFilter";
import PaginatedList from "./PaginatedList";
import Drawer from "./Drawer";
import useQueryStringValue from "../hooks/useQueryStringValue";

const translations = defineMessages({
  PLACEHOLDER: {
    id: "globalsearch.placeholder",
    defaultMessage: "Search...",
  },
});

const addId = assoc("id");
const addUuid = assoc("uuid");
const addTitle = assoc("title");
const shapeAsset = compose(
  addId("Asset"),
  addUuid(DATA_TYPE_UUID.ASSET),
  addTitle(
    <FormattedMessage id="globalsearch.assets" defaultMessage="Asset" />
  ),
  objOf("children"),
  map((o) => ({
    id: o.id,
    type: o.__typename,
    title: o.description,
    url: `/assets/${o.id}/`,
  }))
);
const shapeRegistry = compose(
  addId("Registry"),
  addUuid(DATA_TYPE_UUID.REGISTRY),
  addTitle(
    <FormattedMessage id="globalsearch.registries" defaultMessage="Registry" />
  ),
  objOf("children"),
  map((o) => ({
    id: o.id,
    type: o.__typename,
    title: o.description,
    url: `/registries/${o.id}/`,
  }))
);
const shapeDataset = compose(
  addId("DataSet"),
  addUuid(DATA_TYPE_UUID.DATASET),
  addTitle(
    <FormattedMessage id="globalsearch.datasets" defaultMessage="Dataset" />
  ),
  objOf("children"),
  map((o) => ({
    id: o.id,
    type: "DataSet",
    title: o.description,
    url: `/datasets/${o.id}/${o.type.uuid}/`,
  }))
);
const shapeChart = compose(
  addId("Chart"),
  addUuid(DATA_TYPE_UUID.CHART),
  addTitle(
    <FormattedMessage id="globalsearch.charts" defaultMessage="Chart" />
  ),
  objOf("children"),
  map((o) => ({
    id: o.id,
    type: "Chart",
    title: o.description,
    url: `/charts/${o.id}/`,
  }))
);
const shapeDashboard = compose(
  addId("Dashboard"),
  addUuid(DATA_TYPE_UUID.DASHBOARD),
  addTitle(
    <FormattedMessage id="globalsearch.dashboard" defaultMessage="Dashboards" />
  ),
  objOf("children"),
  map((o) => ({
    id: o.id,
    type: "Dashboard",
    title: o.description,
    url: `/dashboards/${o.id}/`,
  }))
);
const shapeExternalDataset = compose(
  addId("ExternalDataset"),
  addUuid(DATA_TYPE_UUID.EXTERNAL_DATASET),
  addTitle(
    <FormattedMessage
      id="globalsearch.externaldatasets"
      defaultMessage="External dataset"
    />
  ),
  objOf("children"),
  map((o) => ({
    id: o.id,
    type: "ExternalDataset",
    title: o.description,
    url: `/external-datasets/${o.id}/`,
  }))
);
const shapeDataConnection = compose(
  addId("DataConnection"),
  addUuid(DATA_TYPE_UUID.DATA_CONNECTION),
  addTitle(
    <FormattedMessage
      id="globalsearch.dataconnection"
      defaultMessage="Data connection"
    />
  ),
  objOf("children"),
  map((o) => ({
    id: o.id,
    type: "DataConnection",
    title: o.description,
    url: `/data-connections/${o.id}/`,
  }))
);
const shapeSample = compose(
  addId("Sample"),
  addUuid(DATA_TYPE_UUID.SAMPLE),
  addTitle(
    <FormattedMessage id="globalsearch.samples" defaultMessage="Sample" />
  ),
  objOf("children"),
  map((o) => ({
    id: o.id,
    type: o.__typename,
    title: o.description,
    url: `/sample/${o.id}/`,
  }))
);
const shapeTimeSeries = compose(
  addId("TimeSeries"),
  addUuid(DATA_TYPE_UUID.TIME_SERIES),
  addTitle(
    <FormattedMessage
      id="globalsearch.TimeSeries"
      defaultMessage="Time series"
    />
  ),
  objOf("children"),
  map((o) => ({
    id: o.id,
    type: o.__typename,
    title: o.description,
    url: `/time-series/${o.id}/`,
  }))
);
const shapeParameter = compose(
  addId("Parameter"),
  addUuid(DATA_TYPE_UUID.PARAMETER),
  addTitle(
    <FormattedMessage id="globalsearch.Parameter" defaultMessage="Parameter" />
  ),
  objOf("children"),
  map((o) => ({
    id: o.id,
    type: o.__typename,
    title: o.description,
    url: `/Parameter/${o.id}/`,
  }))
);
const shapeWellTest = compose(
  addId("WellTest"),
  addUuid(DATA_TYPE_UUID.WELL_TEST),
  addTitle(
    <FormattedMessage id="globalsearch.WellTest" defaultMessage="Well test" />
  ),
  objOf("children"),
  map((o) => ({
    id: o.id,
    type: o.__typename,
    title: o.description,
    url: `/well-test/${o.id}/`,
  }))
);
const shapeWellBaseCurve = compose(
  addId("WellBaseCurve"),
  addUuid(DATA_TYPE_UUID.WELL_BASE_CURVE),
  addTitle(
    <FormattedMessage
      id="globalsearch.WellBaseCurve"
      defaultMessage="Well base curve"
    />
  ),
  objOf("children"),
  map((o) => ({
    id: o.id,
    type: o.__typename,
    title: o.description,
    url: `/well-test/${o.id}/`,
  }))
);

const searchGroupOrder = [
  "Asset",
  "DataSet",
  "DataConnection",
  "ExternalDataset",
  "Registry",
  "Chart",
  "Dashboard",
  "Sample",
  "TimeSeries",
  "ParameterTemp",
  "WellTest",
  "WellBaseCurve",
];
const sortByGroup = sortBy(
  compose(flip(indexOf)(searchGroupOrder), prop("group"))
);

const shapeData = compose(
  compose(
    sortByGroup,
    values,
    evolve({
      Asset: shapeAsset,
      DataSet: shapeDataset,
      Registry: shapeRegistry,
      ExternalDataset: shapeExternalDataset,
      DataConnection: shapeDataConnection,
      Chart: shapeChart,
      Dashboard: shapeDashboard,
      Sample: shapeSample,
      TimeSeries: shapeTimeSeries,
      ParameterTemp: shapeParameter,
      BoreholeMeasurementSeries: shapeWellTest,
      BoreholeMeasurementBaseCurve: shapeWellBaseCurve,
    }),
    pick([
      // guard against new types that haven't been handled
      "Asset",
      "DataSet",
      "DataConnection",
      "ExternalDataset",
      "Chart",
      "Dashboard",
      "Registry",
      "Sample",
      "TimeSeries",
      "ParameterTemp",
      "BoreholeMeasurementSeries",
      "BoreholeMeasurementBaseCurve",
    ]),
    groupBy(prop("__typename")),
    map(prop("node"))
  ),
  pathOr([], ["search", "edges"])
);

const QUERY_DELAY = 850; // ms

const SearchDrawer = (props) => {
  const { history } = useRouter();
  const inputRef = useRef(null);
  const messages = useTranslations(translations);
  const onSelect = (url) => () => {
    props.onClose();
    history.push(url);
  };
  const [search, setSearch] = useQueryStringValue("globalSearch", "");
  const [setDebouncedSearch] = useDebouncedCallback(setSearch, QUERY_DELAY);
  const { data, loading } = useQuery(APP_SEARCH_QUERY, {
    variables: { text: search },
  });
  const results = shapeData(data);
  return (
    <Drawer
      width="500px"
      placement="left"
      noPadding
      title={
        <SearchFilter
          ref={inputRef}
          style={{ width: "100%", height: "40px" }}
          loading={loading}
          placeholder={messages.PLACEHOLDER()}
          value={search}
          updateValue={setDebouncedSearch}
        />
      }
      afterVisibleChange={(visible) => visible && inputRef.current.focus()}
      {...props}
    >
      <Flex.Column style={{ width: "100%", height: "100%" }}>
        <PaginatedList dataSource={results}>
          {(group) =>
            group.children.map((result) => (
              <List.Item
                clickable
                title={<Link to={result.url}>{result.title}</Link>}
                onClick={onSelect(result.url)}
                description={
                  <span>
                    {ICON_TYPE_BY_DATA_TYPE[group.uuid]}&nbsp;
                    {group.title}
                  </span>
                }
              />
            ))
          }
        </PaginatedList>
      </Flex.Column>
    </Drawer>
  );
};
SearchDrawer.fragments = {
  search: APP_SEARCH_FRAGMENT,
};
export default SearchDrawer;

import { Modal } from "antd";
import { zIndex } from "../../constants";

import styled from "styled-components";

Modal.FakeFooter = styled.div`
  padding: 10px 16px;
  border-top: 1px solid #e8e8e8;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  text-align: right;

  & button + button {
    margin-bottom: 0;
    margin-left: 8px;
  }
`;

Modal.defaultProps = {
  ...Modal.defaultProps,
  closable: true,
  maskClosable: false,
  destroyOnClose: true,
  zIndex: zIndex.modal,
};

export default Modal;

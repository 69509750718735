import gql from "graphql-tag";

export default gql`
  query RegistryTypeBreadcrumbQuery($id: ID!) {
    item: registryType(id: $id) {
      id
      description
    }
  }
`;

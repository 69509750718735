import gql from "graphql-tag";

const resultsKey = "activateAccount";

export default gql`
  mutation ActivateAccount( $token: String!, $uid: String! ) {
    ${resultsKey}(input: { 
        token: $token,
        uid: $uid
    }) {
  message
    }
  }
`;

const urlPrefix = "app";

const color = {
  primary: "#EF7747",
  secondary: "#46A8DE",
  text: "#302D2D",
  textLight: "#585555",
  textMuted: "#9e9b9b",
  componentBackground: "#FFFFFF",
  background: "#E6F7FF",
  backgroundGray: "#f0f2f5",
  border: "#E8E8E8",
  success: "#52c41a",
  warning: "#FAAD14",
  error: "#F5222D",
};

const zIndex = {
  modal: 1004,
};

const size = {
  borderRadius: "2px",
  fontSize: "14px",
  borderWidth: "1px",
};

module.exports = {
  color,
  zIndex,
  size,
  urlPrefix
};

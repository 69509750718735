import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";

import { publicClient } from "../services/client";
import Button from "../shared/components/Button";
import Card from "../shared/components/Card";
import Flex from "../shared/components/Flex";
import Form from "../shared/components/Form";
import Input from "../shared/components/Input";
import Link from "../shared/components/Link";
import LogoPageHeader from "../shared/components/LogoPageHeader";
import Margin from "../shared/components/Margin";
import message from "../shared/components/message";
import Typography from "../shared/components/Typography";

import useDocumentTitle from "../shared/hooks/useDocumentTitle";
import useForm from "../shared/hooks/useForm";

import useMutation from "../shared/hooks/useMutation";
import useQueryStringValue from "../shared/hooks/useQueryStringValue";
import useRouter from "../shared/hooks/useRouter";
import useTranslations from "../shared/hooks/useTranslations";
import RESET_PASSWORD_MUTATION from "../shared/mutations/ResetPasswordMutation";
import CONFIRM_PASSWORD_RESET_MUTATION from "../shared/mutations/ConfirmPasswordResetMutation";
import PageTemplate from "../shared/templates/PageTemplate";
import * as registerPage from "./RegisterPage";
import { isNil, any } from "../shared/misc/fp";

export const path = "/reset-password/";

const CenteredText = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const ResetPasswordForm = ({ refetchQueries }) => {
  const messages = useTranslations(
    defineMessages({
      emailPlaceholder: {
        id: "resetPasswordPage.email.placeholder",
        defaultMessage: "e-mail",
      },
      emailRequired: {
        id: "resetPasswordPage.email.required",
        defaultMessage: "e-mail is required",
      },
      emailInvalid: {
        id: "resetPasswordPage.email.invalid",
        defaultMessage: "The entered e-mail is invalid",
      },
    })
  );
  const [form] = useForm();
  const { history } = useRouter();

  const [resetPassword, { loading: submissionLoading }] = useMutation(
    RESET_PASSWORD_MUTATION,
    {
      client: publicClient,
      onCompleted: (data) => {
        history.push("/");
        message.success(
          <FormattedMessage
            id="resetPasswordPage.success"
            defaultMessage="Password reset link sent. Check your inbox"
          />
        );
      },
      onError: form.setFieldsValidationErrors,
    }
  );
  return (
    <Form
      form={form}
      size="large"
      onFinish={(variables) => {
        resetPassword({
          variables,
          refetchQueries,
        });
      }}
    >
      <Form.Item>
        <Alert
          type="info"
          message={
            <FormattedMessage
              id="resetPasswordPage.info"
              defaultMessage="Enter the e-mail you used to register and we'll e-mail you a password reset link"
            />
          }
        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: messages.emailRequired(),
          },
          {
            type: "email",
            message: messages.emailInvalid(),
          },
        ]}
        hasFeedback
      >
        <Input
          prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          type="email"
          placeholder={messages.emailPlaceholder()}
        />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Button
          block
          type="primary"
          htmlType="submit"
          loading={submissionLoading}
        >
          <FormattedMessage
            id="resetPasswordPage.submit"
            defaultMessage="Reset"
          />
        </Button>
      </Form.Item>
    </Form>
  );
};

const ConfirmPasswordResetForm = ({ token, uid, refetchQueries }) => {
  const messages = useTranslations(
    defineMessages({
      passwordPlaceholder: {
        id: "resetPasswordPage.password.placeholder",
        defaultMessage: "Password",
      },
      passwordRequired: {
        id: "resetPasswordPage.password.required",
        defaultMessage: "You must enter a password",
      },
      confirmationPasswordPlaceholder: {
        id: "resetPasswordPage.confirmationPassword.placeholder",
        defaultMessage: "Confirm password",
      },
      confirmationPasswordRequired: {
        id: "resetPasswordPage.confirmationPassword.required",
        defaultMessage:
          "Please confirm password to make sure mistakes were not made",
      },
    })
  );
  const [form] = useForm();
  const { history } = useRouter();
  const [confirmPasswordReset, { loading }] = useMutation(
    CONFIRM_PASSWORD_RESET_MUTATION,
    {
      client: publicClient,
      onCompleted: (data) => {
        history.push("/");
        message.success(
          <FormattedMessage
            id="resetPasswordPage.confirmPasswordResetForm.success"
            defaultMessage="Password has been set. You can now login with your e-mail and new password"
          />
        );
      },
      onError: form.setFieldsValidationErrors,
    }
  );
  return (
    <Form
      form={form}
      size="large"
      onFinish={(variables) => {
        confirmPasswordReset({
          variables: {
            ...variables,
            token,
            uid,
          },
          refetchQueries,
        });
      }}
    >
      <Form.Item>
        <Alert
          type="info"
          message={
            <FormattedMessage
              id="resetPasswordPage.confirmPasswordReset.info"
              defaultMessage="Choose a password"
            />
          }
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: messages.passwordRequired() }]}
        hasFeedback
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder={messages.passwordPlaceholder()}
        />
      </Form.Item>
      <Form.Item
        name="confirmationPassword"
        rules={[
          {
            required: true,
            message: messages.confirmationPasswordRequired(),
          },
        ]}
        hasFeedback
      >
        <Input.Password
          prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          placeholder={messages.confirmationPasswordPlaceholder()}
        />
      </Form.Item>
      <Form.Item style={{ marginBottom: 0 }}>
        <Button block type="primary" htmlType="submit" loading={loading}>
          <FormattedMessage
            id="resetPasswordPage.confirmPasswordReset.submit"
            defaultMessage="Set password"
          />
        </Button>
      </Form.Item>
    </Form>
  );
};

const ResetPasswordPage = ({ refetchQueries }) => {
  const messages = useTranslations(
    defineMessages({
      requestResetPageTitle: {
        id: "resetPasswordPage.pageTitle",
        defaultMessage: "Reset Password",
      },
      requestResetTitle: {
        id: "resetPasswordPage.title",
        defaultMessage: "Reset password",
      },
      pageTitle: {
        id: "resetPasswordPage.step2.pageTitle",
        defaultMessage: "Set Password",
      },
      title: {
        id: "resetPasswordPage.step2.title",
        defaultMessage: "Set Password",
      },
    })
  );

  const [token] = useQueryStringValue("token");
  const [uid] = useQueryStringValue("uid");
  const isRequestReset = any(isNil, [token, uid]);

  useDocumentTitle(
    isRequestReset ? messages.requestResetPageTitle() : messages.pageTitle()
  );

  return (
    <PageTemplate
      header={<LogoPageHeader />}
      content={
        <Flex justifyContent="center">
          <div>
            <Margin bottom="16px">
              <CenteredText>
                <Typography.Title style={{ fontWeight: 200 }}>
                  {isRequestReset
                    ? messages.requestResetTitle()
                    : messages.title()}
                </Typography.Title>
              </CenteredText>
            </Margin>
            <Card style={{ width: "400px" }}>
              {isRequestReset ? (
                <ResetPasswordForm refetchQueries={refetchQueries} />
              ) : (
                <ConfirmPasswordResetForm
                  refetchQueries={refetchQueries}
                  uid={uid}
                  token={token}
                />
              )}
            </Card>
            <Flex justifyContent="center">
              <Margin betweenChildren="16px" top="16px">
                <Link to={registerPage.path}>
                  <FormattedMessage
                    id="resetPasswordPage.register"
                    defaultMessage="Register"
                  />
                </Link>
                <Link to="/">
                  <FormattedMessage
                    id="resetPasswordPage.login"
                    defaultMessage="Login"
                  />
                </Link>
              </Margin>
            </Flex>
          </div>
        </Flex>
      }
    />
  );
};

ResetPasswordPage.propTypes = {
  refetchQueries: PropTypes.arrayOf(
    PropTypes.shape({
      query: PropTypes.any.isRequired,
      variables: PropTypes.object.isRequired,
    })
  ),
};

export default ResetPasswordPage;

import compose from "./compose";
import converge from "./converge";
import head from "./head";
import identity from "./identity";
import isNil from "./isNil";
import max from "./max";
import reduce from "./reduce";
import reject from "./reject";

// Ord a => [a] -> a
const maxIn = compose(converge(reduce(max), [head, identity]), reject(isNil));

export default maxIn;

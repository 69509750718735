import { useState } from "react";

import { not, thunkify } from "../misc/fp";

const useToggle = (initialState = false) => {
  const [value, setValue] = useState(initialState);
  const off = thunkify(setValue)(false);
  const on = thunkify(setValue)(true);
  const toggle = thunkify(setValue)(not);

  return [value, toggle, off, on];
};

export default useToggle;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Card as AntCard } from "antd";
import { isNil, not } from "../misc/fp";

const BODY_PADDING = "24px";

const StyledCard = styled(AntCard)`
  margin: ${(props) => props.inGrid && "0 !important"};
  display: ${(props) => (props.inGrid || props.fillHeight) && "flex"};
  flex-direction: ${(props) => (props.inGrid || props.fillHeight) && "column"};
  flex: ${(props) => (props.inGrid || props.fillHeight) && "1"};
  // allow item to shrink below content height
  min-height: ${(props) => props.fillHeight && "0"};

  & > .ant-card-body {
    flex: ${(props) => (props.inGrid || props.fillHeight) && "1"};
    display: ${(props) => props.fillHeight && "flex"};
    flex-direction: ${(props) => props.fillHeight && "column"};
    // allow item to shrink below content height
    min-height: ${(props) => props.fillHeight && "0"};
    flex-basis: ${(props) => props.fillHeight && "300px"};

    & > .ant-card-loading-content {
      flex: ${(props) => props.fillHeight && "1"};
    }

    & > * {
      // allow item to shrink below content height
      min-height: ${(props) => props.fillHeight && "0"};
    }
  }

  & .ant-card-head {
    border-color: ${(props) => !props.separator && "transparent"};
  }
  & .ant-card-actions {
    border-color: ${(props) => props.actionsTransparent && "transparent"};
    background: ${(props) => props.actionsTransparent && "transparent"};
  }
  & .ant-card-actions > li:not(:last-child) {
    border-color: ${(props) => props.actionsTransparent && "transparent"};
  }
`;

const FooterRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const FooterLeft = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 16px 0;

  ${FooterLeft} {
    & > button {
      margin-right: 8px;
    }
  }

  ${FooterRight} {
    & > button {
      margin-left: 8px;
    }
  }
`;

const Card = ({
  separator,
  clean,
  actionsTransparent,
  inGrid,
  footer,
  bodyStyle,
  children,
  ...props
}) => {
  const newProps = {
    actionsTransparent: clean ? true : actionsTransparent,
    separator: clean ? false : separator,
    // remove padding if body is empty and card is not loading
    bodyStyle:
      isNil(children) && not(props.loading)
        ? { padding: "0px", ...bodyStyle }
        : bodyStyle,
    inGrid,
  };
  return (
    <StyledCard {...{ ...newProps, ...props }}>
      {children}
      {footer && (
        <Footer>
          <FooterLeft>{footer.left}</FooterLeft>
          <FooterRight>{footer.right}</FooterRight>
        </Footer>
      )}
    </StyledCard>
  );
};
Card.defaultProps = {
  separator: true,
  clean: false,
  actionsTransparent: false,
  fillHeight: false,
};
Card.propTypes = {
  separator: PropTypes.bool,
  clean: PropTypes.bool,
  actionsTransparent: PropTypes.bool,
  fillHeight: PropTypes.bool,
};

Card.Meta = AntCard.Meta;
Card.Grid = AntCard.Grid;

const FillHeightCard = (props) => <Card {...props} />;
FillHeightCard.defaultProps = { fillHeight: true };
Card.FillHeight = FillHeightCard;

const CardWithScrollableBody = styled(FillHeightCard)`
  & > .ant-card-body {
    overflow-y: auto;
    padding: 0 !important; // fit scrollbar without padding

    & > * {
      min-height: min-content;
      padding: ${BODY_PADDING};
    }
  }
`;

Card.WithScrollableBody = CardWithScrollableBody;

const MapContainer = styled.div`
    margin-left: -${BODY_PADDING};
    margin-right: -${BODY_PADDING};
    margin-top: -${BODY_PADDING};
    margin-bottom: ${BODY_PADDING};
    height: ${(props) => (props.mapHeight ? props.mapHeight : "400px")};
  }
`;

Card.MapContainer = MapContainer;

export default Card;

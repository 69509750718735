import gql from "graphql-tag";
import { user as GLOBAL_SIDER_USER_FRAGMENT } from "../fragments/GlobalSiderFragments";

import { compose, path, reduce, pathOr } from "../misc/fp";

const resultsKey = "currentUser";
export const getCurrentUser = path([resultsKey]);
export const getDatasetPermissions = compose(
  reduce(
    (accumulated, item) => ({
      ...accumulated,
      [item.datasetId]: item.permissionLevel,
    }),
    {}
  ),
  pathOr([], [resultsKey, "datasetPermissions"])
);

export const getCurrentUserId = path([resultsKey, "id"]);

export default gql`
  query CurrentUserQuery {
    currentUser {
      id
      initials
      isSuperuser
      avatar: image
      shortName
      datasetPermissions {
        datasetId
        permissionLevel
      }
      ...GlobalSiderUserFragment
    }
  }
  ${GLOBAL_SIDER_USER_FRAGMENT}
`;

import curry from "./curry";

const mapObjKeys = curry((fn, obj) => {
  const newObj = {};
  for (const key in obj) {
    const newKey = fn(key);
    newObj[newKey] = obj[key];
  }

  return newObj;
});

export default mapObjKeys;

import {
  always,
  alwaysArray as fpAlwaysArray,
  compose,
  isNil,
  isNotNil,
  prop,
  qsParse,
  qsStringify,
  when,
} from "../misc/fp";

import useEffect from "./useEffect";
import useState from "./useState";
import useHistory from "./useHistory";
import useCallback from "./useCallback";

// use window location search to make sure to get the current version of qs.
// useLocation hook is sometimes out of sync
const getQS = () =>
  qsParse(window.location.search, { parseNumbers: true, parseBooleans: true });

const useQueryStringValue = (valueKey, initialValue, alwaysArray = false) => {
  const qs = getQS();
  const valueFromQS = compose(
    // when an array of length 1 is written to qs it returns as a single value
    // instead of as an array of a single value. convert to array if applicable
    //
    // take care not to convert nil values to array as nil values should trigger
    // usage of initialValue
    when(always(alwaysArray), when(isNotNil, fpAlwaysArray)),
    prop(valueKey)
  )(qs);
  const defaultValue = alwaysArray ? [] : undefined;
  const [value, _setValue] = useState(
    isNil(valueFromQS)
      ? isNil(initialValue)
        ? defaultValue
        : initialValue
      : valueFromQS
  );
  const history = useHistory();

  // make sure next value is array if alwaysArray is true
  const setValue = useCallback(
    (nextValue) => {
      if (alwaysArray && isNil(nextValue)) {
        return _setValue([]);
      }
      _setValue(nextValue);
    },
    [_setValue, alwaysArray]
  );

  useEffect(() => {
    const nextQS = getQS();
    nextQS[valueKey] = value;
    history.replace({
      hash: window.location.hash,
      search: qsStringify(nextQS),
    });
  }, [value]); //eslint-disable-line react-hooks/exhaustive-deps

  return [value, setValue];
};

export default useQueryStringValue;

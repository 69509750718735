import styled from "styled-components";

const Padding = styled.div`
  padding: ${(props) => (props.px ? `${props.px}px` : undefined)};
  padding-top: ${(props) => (props.top ? `${props.top}` : undefined)};
  padding-bottom: ${(props) => (props.bottom ? `${props.bottom}` : undefined)};
  padding-left: ${(props) => (props.left ? `${props.left}` : undefined)};
  padding-right: ${(props) => (props.right ? `${props.right}` : undefined)};
  & > :not(:first-child) {
    padding: ${(props) => props.betweenChildren};
  }
`;

Padding.Vertical = styled.div`
  padding-top: ${(props) => (props.px ? `${props.px}px` : undefined)};
  padding-top: ${(props) => (props.top ? `${props.top}` : undefined)};
  padding-bottom: ${(props) => (props.bottom ? `${props.bottom}` : undefined)};
`;

Padding.Horizontal = styled.span`
  padding-left: ${(props) => (props.px ? `${props.px}px` : undefined)};
  padding-left: ${(props) => (props.left ? `${props.left}` : undefined)};
  padding-right: ${(props) => (props.right ? `${props.right}` : undefined)};
`;

export default Padding;

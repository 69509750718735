import { PageHeader as AntPageHeader } from "antd";
import React from "react";
import useRouter from "../hooks/useRouter";
import Skeleton from "./Skeleton";

const PageHeader = ({ loading, ghost, ...props }) => {
  const { history } = useRouter();
  const headerProps = {
    ghost,
    onBack: () => history.push("../"),
    style: {
      borderBottom: "1px solid rgba(0, 21, 41, 0.08);",
      paddingTop: "0px", // move closer to breadcrumbs
    },
  };
  if (loading) {
    return (
      <AntPageHeader title="" {...headerProps}>
        <Skeleton active avatar paragraph={false} />
      </AntPageHeader>
    );
  }
  return <AntPageHeader {...headerProps} {...props} />;
};

PageHeader.defaultProps = {
  loading: false,
  ghost: false,
};

export default PageHeader;

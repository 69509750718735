import { compose, path, pathOr, prop } from "../misc/fp";
import useState from "./useState";
import useQuery from "./useQuery";

const getEdges = (resultsKey) => path([resultsKey, "edges"]);
const getEdgesLength = (resultsKey) =>
  compose(prop("length"), getEdges(resultsKey));

export const prepareFetchMore = ({
  fetchMore,
  variables,
  resultsKey,
  data,
}) => () =>
  fetchMore({
    variables: {
      ...variables,
      after: path([resultsKey, "pageInfo", "endCursor"], data),
    },
    updateQuery: (prev, { fetchMoreResult: next }) =>
      getEdgesLength(resultsKey)(next)
        ? {
            [resultsKey]: {
              ...path([resultsKey], next),
              edges: [
                ...getEdges(resultsKey)(prev),
                ...getEdges(resultsKey)(next),
              ],
            },
          }
        : prev,
  });
export const getHasMore = ({ resultsKey, data }) =>
  pathOr(false, [resultsKey, "pageInfo", "hasNextPage"], data);

export const prepareSubscribeToMore = ({
  subscribeToMore,
  variables,
  resultsKey,
}) => (
  SUBSCRIPTION,
  { subscriptionResultKey, onNewResult, addNewToBottom = false }
) => {
  return subscribeToMore({
    document: SUBSCRIPTION,
    variables,
    updateQuery: (prev, { subscriptionData, ...rest }) => {
      if (!subscriptionData.data) return prev;
      const newResult = path(["data", subscriptionResultKey], subscriptionData);
      onNewResult && onNewResult(newResult);
      const totalCount = path([resultsKey, "totalCount"], prev) || 0;

      const newEdge = {
        node: newResult,
        __typename: newResult.__typename + "Edge",
      };

      const edges = addNewToBottom
        ? [...getEdges(resultsKey)(prev), newEdge]
        : [newEdge, ...getEdges(resultsKey)(prev)];

      return Object.assign({}, prev, {
        [resultsKey]: {
          ...path([resultsKey], prev),
          totalCount: totalCount + 1,
          edges,
        },
      });
    },
  });
};

const usePaginatedQuery = ({
  query,
  variables: defaultVariables,
  resultsKey,
  ...rest
}) => {
  const [value, setVariables] = useState();
  const variables = { ...defaultVariables, ...value };

  const { loading, fetchMore, subscribeToMore, data, networkStatus } = useQuery(
    query,
    {
      variables,
      notifyOnNetworkStatusChange: true,
      ...rest,
    }
  );

  return {
    loading,
    networkStatus,
    subscribeToMore: prepareSubscribeToMore({
      subscribeToMore,
      resultsKey,
      variables,
    }),
    fetchMore: prepareFetchMore({ fetchMore, resultsKey, variables, data }),
    hasMore: getHasMore({ resultsKey, data }),
    data,
    variables,
    setVariables,
  };
};

export default usePaginatedQuery;

import curry from "./curry";
import ifElseNull from "./ifElseNull";
import map from "./map";

// [ [ (*... -> Boolean), (*... -> *...) ] ] -> *... -> [*...]
const condRender = curry((conds, data) =>
  map(([pred, fn]) => ifElseNull(pred, fn)(data))(conds)
);

export default condRender;

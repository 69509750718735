import T from "../T";
import __ from "../__";
import always from "../always";
import cond from "../cond";
import curry from "../curry";
import gte from "../gte";
import isNil from "../isNil";
import max from "../max";
import min from "../min";

const getPrecision = cond([
  [isNil, always(0)],
  [gte(__, 0), min(292)],
  [T, max(-292)],
]);

const createRound = (methodName) => {
  const func = Math[methodName];
  return curry((_precision, number) => {
    const precision = getPrecision(_precision);
    if (precision) {
      // Shift with exponential notation to avoid floating-point issues.
      // See [MDN](https://mdn.io/round#Examples) for more details.
      let pair = `${number}e`.split("e");
      const value = func(`${pair[0]}e${+pair[1] + precision}`);

      pair = `${value}e`.split("e");
      return +`${pair[0]}e${+pair[1] - precision}`;
    }
    return func(number);
  });
};

export default createRound;

export const mediaPrefix = "/media";
export const staticPrefix = "/static";
const devBackendUrl = "http://localhost:8000";

const getBackendUrl = () => {
  if (process.env.NODE_ENV === "production") {
    // return `${window.location.origin}:44300`; // for bypassing nginx reverse proxy
    return window.location.origin; // leaves it up to nginx to pass graphql requests on to backend
  } else if (process.env.NODE_ENV === "development") {
    return devBackendUrl;
  }
};
const getStaticUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return staticPrefix;
  } else if (process.env.NODE_ENV === "development") {
    return devBackendUrl + staticPrefix;
  }
};

export const prependBackendUrl = (path) => `${getBackendUrl()}${path}`;
export const prependStaticUrl = (path) => `${getStaticUrl()}${path}`;

export default getBackendUrl;

import gql from "graphql-tag";

import { lensPath, view } from "../misc/fp";

const adfsAuthUrlLens = lensPath(["settings", "adfsAuthUrl"]);
export const getAdfsAuthUrl = view(adfsAuthUrlLens);

export default gql`
  query AppSettingsQuery {
    settings {
      adfsAuthUrl
    }
  }
`;

import cond from "./cond";
import isNil from "./isNil";
import always from "./always";
import not from "./not";
import isArray from "./isArray";
import T from "./T";
import identity from "./identity";
import compose from "./compose";

const alwaysArray = cond([
  [isNil, always([])],
  [compose(not, isArray), (val) => [val]],
  [T, identity],
]);

export default alwaysArray;

import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  align-content: ${(props) => props.alignContent};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  justify-items: ${(props) => props.justifyItems};
  flex: ${(props) => props.flex};
  flex-grow: ${(props) => props.grow};
  flex-basis: ${(props) => props.basis};
  flex-shrink: ${(props) => props.shrink};
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "initial")};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
`;

const Row = styled(Flex)`
  flex-direction: row;
`;

const Column = styled(Flex)`
  flex-direction: column;
`;

const Center = styled(Row)`
  flex: auto;
  justify-content: center;
  align-items: center;
`;

Flex.Row = Row;
Flex.Column = Column;
Flex.Center = Center;

export default Flex;

import compose from "./compose";
import last from "./last";
import replace from "./replace";
import toUpper from "./toUpper";

const PATTERN = /_./;
const lastToUpper = compose(toUpper, last);

// String -> String
const snakeToCamelCase = replace(PATTERN, lastToUpper);

export default snakeToCamelCase;

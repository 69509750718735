import { defineMessages } from "react-intl";

export default defineMessages({
  january: {
    id: "chart.january",
    description: "January",
    defaultMessage: "January",
  },
  february: {
    id: "chart.february",
    description: "February",
    defaultMessage: "February",
  },
  march: {
    id: "chart.march",
    description: "March",
    defaultMessage: "March",
  },
  april: {
    id: "chart.april",
    description: "April",
    defaultMessage: "April",
  },
  may: {
    id: "chart.may",
    description: "May",
    defaultMessage: "May",
  },
  june: {
    id: "chart.june",
    description: "June",
    defaultMessage: "June",
  },
  july: {
    id: "chart.july",
    description: "July",
    defaultMessage: "July",
  },
  august: {
    id: "chart.august",
    description: "August",
    defaultMessage: "August",
  },
  september: {
    id: "chart.september",
    description: "September",
    defaultMessage: "September",
  },
  october: {
    id: "chart.october",
    description: "October",
    defaultMessage: "October",
  },
  november: {
    id: "chart.november",
    description: "November",
    defaultMessage: "November",
  },
  december: {
    id: "chart.december",
    description: "December",
    defaultMessage: "December",
  },
  jan: {
    id: "chart.jan",
    description: "Jan",
    defaultMessage: "Jan",
  },
  feb: {
    id: "chart.feb",
    description: "Feb",
    defaultMessage: "Feb",
  },
  mar: {
    id: "chart.mar",
    description: "Mar",
    defaultMessage: "Mar",
  },
  apr: {
    id: "chart.apr",
    description: "Apr",
    defaultMessage: "Apr",
  },
  jun: {
    id: "chart.jun",
    description: "Jun",
    defaultMessage: "Jun",
  },
  jul: {
    id: "chart.jul",
    description: "Jul",
    defaultMessage: "Jul",
  },
  aug: {
    id: "chart.aug",
    description: "Aug",
    defaultMessage: "Aug",
  },
  thousandsSeparator: {
    id: "chart.thousandsSeparator",
    description: "thousandsseparator",
    defaultMessage: ",",
  },
  sep: {
    id: "chart.sep",
    description: "Sep",
    defaultMessage: "Sep",
  },
  oct: {
    id: "chart.oct",
    description: "Oct",
    defaultMessage: "Oct",
  },
  nov: {
    id: "chart.nov",
    description: "Nov",
    defaultMessage: "Nov",
  },
  dec: {
    id: "chart.dec",
    description: "Dec",
    defaultMessage: "Dec",
  },
  decimalPoint: {
    id: "chart.decimalPoint",
    description: "DecimalPoint",
    defaultMessage: ".",
  },
  configurations: {
    id: "chart.configurations",
    description: "Configurations",
    defaultMessage: "Configurations",
  },
  drillUpText: {
    id: "chart.drillUpText",
    description: "DrillUpText",
    defaultMessage: "Back to",
  },
  loading: {
    id: "chart.loading",
    description: "Loading",
    defaultMessage: "Loading",
  },
  noData: {
    id: "chart.noData",
    description: "no data available",
    defaultMessage: "No data available",
  },
  zoomOut: {
    id: "chart.zoomOut",
    description: "Zoom out",
    defaultMessage: "Zoom out",
  },
  resetZoom: {
    id: "chart.resetZoom",
    description: "Reset zoom",
    defaultMessage: "Reset zoom",
  },
  sunday: {
    id: "chart.sunday",
    description: "Sunday",
    defaultMessage: "Sunday",
  },
  monday: {
    id: "chart.monday",
    description: "Monday",
    defaultMessage: "Monday",
  },
  tuesday: {
    id: "chart.tuesday",
    description: "Tuesday",
    defaultMessage: "Tuesday",
  },
  wednesday: {
    id: "chart.wednesday",
    description: "Wednesday",
    defaultMessage: "Wednesday",
  },
  thursday: {
    id: "chart.thursday",
    description: "Thursday",
    defaultMessage: "Thursday",
  },
  friday: {
    id: "chart.friday",
    description: "Friday",
    defaultMessage: "Friday",
  },
  saturday: {
    id: "chart.saturday",
    description: "Saturday",
    defaultMessage: "Saturday",
  },
});

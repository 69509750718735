import gql from "graphql-tag";

const user = gql`
  fragment GlobalSiderUserFragment on User {
    id
    username
    shortName
    name: Description
    avatar: image
  }
`;

export { user };

import F from "./F";
import compose from "./compose";
import ifElse from "./ifElse";
import invoker from "./invoker";
import isNil from "./isNil";
import toDate from "./toDate";

// * -> Boolean
const isValidDate = compose(
  ifElse(isNil, F, compose(invoker(0, "isValid"), toDate))
);

export default isValidDate;

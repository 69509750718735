import React from "react";
import styled from "styled-components";
import { color } from "../../constants";
import { prependStaticUrl } from "../../services/backend";
import Flex from "./Flex";
import Margin from "./Margin";
import PageHeader from "./PageHeader";

const Image = styled.img`
  height: 80px;
  max-width: 400px;
  background-color: ${color.backgroundGray};
`;

const LogoPageHeader = () => (
  <PageHeader onBack={null} ghost style={{ overflow: "hidden" }}>
    <Flex justifyContent="center">
      <Margin top="16px" style={{ zIndex: 1 }}>
        <Image
          src={prependStaticUrl("/eyk/logo/EYK_black.svg")}
          alt="eyk logo"
        />
      </Margin>
    </Flex>
  </PageHeader>
);

export default LogoPageHeader;

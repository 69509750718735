import React from "react";
import PropTypes from "prop-types";

import { always } from "../misc/fp";

import Link from "./Link";

// String -> String
export const getAccountLink = always("/account/");

const AccountLink = ({ description }) => (
  <Link to={getAccountLink()}>{description}</Link>
);
AccountLink.propTypes = {
  description: PropTypes.string.isRequired,
};

AccountLink.getLink = getAccountLink;

export default AccountLink;

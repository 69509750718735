import gql from "graphql-tag";

import { lensPath, view } from "../misc/fp";

export const getCurrentLanguage = view(lensPath(["settings", "language"]));

export default gql`
  query CurrentLanguageQuery {
    settings {
      language
    }
  }
`;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Input } from "antd";
import { InputNumber } from "antd";
import Button from "./Button";
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const InlineInputInternal = styled(Input)`
  width: auto !important;
`;

// eslint-disable-next-line react/prefer-stateless-function
class InlineInput extends React.Component {
  // extends react component to be able to accept refs for internal input
  render() {
    const { onCancel, onDelete, onSubmit, ...restProps } = this.props;
    return (
      <Input.Group compact>
        <InlineInputInternal {...restProps} />
        {onDelete && (
          <Button icon={<DeleteOutlined />} danger onClick={onDelete} />
        )}
        <Button icon={<CloseOutlined />} onClick={onCancel} />
        <Button
          icon={<CheckOutlined />}
          type="primary"
          htmlType="submit"
          onClick={onSubmit}
        />
      </Input.Group>
    );
  }
}

InlineInput.defaultProps = {
  onDelete: undefined,
};

InlineInput.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

Input.Number = InputNumber;

Input.Inline = InlineInput;

const BetweenInputs = styled(Input)`
  pointer-events: none;
  border-left: 0 !important;
  width: 30px !important;
  background-color: white !important;
`;

Input.Between = ({ children, ...props }) => (
  <BetweenInputs disabled placeholder={children} {...props} />
);

Input.Between.propTypes = {
  children: PropTypes.node,
};

Input.Between.defaultProps = {
  children: "~",
};

Input.Label = styled.span`
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid #d9d9d9;
  padding: 4px 10px;
  white-space: nowrap;
  text-align: center;
  background-color: #f5f5f5;
  opacity: 1;
`;

Input.Button = styled(Button)`
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  border: 1px solid #d9d9d9;
  padding: 4px 10px;
  white-space: nowrap;
  text-align: center;
  background-color: #f5f5f5;
  opacity: 1;
`;

Input.InlineGroup = styled(Input.Group)`
  vertical-align: top;
  display: inline-flex !important;
  width: auto !important;
`;

export default Input;

import gql from "graphql-tag";
import { path } from "../misc/fp";

export const getVersion = path(["settings", "version"]);

export default gql`
  query AppQuery {
    settings {
      version
    }
  }
`;

import anyPass from "./anyPass";
import isGeneratorFunction from "./isGeneratorFunction";
import isAsyncFunction from "./isAsyncFunction";

/* eslint-enable max-len */
const isFunction = anyPass([
  (val) => Object.prototype.toString.call(val) === "[object Function]",
  isGeneratorFunction,
  isAsyncFunction,
]);

export default isFunction;

import converge from "./converge";
import keys from "./keys";
import values from "./values";
import zipWith from "./zipWith";

// {k: v} -> [{k: v}]
const objToArray = converge(
  zipWith((x, y) => ({ [x]: y })),
  [keys, values]
);

export default objToArray;

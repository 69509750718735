import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink, split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { createUploadLink } from "apollo-upload-client";
import { getMainDefinition } from "apollo-utilities";
import Cookies from "js-cookie";
import json from "../schema.json";
import { prependBackendUrl } from "./backend";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: json.data,
});

const makeApolloClient = ({ uri, subscriptions = false, devTools }) => {
  const fpLink = createUploadLink({
    uri,
    credentials: "include",
  });

  const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      return {
        credentials: "include",
        headers: {
          Accept: "application/json",
          "X-CSRFToken": Cookies.get("csrftoken"),
          ...headers,
        },
      };
    });
    return forward(operation);
  });

  const httpLink = middlewareLink.concat(fpLink);

  let link;
  if (subscriptions) {
    const wsLink = new WebSocketLink({
      uri: uri.replace("http", "ws"),
      options: {
        reconnect: true,
      },
    });

    link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );
  } else {
    link = httpLink;
  }

  return new ApolloClient({
    link,
    cache: new InMemoryCache({ fragmentMatcher }),
    connectToDevTools: devTools,
  });
};

export const publicClient = makeApolloClient({
  uri: prependBackendUrl("/public-graphql"),
  devTools: false,
});
export const client = makeApolloClient({
  uri: prependBackendUrl("/graphql"),
  subscriptions: true,
});
export default client;

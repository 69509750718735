import { useParams, useLocation, useHistory } from "react-router-dom";

const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  return {
    location,
    params,
    history,
  };
};
export default useRouter;

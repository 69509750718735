import both from "./both";
import complement from "./complement";
import equals from "./equals";
import flip from "./flip";
import isInteger from "./isInteger";
import modulo from "./modulo";
import pipe from "./pipe";

const isOdd = both(isInteger, pipe(flip(modulo)(2), complement(equals)(0)));

export default isOdd;

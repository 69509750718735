import assoc from "./assoc";
import curry from "./curry";
import keys from "./keys";
import reduce from "./reduce";

// usage renameKeys({ newKey: "oldKey" })({ oldKey: value}) -> { newKey: value }
const renameKeys = curry((keysMap, obj) =>
  reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
);

export default renameKeys;

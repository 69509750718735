import compose from "./compose";
import last from "./last";
import mapAccum from "./mapAccum";

const combinationsOfString = compose(
  last,
  mapAccum((a, b) => [a + b, a + b], "")
);

export default combinationsOfString;

import { Form } from "antd";

import styled from "styled-components";

const StyledForm = styled(Form)`
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;

export default StyledForm;

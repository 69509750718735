import React from "react";
import PropTypes from "prop-types";
import { Spin as AntdSpin } from "antd";
import styled from "styled-components";

import { isNil } from "../../misc/fp";

import Message from "./SpinMessage";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Spin = ({ children }) => (
  <Container>
    <AntdSpin size="large" />
    {!isNil(children) && <Message>{children}</Message>}
  </Container>
);

Spin.defaultProps = {
  children: undefined,
};

Spin.propTypes = {
  children: PropTypes.node,
};

export default Spin;

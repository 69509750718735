import React from "react";
import { FormattedMessage } from "react-intl";
import Button from "./Button";

export const CancelMessage = (
  <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
);

const CancelButton = (props) => <Button {...props} />;
CancelButton.propTypes = {
  ...Button.propTypes,
};
CancelButton.defaultProps = {
  children: CancelMessage,
};

export default CancelButton;

import { NON_FIELD_KEY } from "./internal/constants";
import { compose, propOr } from "../fp";
import getValidationMessages from "./getValidationMessages";

const getNonFieldValidationMessages = compose(
  propOr([], NON_FIELD_KEY),
  getValidationMessages
);

export default getNonFieldValidationMessages;

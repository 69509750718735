import gql from "graphql-tag";
import {
  always,
  compose,
  lensPath,
  listOf,
  traversed,
  tryCatch,
  view,
} from "../misc/fp";

export const resultsKey = "notifications";

const notificationsLens = lensPath([resultsKey]);
export const totalCount = view(
  compose(notificationsLens, lensPath(["totalCount"]))
);
export const nodes = tryCatch(
  listOf(
    compose(
      notificationsLens,
      lensPath(["edges"]),
      traversed,
      lensPath(["node"])
    )
  ),
  always([])
);

export default gql`
  query Notifications(
    $userId: ID!
    $isRead: Boolean
    $first: Int
    $after: String
  ) {
    notifications(
      userGid: $userId
      isRead: $isRead
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          isRead
          title
          body
          timestamp
        }
      }
    }
  }
`;

import { defineMessages } from "react-intl";
import {
  getNonFieldValidationMessages,
  getPermissionErrorMessage,
  getValidationMessages,
} from "../misc/api";
import { always, compose, ifElse, map, unnest, values } from "../misc/fp";
import notification from "./notification";

const getMessages = ({ onlyNonField }) =>
  ifElse(
    always(onlyNonField),
    getNonFieldValidationMessages,
    getValidationMessages
  );

const translations = defineMessages({
  permissionError: {
    id: "permissionError.title",
    defaultMessage: "Permission Error",
  },
  validationError: {
    id: "validationError.title",
    defaultMessage: "Validation Error",
  },
});

const sendValidationErrorMessages = (intl) =>
  compose(
    map((msg) =>
      notification.error({
        duration: 0,
        message: intl.formatMessage(translations.validationError),
        description: msg,
      })
    ),
    unnest,
    values,
    getMessages({ onlyNonField: true })
  );

const sendPermissionErrorMessages = (intl) =>
  compose(
    map((msg) =>
      notification.error({
        duration: 0,
        message: intl.formatMessage(translations.permissionError),
        description: msg,
      })
    ),
    getPermissionErrorMessage
  );

const sendErrorMessages = (intl) => (error) => {
  sendValidationErrorMessages(intl)(error);
  sendPermissionErrorMessages(intl)(error);
};

export default sendErrorMessages;

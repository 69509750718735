import React from "react";
import { defineMessages } from "react-intl";

import Modal from "../components/Modal";

import useTranslations from "./useTranslations";
import useState from "./useState";
import useCallback from "./useCallback";

const translations = defineMessages({
  modalTitle: {
    id: "welcomeModal.modal.title",
    defaultMessage: "Welcome to EYK",
  },
  okButtonText: {
    id: "welcomeModal.modal.okbutton.title",
    defaultMessage: "Ok, thanks, bye",
  },
});

// QUERY company membership and check if user just created a company or if he
// was invited to one

const useWelcomeModal = () => {
  const messages = useTranslations(translations);
  const [visible, setVisible] = useState(false);
  const onCancel = useCallback(() => setVisible(false), [setVisible]);

  const modal = (
    <Modal
      title={messages.modalTitle()}
      visible={visible}
      onCancel={onCancel}
      width="450px"
      okText={messages.okButtonText()}
      onOk={onCancel}
    >
      WELCOME TINY LITTLE USER!
    </Modal>
  );

  return {
    modal,
    setVisible,
  };
};

export default useWelcomeModal;

import {
  BellOutlined,
  CustomerServiceOutlined,
  GlobalOutlined,
  LogoutOutlined,
  MenuOutlined,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import React, { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import styled from "styled-components";
import useActiveFaces from "../../screens/Faces/useActiveFaces";

import { user as GLOBAL_SIDER_USER_FRAGMENT } from "../fragments/GlobalSiderFragments";

import useCurrentUser from "../hooks/useCurrentUser";
import useLocale from "../hooks/useLocale";
import useMemo from "../hooks/useMemo";
import useQuery from "../hooks/useQuery";
import useQueryStringValue from "../hooks/useQueryStringValue";
import { DRAWER_Z_INDEX } from "../misc/constants";
import { map, not, path } from "../misc/fp";
import APP_QUERY, { getVersion } from "../queries/AppQuery";
import AccountLink from "./AccountLink";
import Avatar from "./Avatar";
import Badge from "./Badge";
import Flex from "./Flex";
import { EYKFilled } from "./Icon";
import Layout from "./Layout";
import MegaMenu from "./MegaMenu";
import Menu from "./Menu";
import NotificationsDrawer, {
  useNumUnreadNotifications,
} from "./NotificationsDrawer";
import SearchDrawer from "./SearchDrawer";
import Space from "./Space";
import Tooltip from "./Tooltip";

const heightNumber = 80;
export const height = `${heightNumber}px`;
const widthNumber = 80;
export const width = `${widthNumber}px`;
const itemSize = "40px";
const bigItemSize = "56px";
const zIndex = DRAWER_Z_INDEX - 2; // below regular drawer & drawer mask

const NavItemContainer = styled.div`
  height: ${itemSize};
  line-height: ${itemSize};
  padding: 0 12px;
  margin: 4px 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavItem = ({ title, isVertical, children, ...rest }) => {
  return (
    <Tooltip title={title} placement={isVertical ? "right" : "top"}>
      <NavItemContainer {...rest}>{children}</NavItemContainer>
    </Tooltip>
  );
};

const BottomToolbar = styled.div`
  width: 100%;
  background: white;
  border-top: 1px solid #f0f0f0;
  display: flex;
  justify-content: center;
  padding: 8px 12px;
`;

const MobileNav = ({ children, ...rest }) => {
  return (
    <BottomToolbar {...rest}>
      <Space
        style={{
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        {children}
      </Space>
    </BottomToolbar>
  );
};

const MegaMenuComponent = ({ isVertical }) => {
  const { data } = useQuery(APP_QUERY);
  const version = useMemo(() => getVersion(data), [data]);
  const activeFaces = useActiveFaces();
  return (
    <MegaMenu
      isVertical={isVertical}
      title="EYK"
      subtitle={version}
      links={map(
        (face) => ({
          title: face.description,
          subTitle: face.subDescription,
          path: face.action.to,
          icon: face.icon,
          focus: face.focus,
        }),
        activeFaces
      )}
    >
      {({ visible, setVisible }) => (
        <NavItem onClick={setVisible}>
          <MenuOutlined />
        </NavItem>
      )}
    </MegaMenu>
  );
};

const AccountMenu = ({ doLogout, isVertical, ...rest }) => {
  const { currentUser } = useCurrentUser();
  return (
    <Menu
      selectable={false}
      mode={isVertical ? "vertical" : "horizontal"}
      style={{
        height: bigItemSize,
        border: "unset",
      }}
      {...rest}
    >
      <Menu.SubMenu
        key="userMenu"
        title={
          <Avatar style={isVertical ? { right: "50%" } : { marginTop: "8px" }}>
            {path(["initials"], currentUser)}
          </Avatar>
        }
        style={{ position: "relative" }}
      >
        <Menu.Item key="accountcenter">
          <AccountLink
            description={
              <React.Fragment>
                <UserOutlined />
                <FormattedMessage
                  style={{ marginLeft: "8px" }}
                  id="globalsider.account"
                  defaultMessage="My account"
                />
              </React.Fragment>
            }
          />
        </Menu.Item>
        <Menu.Item key="logout" onClick={doLogout}>
          <LogoutOutlined />
          <FormattedMessage
            style={{ marginLeft: "8px" }}
            id="globalsider.logout"
            defaultMessage="Logout"
          />
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};

const GlobalNav = ({
  doLogout,
  showSearchDrawer,
  setShowSearchDrawer,
  isSmallScreen,
  ...props
}) => {
  const { currentUserId } = useCurrentUser();
  const isVertical = not(isSmallScreen);
  const unreadNotificationsCount = useNumUnreadNotifications(currentUserId);
  const { currentLanguage, otherLanguage, toggleLocale } = useLocale();
  const rootRef = useRef(null);
  const [showNotificationsDrawer, setShowNotificationsDrawer] =
    useQueryStringValue("showNotifs", false);

  const homeLink = (
    <Link to={"/"} ref={rootRef} autoFocus>
      <NavItem style={{ height: bigItemSize }} isVertical={isVertical}>
        <EYKFilled style={{ fontSize: itemSize }} />
      </NavItem>
    </Link>
  );
  const searchTrigger = (
    <NavItem
      isVertical={isVertical}
      onClick={() => {
        setShowSearchDrawer((val) => !val);
        setShowNotificationsDrawer(false);
      }}
      title={
        <FormattedMessage
          id="globalsider.search.title"
          defaultMessage="Search [ / ]"
        />
      }
    >
      <SearchOutlined />
    </NavItem>
  );
  const notificationsTrigger = (
    <NavItem
      isVertical={isVertical}
      onClick={() => {
        setShowSearchDrawer(false);
        setShowNotificationsDrawer((val) => !val);
      }}
      title={
        <FormattedMessage
          id="globalsider.notifications.title"
          defaultMessage="Notifications"
        />
      }
    >
      <Badge showZero={false} count={unreadNotificationsCount} color="red">
        <BellOutlined style={{ lineHeight: "unset" }} />
      </Badge>
    </NavItem>
  );
  const liveChat = (
    <NavItem
      isVertical={isVertical}
      onClick={() => window.liveChatButton.onClick()}
      title={
        <FormattedMessage
          id="globalsider.getHelp"
          defaultMessage="Chat with a support agent"
        />
      }
    >
      <CustomerServiceOutlined />
    </NavItem>
  );
  const localeSwitch = (
    <NavItem
      isVertical={isVertical}
      onClick={toggleLocale}
      title={
        <FormattedMessage
          id="globalsider.changeLocale"
          defaultMessage="Breyta tungumáli úr {currentLanguage} í {otherLanguage}"
          values={{ currentLanguage, otherLanguage }}
        />
      }
    >
      <GlobalOutlined />
    </NavItem>
  );

  return [
    <SearchDrawer
      placement={isVertical ? "left" : "bottom"}
      width={isVertical && "500"}
      height={not(isVertical) && "60vh"}
      style={isVertical ? { left: width } : { bottom: height }}
      visible={showSearchDrawer}
      onClose={() => {
        setShowSearchDrawer(false);
        rootRef.current.focus();
      }}
      zIndex={zIndex}
    />,
    <NotificationsDrawer
      placement={isVertical ? "left" : "bottom"}
      width={isVertical && "500"}
      height={not(isVertical) && "60vh"}
      style={isVertical ? { left: width } : { bottom: height }}
      visible={showNotificationsDrawer}
      onClose={() => setShowNotificationsDrawer(false)}
      userId={currentUserId}
      zIndex={zIndex}
    />,
    isSmallScreen ? (
      <MobileNav style={{ zIndex: zIndex + 1 }} {...props}>
        {homeLink}
        <MegaMenuComponent isVertical={isVertical} />
        {searchTrigger}
        {notificationsTrigger}
        {localeSwitch}
        {liveChat}
        <AccountMenu doLogout={doLogout} isVertical={isVertical} />
      </MobileNav>
    ) : (
      <Layout.Sider
        collapsed
        collapsible
        trigger={null}
        theme="light"
        style={{
          zIndex: zIndex + 1,
          borderRight: "1px solid #f0f0f0",
        }}
        {...props}
      >
        <Flex.Column style={{ height: "100%" }}>
          <Flex.Column flex={1}>
            {homeLink}
            <MegaMenuComponent isVertical={isVertical} />
            {searchTrigger}
            {notificationsTrigger}
          </Flex.Column>
          <Flex.Column flex={1} justifyContent={"flex-end"}>
            {localeSwitch}
            {liveChat}
            <AccountMenu doLogout={doLogout} isVertical={isVertical} />
          </Flex.Column>
        </Flex.Column>
      </Layout.Sider>
    ),
  ];
};

GlobalNav.fragments = { user: GLOBAL_SIDER_USER_FRAGMENT };

GlobalNav.defaultProps = {
  isSmallScreen: false,
};

GlobalNav.propTypes = {
  showSearchDrawer: PropTypes.bool,
  setShowSearchDrawer: PropTypes.func,
  doLogout: PropTypes.func,
  isSmallScreen: PropTypes.bool,
};

export default GlobalNav;

import { any, has, compose, complement, ifElse, F } from "../fp";
import { VALIDATION_MESSAGES_KEY } from "./internal/constants";
import getErrors from "./getErrors";
import hasErrors from "./hasErrors";

const hasNoErrors = complement(hasErrors);
const anyValidationErrors = any(has(VALIDATION_MESSAGES_KEY));

// a -> Boolean
const hasValidationErrors = compose(
  ifElse(hasNoErrors, F, compose(anyValidationErrors, getErrors))
);

export default hasValidationErrors;

import isNotNull from "./isNotNull";

let GeneratorFunction = null;
try {
  GeneratorFunction = new Function("return function* () {}")().constructor; // eslint-disable-line no-new-func
} catch (e) {} // eslint-disable-line no-empty

/* eslint-enable max-len */
const isGeneratorFunction = (val) => {
  const toStringCheck =
    Object.prototype.toString.call(val) === "[object GeneratorFunction]";
  const legacyConstructorCheck =
    isNotNull(GeneratorFunction) && val instanceof GeneratorFunction;

  return toStringCheck || legacyConstructorCheck;
};

export default isGeneratorFunction;

import { VALIDATION_MESSAGES_KEY } from "./internal/constants";
import errorsLens from "./errorsLens";
import {
  always,
  compose,
  concat,
  lensPath,
  listOf,
  mergeWith,
  reduce,
  traversed,
  tryCatch,
} from "../fp";

export const validationMessagesLens = compose(
  errorsLens,
  traversed,
  lensPath([VALIDATION_MESSAGES_KEY])
);

// [a] -> {k: v}
const mergeValidationMessages = reduce(
  (acc, value) => mergeWith(concat)(acc)(value),
  {}
);

// {k: v} -> {a: [String]}
const getValidationMessages = compose(listOf(validationMessagesLens));

// {k: v} -> {k: v}
const getValidationMessagesOrNothing = compose(
  mergeValidationMessages,
  tryCatch(getValidationMessages, always([]))
);

export default getValidationMessagesOrNothing;

import { useMutation as useApolloMutation } from "react-apollo";
import sendErrorMessages from "../components/sendErrorMessages";
import { isNotNil } from "../misc/fp";
import useIntl from "./useIntl";

const useMutation = (mutation, options = {}, ...props) => {
  const intl = useIntl();
  const onError = (error) => {
    if (isNotNil(options.onError)) {
      options.onError(error);
    }
    // if the error is not related to the backend sending error messages might
    // fail. If so throw the original error
    try {
      sendErrorMessages(intl)(error);
    } catch (err) {
      throw error;
    }
  };
  return useApolloMutation(
    mutation,
    {
      ...options,
      onError,
    },
    ...props
  );
};

export default useMutation;

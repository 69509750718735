import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const enableSentry = () =>
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  });

const registerUserWithSentry = ({ id, email }) => {
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: email,
      email,
      username: email,
    });
  });
};

export const registerUserWithErrorTrackers = ({ id, email, name }) => {
  registerUserWithSentry({ id, email });
};

export const enableErrorTrackers = ({ version }) => {
  enableSentry();
};

export const reportError = ({ error, info }) => {
  Sentry.withScope((scope) => {
    Object.keys(info).forEach((key) => {
      scope.setExtra(key, info[key]);
    });
    Sentry.captureException(error);
  });
};

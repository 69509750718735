import {Button as AntButton, Space} from "antd";
import PropTypes from "prop-types";
import React from "react";
import { cond, propEq, render, T } from "../misc/fp";
import Dropdown from "./Dropdown";
import Padding from "./Padding";

const AnchorButton = ({ icon, children, type, ...rest }) => (
  <a {...rest}>
    {icon && icon}
    <Padding.Horizontal px={10} />
    {children}
  </a>
);

const DropdownButton = ({ dropdownIcon, icon, children, ...props }) => {
  return (
    <Dropdown.Button {...props}>
      {icon}
      {children}
    </Dropdown.Button>
  );
};

const isAnchor = propEq("type", "anchor");
const isDropdown = propEq("type", "dropdown");
const Button = cond([
  [isAnchor, render(AnchorButton)],
  [isDropdown, render(DropdownButton)],
  [T, render(AntButton)],
]);

const ToggleButton = ({ on, toggle, onTitle, offTitle, children, ...rest }) => (
  <Button onClick={toggle} {...rest}>
    {on ? onTitle : offTitle}
    {children}
  </Button>
);

ToggleButton.propTypes = {
  on: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  children: PropTypes.node,
  onTitle: PropTypes.string,
  offTitle: PropTypes.string,
};

ToggleButton.defaultProps = {
  children: undefined,
  onTitle: undefined,
  offTitle: undefined,
};

Button.Group = Space;
Button.Dropdown = AntButton.Dropdown;
Button.Toggle = ToggleButton;

export default Button;

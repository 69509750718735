export const red = [
  "#ffccc7",
  "#ffa39e",
  "#ff7875",
  "#ff4d4f",
  "#f5222d",
  "#cf1322",
  "#a8071a",
  "#820014",
  "#5c0011",
];
export const orange = [
  "#612500",
  "#873800",
  "#ad4e00",
  "#d46b08",
  "#fa8c16",
  "#ffa940",
  "#ffc069",
  "#ffd591",
  "#ffe7ba",
];
export const yellow = [
  "#614700",
  "#876800",
  "#ad8b00",
  "#d4b106",
  "#fadb14",
  "#ffec3d",
  "#fff566",
  "#fffb8f",
  "#ffffb8",
];
export const gold = [
  "#613400",
  "#874d00",
  "#ad6800",
  "#d48806",
  "#faad14",
  "#ffc53d",
  "#ffd666",
  "#ffe58f",
  "#fff1b8",
];
export const lime = [
  "#254000",
  "#3f6600",
  "#5b8c00",
  "#7cb305",
  "#a0d911",
  "#bae637",
  "#d3f261",
  "#eaff8f",
  "#f4ffb8",
];
export const green = [
  "#092b00",
  "#135200",
  "#237804",
  "#389e0d",
  "#52c41a",
  "#73d13d",
  "#95de64",
  "#b7eb8f",
  "#d9f7be",
];
export const cyan = [
  "#002329",
  "#00474f",
  "#006d75",
  "#08979c",
  "#13c2c2",
  "#36cfc9",
  "#5cdbd3",
  "#87e8de",
  "#b5f5ec",
];
export const blue = [
  "#002766",
  "#003a8c",
  "#0050b3",
  "#096dd9",
  "#1890ff",
  "#40a9ff",
  "#69c0ff",
  "#91d5ff",
  "#bae7ff",
];
export const purple = [
  "#120338",
  "#22075e",
  "#391085",
  "#531dab",
  "#722ed1",
  "#9254de",
  "#b37feb",
  "#d3adf7",
  "#efdbff",
];
export const magenta = [
  "#520339",
  "#780650",
  "#9e1068",
  "#c41d7f",
  "#eb2f96",
  "#f759ab",
  "#ff85c0",
  "#ffadd2",
  "#ffd6e7",
];
export const grey = [
  "#000000",
  "#262626",
  "#595959",
  "#8c8c8c",
  "#bfbfbf",
  "#d9d9d9",
  "#e8e8e8",
  "#f5f5f5",
  "#fafafa",
  "#ffffff",
];

import gql from "graphql-tag";

export default gql`
  query WellTestBreadcrumbQuery($id: ID!) {
    item: measurementSerie(id: $id) {
      ... on BoreholeMeasurementSeries {
        id
        description: Description
      }
      ... on BoreholeMeasurementBaseCurve {
        id
        description: Description
      }
    }
  }
`;

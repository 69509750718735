import { publicClient } from "../../services/client";
import useQuery from "./useQuery";
import CURRENT_USER_QUERY, {
  getCurrentUser,
  getDatasetPermissions,
  getCurrentUserId,
} from "../queries/CurrentUserQuery";

const useCurrentUser = () => {
  const { data, loading } = useQuery(CURRENT_USER_QUERY, {
    client: publicClient,
  });
  const currentUser = getCurrentUser(data);
  const refetch = [{ query: CURRENT_USER_QUERY }];
  const datasetPermissions = getDatasetPermissions(data);
  const currentUserId = getCurrentUserId(data);

  return {
    loading,
    currentUser,
    currentUserId,
    refetch,
    datasetPermissions,
  };
};

export default useCurrentUser;

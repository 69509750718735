import React from "react";
import { Spin as AntdSpin } from "antd";
import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1005;

  @media (max-width: 768px) {
    top: 93px;
  }
`;

const Spin = ({ ...props }) => (
  <Container {...props}>
    <AntdSpin />
  </Container>
);

export default Spin;

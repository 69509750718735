import React from "react";
import { path } from "../misc/fp";
import Icon from "@ant-design/icons";

const FlaskOutlinedSVG = () => (
  <svg
    aria-hidden="true"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <path
      fill="currentColor"
      d="M437.2 403.5L320 215V64h8c13.3 0 24-10.7 24-24V24c0-13.3-10.7-24-24-24H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h8v151L10.8 403.5C-18.5 450.6 15.3 512 70.9 512h306.2c55.7 0 89.4-61.5 60.1-108.5zM137.9 320l48.2-77.6c3.7-5.2 5.8-11.6 5.8-18.4V64h64v160c0 6.9 2.2 13.2 5.8 18.4l48.2 77.6h-172z"
    />
  </svg>
);
export const FlaskOutlined = (props) => (
  <Icon component={FlaskOutlinedSVG} {...props} />
);

const NotEnvironmentOutlinedSVG = () => (
  <svg
    aria-hidden="true"
    data-icon="location"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <path
      transform="translate(-64, 0)"
      fill="currentColor"
      d="M637 485.25L23 1.75A7.995 7.995 0 0 0 11.76 3l-10 12.51a7.995 7.995 0 0 0 1.25 11.24l614 483.49a7.995 7.995 0 0 0 11.24-1.25l10-12.51c2.76-3.44 2.2-8.47-1.25-11.23zM320 32c88.22 0 160 71.78 160 160 0 34.67-4.71 54.09-34.81 101.47l25.32 19.94C503.87 261.25 512 235.24 512 192 512 86.4 425.6 0 320 0c-61.72 0-116.73 29.65-151.92 75.27l25.36 19.97C222.71 57.06 268.29 32 320 32zm73.95 221.12C407.56 236.58 416 215.54 416 192c0-53.76-42.24-96-96-96-31.89 0-59.46 15.08-76.83 38.39l25.11 19.77C279.81 138.27 298.43 128 320 128c35.89 0 64 28.11 64 64 0 15.99-5.8 30.24-15.16 41.35l25.11 19.77zm-21.47 146.19c-15.52 22.01-33.09 46.91-52.47 74.69-19.67-28.17-37.09-52.85-52.49-74.69C170.64 261.97 160 245.11 160 192c0-.28.08-.54.08-.82l-30.3-23.86c-1.05 8.1-1.78 16.3-1.78 24.68 0 76.8 25.6 99.2 172.8 310.4 4.8 6.4 12 9.6 19.2 9.6s14.4-3.2 19.2-9.6c30.77-44.15 55.96-79.69 76.98-109.56L391 373.01c-5.81 8.28-11.93 16.96-18.52 26.3z"
    />
  </svg>
);
export const NotEnvironmentOutlined = (props) => (
  <Icon component={NotEnvironmentOutlinedSVG} {...props} />
);

const EnvironmentOutlinedSVG = () => (
  <svg
    aria-hidden="true"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <path
      transform="translate(64, 0)"
      fill="currentColor"
      d="M192 96c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64zm0-256C85.961 0 0 85.961 0 192c0 77.413 26.97 99.031 172.268 309.67 9.534 13.772 29.929 13.774 39.465 0C357.03 291.031 384 269.413 384 192 384 85.961 298.039 0 192 0zm0 473.931C52.705 272.488 32 256.494 32 192c0-42.738 16.643-82.917 46.863-113.137S149.262 32 192 32s82.917 16.643 113.137 46.863S352 149.262 352 192c0 64.49-20.692 80.47-160 281.931z"
    />
  </svg>
);
export const EnvironmentOutlined = (props) => (
  <Icon component={EnvironmentOutlinedSVG} {...props} />
);

const HugfimiOutlinedSVG = () => (
  <svg fill="currentColor" viewBox="0 0 20000 20000" width="1em" height="1em">
    <g transform="translate(500, 500)">
      <rect x="-1" y="-1" fill="none" />
    </g>

    <g transform="translate(500, 500)">
      <g id="svg_1">
        <path
          d="m420,9500l0,-9080l9080,0l9080,0l0,9080l0,9080l-9080,0l-9080,0l0,-9080zm17260,0l0,-8180l-8180,0l-8180,0l0,8180l0,8180l8180,0l8180,0l0,-8180z"
          id="svg_2"
        />
        <path
          d="m7520,14310l0,-760l2840,0l2840,0l0,760l0,760l-2840,0l-2840,0l0,-760z"
          id="svg_3"
        />
        <path
          d="m3290,10940l0,-760l4455,0l4455,0l0,760l0,760l-4455,0l-4455,0l0,-760z"
          id="svg_4"
        />
        <path
          d="m5110,7570l0,-760l5075,0l5075,0l0,760l0,760l-5075,0l-5075,0l0,-760z"
          id="svg_5"
        />
        <path
          d="m3290,4195l0,-755l2230,0l2230,0l0,755l0,755l-2230,0l-2230,0l0,-755z"
          id="svg_6"
        />
      </g>
    </g>
  </svg>
);
export const HugfimiOutlined = (props) => (
  <Icon component={HugfimiOutlinedSVG} {...props} />
);

const LandsnetOutlinedSVG = () => (
  <svg x="0px" y="0px" width="1em" height="1em" viewBox="0 0 41.51 51.2">
    <g id="Layer_2" data-name="Layer 2">
      <g id="LOGOS">
        <g id="POLYLINE">
          <polyline
            className="cls-1"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0"
            points="0 51.2 3.87 51.2 3.87 24.2 41.51 51.14 41.51 46.11 0 15.95 0 51.2"
          />
        </g>
        <g id="HATCH">
          <polygon
            className="cls-2"
            fill="#36668f"
            points="0 51.2 3.87 51.2 3.87 24.2 41.51 51.14 41.51 46.11 0 15.95 0 51.2 0 51.2"
          />
        </g>
        <g id="POLYLINE-2" data-name="POLYLINE">
          <polyline
            className="cls-1"
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="0"
            points="41.51 0 37.63 0 37.63 27 0 0.05 0 5.08 41.51 35.25 41.51 0"
          />
        </g>
        <g id="HATCH-2" data-name="HATCH">
          <polygon
            className="cls-3"
            fill="#9c1c20"
            points="41.51 0 37.63 0 37.63 27 0 0.05 0 5.08 41.51 35.25 41.51 0 41.51 0"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const LandsnetOutlined = (props) => (
  <Icon component={LandsnetOutlinedSVG} {...props} />
);

const MannvitOutlinedSVG = () => (
  <svg x="0px" y="0px" width="1em" height="1em" viewBox="0 0 41.4 41.4">
    <g>
      <g>
        <g>
          <path fill="#939598" d="M18,13.1l6.4,11.3H11.6L18,13.1z" />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#FFD200"
            d="M28.9,6.6l6.7,11.7l-9.5,5.8l-7-12.4L28.9,6.6z"
          />
        </g>
        w
      </g>
      <g>
        <g>
          <path fill="#58595B" d="M31.5,7.8l4.4,2.6v5.1L31.5,7.8z" />
        </g>
      </g>
      <g>
        <g>
          <path fill="#58595B" d="M22.3,38.9l-4.4,2.5l-4.4-2.5L22.3,38.9z" />
        </g>
      </g>
      <g>
        <g>
          <path fill="#C7C8CA" d="M4.4,7.7L0,15.5v-5.1L4.4,7.7z" />
        </g>
      </g>
      <g>
        <g>
          <path fill="#FFD200" d="M25,26.1v11.2l-14,0V26.1H25z" />
        </g>
      </g>
      <g>
        <g>
          <path fill="#FFD200" d="M7,6.5l9.8,5.3L9.9,24.1l-9.6-5.8L7,6.5z" />
        </g>
      </g>
      <g>
        <g>
          <path fill="#58595B" d="M35.9,20v11.1l-9.3,5.5v-11L35.9,20z" />
        </g>
      </g>
      <g>
        <g>
          <path fill="#C7C8CA" d="M17.9,0l9.6,5.5l-9.6,5L8.4,5.3L17.9,0z" />
        </g>
      </g>
      <g>
        <g>
          <path fill="#C7C8CA" d="M0,20.1l9.4,5.6v10.9L0,30.9V20.1z" />
        </g>
      </g>
    </g>
  </svg>
);
export const MannvitOutlined = (props) => (
  <Icon component={MannvitOutlinedSVG} {...props} />
);

const EnvironmentFilledSVG = () => (
  <svg
    aria-hidden="true"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="1em"
    height="1em"
    fill="currentColor"
  >
    <path
      transform="translate(64, 0)"
      fill="currentColor"
      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
      className=""
    />
  </svg>
);
export const EnvironmentFilled = (props) => (
  <Icon component={EnvironmentFilledSVG} {...props} />
);

const MapOutlinedSVG = () => (
  <svg viewBox="0 0 576 512" width="1em" height="1em" fill="currentColor">
    <path d="M560.02 32c-1.96 0-3.98.37-5.96 1.16L384.01 96H384L212 35.28A64.252 64.252 0 0 0 191.76 32c-6.69 0-13.37 1.05-19.81 3.14L20.12 87.95A32.006 32.006 0 0 0 0 117.66v346.32C0 473.17 7.53 480 15.99 480c1.96 0 3.97-.37 5.96-1.16L192 416l172 60.71a63.98 63.98 0 0 0 40.05.15l151.83-52.81A31.996 31.996 0 0 0 576 394.34V48.02c0-9.19-7.53-16.02-15.98-16.02zM30.63 118.18L176 67.61V387.8L31.91 441.05l-1.28-322.87zM208 387.71V67.8l160 56.48v319.91l-160-56.48zm192 56.68V124.2l144.09-53.26 1.28 322.87L400 444.39z" />
  </svg>
);
export const MapOutlined = (props) => (
  <Icon component={MapOutlinedSVG} {...props} />
);

const EYKFilledSVG = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 450 450"
  >
    <g>
      <rect style={{ fill: "#EF7747" }} width="450" height="450" rx="98" />
      <g>
        <path
          style={{ fill: "#FFF" }}
          d="M269,200.48H160.33a49.05,49.05,0,0,0-49.06,49.05H220A49,49,0,0,0,269,200.48Z"
        />
        <polygon
          style={{ fill: "#FFF" }}
          points="295 112.51 154.27 112.51 154.27 161.65 321.34 161.65 295 112.51"
        />
        <polygon
          style={{ fill: "#FFF" }}
          points="295 337.5 154.27 337.5 154.27 288.36 321.34 288.36 295 337.5"
        />
      </g>
    </g>
  </svg>
);
export const EYKFilled = (props) => (
  <Icon component={EYKFilledSVG} {...props} />
);

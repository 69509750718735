import { compose, startsWith, tail, trim, when } from "../misc/fp";
import useEffect from "./useEffect";

const prune = compose(when(startsWith("/"), tail), trim);
const useDocumentTitle = (title) => {
  useEffect(() => {
    window.document.title = title ? prune(title) : " / ";
  }, [title]);
};

export default useDocumentTitle;

import gql from "graphql-tag";

import { lensPath, view } from "../misc/fp";

export const getLanguage = view(lensPath(["setLanguage", "language"]));

export default gql`
  mutation SetLanguage($language: String!) {
    setLanguage(input: { language: $language }) {
      language
    }
  }
`;

import gql from "graphql-tag";

export default gql`
  query AssetBreadcrumbQuery($id: ID!) {
    item: asset(id: $id) {
      id
      description: Description
    }
  }
`;

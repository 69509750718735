import isNil from "./isNil";

// borrowed from https://medium.com/@mhagemann/the-ultimate-way-to-slugify-a-url-string-in-javascript-b8e4a0d849e1
// String | nil => String
function slugify(string) {
  /* eslint-disable */
  const mapping = {
    ",": "-",
    "/": "-",
    ":": "-",
    ";": "-",
    _: "-",
    "·": "-",
    ß: "s",
    à: "a",
    á: "a",
    â: "a",
    ã: "a",
    ä: "a",
    å: "a",
    æ: "ae",
    ç: "c",
    ð: "d",
    è: "e",
    é: "e",
    ê: "e",
    ë: "e",
    ì: "i",
    í: "i",
    î: "i",
    ï: "i",
    ñ: "n",
    ò: "o",
    ó: "o",
    ô: "o",
    ö: "o",
    ù: "u",
    ú: "u",
    û: "u",
    ü: "u",
    ÿ: "y",
    ă: "a",
    ń: "n",
    œ: "o",
    ŕ: "r",
    ś: "s",
    ź: "z",
    ǘ: "u",
    ǵ: "g",
    ǹ: "n",
    ș: "s",
    ț: "t",
    ḧ: "h",
    ḿ: "m",
    ṕ: "p",
    ẃ: "w",
    ẍ: "x",
    þ: "th",
  };
  /* eslint-enable */

  const p = new RegExp(Object.keys(mapping).join("|"), "g");
  return isNil(string)
    ? ""
    : string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => mapping[c]) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with ‘and’
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - fom start of text
        .replace(/-+$/, ""); // Trim - from end of text
}

export default slugify;

import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/Layout";

class AppTemplate extends React.PureComponent {
  render() {
    const { sider, header, content, footer, style } = this.props;
    return (
      <Layout style={style.main}>
        {sider && sider}
        <Layout style={style.content}>
          {header && header}
          {content}
          {footer && footer}
        </Layout>
      </Layout>
    );
  }
}
AppTemplate.defaultProps = {
  sider: null,
  header: null,
  footer: null,
  style: {
    main: null,
    content: null,
  },
};
AppTemplate.propTypes = {
  sider: PropTypes.node,
  header: PropTypes.node,
  content: PropTypes.node.isRequired,
  footer: PropTypes.node,
  style: PropTypes.shape({
    main: PropTypes.object,
    content: PropTypes.object,
  }),
};

export default AppTemplate;

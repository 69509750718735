import React from "react";
import { Mutation } from "react-apollo";
import { HotKeys } from "react-hotkeys";
import { Switch } from "react-router-dom";
import { registerUserWithErrorTrackers } from "../../errorReporting";
import LoadingPage from "../../screens/LoadingPage";
import LoginPage from "../../screens/LoginPage";
import { publicClient } from "../../services/client";
import Breadcrumbs from "../../shared/components/Breadcrumbs";
import GlobalNav from "../../shared/components/GlobalNav";
import message from "../../shared/components/message";
import Route from "../../shared/components/Route";
import AddToAvailablePathsContext from "../../shared/contexts/AddToAvailablePathsContext";
import useBreakpoint from "../../shared/hooks/useBreakpoint";
import useCallback from "../../shared/hooks/useCallback";
import useCurrentUser from "../../shared/hooks/useCurrentUser";
import useMemo from "../../shared/hooks/useMemo";
import useQueryStringValue from "../../shared/hooks/useQueryStringValue";
import useState from "../../shared/hooks/useState";
import { isNil, not } from "../../shared/misc/fp";
import LOGOUT_MUTATION, {
  getMessage,
} from "../../shared/mutations/LogoutMutation";
import AppTemplate from "../../shared/templates/AppTemplate";
import paths, { routes } from "./paths";

const appStyle = {
  main: {
    height: "100%",
  },
  content: {},
};

const Nav = ({
  showSearchDrawer,
  setShowSearchDrawer,
  refetchCurrentUser,
  ...rest
}) => {
  return (
    <Mutation
      mutation={LOGOUT_MUTATION}
      onCompleted={(data) => message.success(getMessage(data))}
      client={publicClient}
    >
      {(logoutMutation) => (
        <GlobalNav
          showSearchDrawer={showSearchDrawer}
          setShowSearchDrawer={setShowSearchDrawer}
          doLogout={() =>
            logoutMutation({
              refetchQueries: refetchCurrentUser,
            })
          }
          {...rest}
        />
      )}
    </Mutation>
  );
};

const EYK = () => {
  const screens = useBreakpoint();
  const isSmallScreen = useMemo(() => not(screens.md), [screens]);

  const { currentUser, refetch: refetchCurrentUser } = useCurrentUser();
  const [availablePaths, setAvailablePaths] = useState(paths);
  const addToAvailablePaths = useCallback(
    (paths) => {
      setAvailablePaths([...availablePaths, ...paths]);
    },
    [availablePaths]
  );
  const [showSearchDrawer, setShowSearchDrawer] = useQueryStringValue(
    "showSearch",
    false
  );
  const doShowSearchDrawer = useCallback(() => {
    setShowSearchDrawer(true);
  }, [setShowSearchDrawer]);
  const keyMap = {
    SHOW_SEARCH: "/",
  };
  const handlers = {
    SHOW_SEARCH: doShowSearchDrawer,
  };

  if (isNil(currentUser)) {
    // USER HAS TO LOGIN
    return (
      <AppTemplate
        style={appStyle}
        content={<LoginPage refetchQueries={refetchCurrentUser} />}
      />
    );
  }

  registerUserWithErrorTrackers({
    id: currentUser.id,
    email: currentUser.username,
    name: currentUser.name,
  });

  return (
    <HotKeys
      keyMap={keyMap}
      handlers={handlers}
      style={{ height: "100%", width: "100%" }}
    >
      <AddToAvailablePathsContext.Provider value={addToAvailablePaths}>
        <AppTemplate
          style={appStyle}
          header={<Breadcrumbs paths={availablePaths} />}
          sider={
            isSmallScreen ? null : (
              <Nav
                showSearchDrawer={showSearchDrawer}
                setShowSearchDrawer={setShowSearchDrawer}
                refetchCurrentUser={refetchCurrentUser}
              />
            )
          }
          content={
            <React.Suspense fallback={<LoadingPage />}>
              <Switch>
                {routes.map((route) => (
                  <Route key={route.path} strict {...route} />
                ))}
              </Switch>
            </React.Suspense>
          }
          footer={
            isSmallScreen && (
              <Nav
                showSearchDrawer={showSearchDrawer}
                setShowSearchDrawer={setShowSearchDrawer}
                refetchCurrentUser={refetchCurrentUser}
                isSmallScreen
              />
            )
          }
        />
      </AddToAvailablePathsContext.Provider>
    </HotKeys>
  );
};

EYK.propTypes = {};

export default EYK;

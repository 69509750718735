import { LoadingOutlined } from "@ant-design/icons";
import Markdown from "./Markdown";
import useMutation from "../hooks/useMutation";
import useState from "../hooks/useState";
import SET_NOTIFICATION_READ_MUTATION from "../mutations/SetNotificationReadMutation";
import PaginatedList from "./PaginatedList";
import Button from "./Button";
import { FormattedMessage } from "react-intl";
import { compose, fromNow, toDate } from "../misc/fp";
import React from "react";
import styled from "styled-components";
import Badge from "./Badge";
import Avatar from "./Avatar";
import Empty from "./Empty";
import horn from "../graphics/horn.svg";
import { ConfigProvider } from "antd";

const TransparentBadge = styled(Badge)`
  background-color: unset !important;
`;

const TransparentAvatar = styled(Avatar)`
  background-color: unset !important;
  color: unset !important;
`;

const customizeRenderEmpty = (text) => () => (
  <div style={{ textAlign: "center" }}>
    <Empty image={horn} description={text} />
  </div>
);

const NotificationStatus = ({ isRead, loading }) => {
  if (loading) {
    return <LoadingOutlined />;
  }
  return isRead ? (
    <TransparentBadge status="default" />
  ) : (
    <TransparentBadge status="error" />
  );
};

const NotificationListItem = ({
  refetch,
  loading,
  id,
  isRead,
  body,
  title,
  timestamp,
  activeNotification,
  setActiveNotification,
  ...rest
}) => {
  const [setRead, { loading: myLoading }] = useMutation(
    SET_NOTIFICATION_READ_MUTATION,
    {
      refetchQueries: refetch,
      name: "markNotificationRead",
    }
  );

  const isActive = activeNotification === id;

  return (
    <PaginatedList.Item
      clickable
      showTitleActionsOnHover
      avatar={
        <TransparentAvatar>
          <NotificationStatus isRead={isRead} loading={loading || myLoading} />
        </TransparentAvatar>
      }
      titleActions={[
        <Button
          type="link"
          onClick={(e) => {
            setRead({ variables: { id, isRead: !isRead } });
            e.stopPropagation();
          }}
        >
          {isRead ? (
            <FormattedMessage
              id="notifiactionListItem.setUnread"
              defaultMessage="Mark unread"
            />
          ) : (
            <FormattedMessage
              id="notifiactionListItem.setRead"
              defaultMessage="Mark read"
            />
          )}
        </Button>,
        <Button
          type="link"
          onClick={(e) => {
            isActive ? setActiveNotification() : setActiveNotification(id);
            e.stopPropagation();
          }}
        >
          {isActive ? (
            <FormattedMessage
              id="notifiactionListItem.close"
              defaultMessage="Close"
            />
          ) : (
            <FormattedMessage
              id="notifiactionListItem.open"
              defaultMessage="Open"
            />
          )}
        </Button>,
      ]}
      title={title}
      description={compose(fromNow, toDate)(timestamp)}
      onClick={() =>
        isActive ? setActiveNotification() : setActiveNotification(id)
      }
      {...rest}
    >
      {isActive && <Markdown source={body} />}
    </PaginatedList.Item>
  );
};

const NotificationsList = ({
  refetch,
  loading,
  dataSource,
  fetchMore,
  hasMore,
  networkStatus,
  itemsLoading,
  pageSize,
  locale,
  ...rest
}) => {
  const [activeNotification, setActiveNotification] = useState();
  return (
    <ConfigProvider
      renderEmpty={customizeRenderEmpty(locale && locale.emptyText)}
    >
      <PaginatedList
        itemLayout="vertical"
        dataSource={dataSource}
        fetchMore={fetchMore}
        hasMore={hasMore}
        networkStatus={networkStatus}
        loading={loading}
        pageSize={pageSize}
        {...rest}
      >
        {({ id, isRead, title, timestamp, body }) => (
          <NotificationListItem
            id={id}
            isRead={isRead}
            title={title}
            body={body}
            timestamp={timestamp}
            loading={itemsLoading}
            refetch={refetch}
            activeNotification={activeNotification}
            setActiveNotification={setActiveNotification}
          />
        )}
      </PaginatedList>
    </ConfigProvider>
  );
};

export default NotificationsList;

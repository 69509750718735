import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Message = styled.h3`
  margin-top: 24px;
  margin-bottom: 8px;
  color: #108ee9;
`;

const SpinMessage = ({ children }) => <Message>{children}</Message>;

SpinMessage.propTypes = {
  children: PropTypes.string.isRequired,
};

export default SpinMessage;

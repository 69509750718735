import { stringify } from "query-string";

import both from "./both";
import complement from "./complement";
import compose from "./compose";
import isArray from "./isArray";
import isObj from "./isObj";
import map from "./map";
import when from "./when";

// This function respects default query string formatting for first level objects.
// To support nested lists and objects any nested values are formatted as json strings

const stringifyObjects = map(
  when(both(isObj, complement(isArray)), window.JSON.stringify)
);
const stringifyObjectsInArrays = map(
  when(isArray, map(when(isObj, window.JSON.stringify)))
);

// {k: v} -> String
const qsStringify = compose(
  stringify,
  stringifyObjects,
  stringifyObjectsInArrays
);

export default qsStringify;

import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import styled from "styled-components";

import { publicClient } from "../services/client";
import { getAccountLink } from "../shared/components/AccountLink";
import Button from "../shared/components/Button";
import Card from "../shared/components/Card";
import Flex from "../shared/components/Flex";
import Form from "../shared/components/Form";
import Input from "../shared/components/Input";
import Link from "../shared/components/Link";
import LogoPageHeader from "../shared/components/LogoPageHeader";
import Margin from "../shared/components/Margin";
import message from "../shared/components/message";
import Padding from "../shared/components/Padding";
import Typography from "../shared/components/Typography";

import useDocumentTitle from "../shared/hooks/useDocumentTitle";
import useForm from "../shared/hooks/useForm";

import useMutation from "../shared/hooks/useMutation";
import useRouter from "../shared/hooks/useRouter";
import useTranslations from "../shared/hooks/useTranslations";
import REGISTER_MUTATION, {
  getUser,
} from "../shared/mutations/RegisterMutation";
import PageTemplate from "../shared/templates/PageTemplate";

export const path = "/register/";

const CenteredText = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const RegisterForm = ({ refetchQueries }) => {
  const messages = useTranslations(
    defineMessages({
      registerUnsuccessful: {
        id: "registerPage.registerUnsuccessfulErrorMessage",
        defaultMessage: "Register unsuccessful",
      },
      firstNamePlaceholder: {
        id: "registerPage.firstName.placeholder",
        defaultMessage: "First name",
      },
      firstNameRequired: {
        id: "registerPage.firstName.required",
        defaultMessage: "First name is required",
      },
      lastNamePlaceholder: {
        id: "registerPage.lastName.placeholder",
        defaultMessage: "Last name",
      },
      lastNameRequired: {
        id: "registerPage.lastName.required",
        defaultMessage: "Last name is required",
      },
      emailPlaceholder: {
        id: "registerPage.email.placeholder",
        defaultMessage: "e-mail",
      },
      emailRequired: {
        id: "registerPage.email.required",
        defaultMessage: "e-mail is required",
      },
      emailInvalid: {
        id: "registerPage.email.invalid",
        defaultMessage: "The entered e-mail is invalid",
      },
      passwordPlaceholder: {
        id: "registerPage.password.placeholder",
        defaultMessage: "Password",
      },
      passwordRequired: {
        id: "registerPage.password.required",
        defaultMessage: "You must enter a password",
      },
      confirmationPasswordPlaceholder: {
        id: "registerPage.confirmationPassword.placeholder",
        defaultMessage: "Confirm password",
      },
      confirmationPasswordRequired: {
        id: "registerPage.confirmationPassword.required",
        defaultMessage:
          "Please confirm password to make sure mistakes were not made",
      },
    })
  );
  const [form] = useForm();
  const { history } = useRouter();

  const [registerMutation, { loading: submissionLoading }] = useMutation(
    REGISTER_MUTATION,
    {
      client: publicClient,
      onCompleted: (data) => {
        history.push(getAccountLink());
        const user = getUser(data);
        message.success(
          <FormattedMessage
            id="registerPage.welcomToEYK"
            defaultMessage="Welcome to EYK {user}"
            values={{ user: user.nameOrEmail }}
          />
        );
      },
      onError: form.setFieldsValidationErrors,
    }
  );
  return (
    <Card style={{ width: "400px" }}>
      <Form
        form={form}
        size="large"
        onFinish={(variables) => {
          registerMutation({
            variables,
            refetchQueries,
          });
        }}
      >
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: messages.firstNameRequired() }]}
          hasFeedback
        >
          <Input
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder={messages.firstNamePlaceholder()}
          />
        </Form.Item>
        <Form.Item
          name="lastName"
          rules={[{ required: true, message: messages.lastNameRequired() }]}
          hasFeedback
        >
          <Input
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder={messages.lastNamePlaceholder()}
          />
        </Form.Item>
        <Padding top="24px" />
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: messages.emailRequired(),
            },
            {
              type: "email",
              message: messages.emailInvalid(),
            },
          ]}
          hasFeedback
        >
          <Input
            prefix={<MailOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            type="email"
            placeholder={messages.emailPlaceholder()}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: messages.passwordRequired() }]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder={messages.passwordPlaceholder()}
          />
        </Form.Item>
        <Form.Item
          name="confirmationPassword"
          rules={[
            {
              required: true,
              message: messages.confirmationPasswordRequired(),
            },
          ]}
          hasFeedback
        >
          <Input.Password
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder={messages.confirmationPasswordPlaceholder()}
          />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            block
            type="primary"
            htmlType="submit"
            loading={submissionLoading}
          >
            <FormattedMessage
              id="registerPage.submit"
              defaultMessage="Register"
            />
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

const RegisterPage = ({ refetchQueries }) => {
  const messages = useTranslations(
    defineMessages({
      pageTitle: { id: "register.pageTitle", defaultMessage: "Register" },
      title: { id: "register.title", defaultMessage: "Sign up for EYK" },
    })
  );

  useDocumentTitle(messages.pageTitle());

  return (
    <PageTemplate
      header={<LogoPageHeader />}
      content={
        <Flex justifyContent="center">
          <div>
            <Margin bottom="16px">
              <CenteredText>
                <Typography.Title style={{ fontWeight: 200 }}>
                  {messages.title()}
                </Typography.Title>
              </CenteredText>
            </Margin>
            <RegisterForm refetchQueries={refetchQueries} />
            <Flex justifyContent="center">
              <Margin betweenChildren="16px" top="16px">
                <span>
                  <Typography.Text type="secondary">
                    <FormattedMessage
                      id="registerPage.alreadyRegistered.text"
                      defaultMessage="Already registered?"
                    />
                  </Typography.Text>
                  &nbsp;
                  <Link to="/login">
                    <FormattedMessage
                      id="registerPage.login"
                      defaultMessage="Login"
                    />
                  </Link>
                </span>
              </Margin>
            </Flex>
          </div>
        </Flex>
      }
    />
  );
};

RegisterPage.propTypes = {
  refetchQueries: PropTypes.arrayOf(
    PropTypes.shape({
      query: PropTypes.any.isRequired,
      variables: PropTypes.object.isRequired,
    })
  ),
};

export default RegisterPage;

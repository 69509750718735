import compose from "./compose";
import curry from "./curry";
import flip from "./flip";
import merge from "./merge";
import qsParse from "./qsParse";
import qsStringify from "./qsStringify";

// {k: v} -> String -> String
const qsUpdate = (obj, querystring) =>
  compose(qsStringify, flip(merge)(obj), qsParse)(querystring);

export default curry(qsUpdate);

import React from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";
import styled from "styled-components";

import { isNotNil, repeat } from "../misc/fp";

import List from "./List";
import Button from "./Button";
import { FormattedMessage } from "react-intl";
import { APOLLO_NETWORK_STATUS } from "../misc/constants";

const ScrollContainer = styled.div`
  height: 100%;
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  overflow-y: auto;
`;

const PaginatedList = ({
  dataSource,
  fetchMore,
  hasMore,
  loading,
  networkStatus,
  pageSize,
  children,
  width,
  ...rest
}) => {
  // Do not show loading when polling and network status is provided
  const showLoading = isNotNil(networkStatus)
    ? loading && networkStatus !== APOLLO_NETWORK_STATUS.poll
    : loading;

  // Failsafe load more button, in case page size is too small to make list scroll
  const loadMore =
    hasMore && !showLoading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          marginBottom: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={fetchMore}>
          <FormattedMessage
            id="paginatedList.loadMoreButton"
            defaultMessage="Load more"
          />
        </Button>
      </div>
    ) : null;

  return (
    <ScrollContainer width={width}>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={fetchMore}
        hasMore={!loading && hasMore}
        threshold={500} //px
        useWindow={false}
      >
        <List
          dataSource={
            showLoading
              ? [...dataSource, ...repeat({ loading: true }, pageSize)]
              : dataSource
          }
          loadMore={loadMore}
          {...rest}
        >
          {(item) => (item.loading ? <List.Item loading /> : children(item))}
        </List>
      </InfiniteScroll>
    </ScrollContainer>
  );
};

PaginatedList.Item = List.Item;
PaginatedList.Header = List.Header;

PaginatedList.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchMore: PropTypes.func,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  networkStatus: PropTypes.number,
  pageSize: PropTypes.number,
  children: PropTypes.func.isRequired,
  width: PropTypes.string,
};

PaginatedList.defaultProps = {
  fetchMore: undefined,
  hasMore: undefined,
  loading: false,
  pageSize: 10,
  width: "100%",
};

export default PaginatedList;

import { compose } from "../../misc/fp";
import { setLang, setColors, setYAxis, setXAxis } from "./helpers";
import messages from "./messages";
// Zoom

// Language
const lang = (intl) => ({
  contextButtonTitle: intl.formatMessage(messages.configurations),
  decimalPoint: intl.formatMessage(messages.decimalPoint),
  drillUpText: `${intl.formatMessage(messages.drillUpText)} {series.name}`,
  loading: `${intl.formatMessage(messages.loading)}...`,
  months: [
    intl.formatMessage(messages.january),
    intl.formatMessage(messages.february),
    intl.formatMessage(messages.march),
    intl.formatMessage(messages.april),
    intl.formatMessage(messages.may),
    intl.formatMessage(messages.june),
    intl.formatMessage(messages.july),
    intl.formatMessage(messages.august),
    intl.formatMessage(messages.september),
    intl.formatMessage(messages.october),
    intl.formatMessage(messages.november),
    intl.formatMessage(messages.december),
  ],
  noData: intl.formatMessage(messages.noData),
  numericSymbols: ["k", "M", "G", "T", "P", "E"],
  resetZoom: intl.formatMessage(messages.zoomOut),
  resetZoomTitle: `${intl.formatMessage(messages.resetZoom)} 1:1`,
  shortMonths: [
    intl.formatMessage(messages.jan),
    intl.formatMessage(messages.feb),
    intl.formatMessage(messages.mar),
    intl.formatMessage(messages.apr),
    intl.formatMessage(messages.may),
    intl.formatMessage(messages.jun),
    intl.formatMessage(messages.jul),
    intl.formatMessage(messages.aug),
    intl.formatMessage(messages.sep),
    intl.formatMessage(messages.oct),
    intl.formatMessage(messages.nov),
    intl.formatMessage(messages.dec),
  ],
  thousandsSep: intl.formatMessage(messages.thousandsSeparator),
  weekdays: [
    intl.formatMessage(messages.sunday),
    intl.formatMessage(messages.monday),
    intl.formatMessage(messages.tuesday),
    intl.formatMessage(messages.wednesday),
    intl.formatMessage(messages.thursday),
    intl.formatMessage(messages.friday),
    intl.formatMessage(messages.saturday),
  ],
});

export const globalOptions = ({
  intl,
  colorPalette,
  xAxisDefaults,
  yAxisDefaults,
}) =>
  compose(
    setLang(lang(intl)),
    setColors(colorPalette),
    setXAxis(xAxisDefaults),
    setYAxis(yAxisDefaults)
  )({});

import { List as AntList, Skeleton } from "antd";
import styled from "styled-components";
import { color } from "../../constants";
import useToggle from "../hooks/useToggle";
import PropTypes from "prop-types";
import React from "react";
import Flex from "./Flex";
import FlagDot from "./FlagDot";

const StyledListItem = styled(AntList.Item)`
  position: relative;
  padding: 12px 20px !important;
  background-color: ${(props) => props.active && color.background};
  cursor: ${(props) => props.clickable && "pointer"};
  transition: background-color 300ms ease;

  &:hover {
    background-color: ${(props) =>
      props.clickable && (props.active ? "#dff0f8" : "#f8f8f8")};
  }

  &:focus-within {
    background-color: ${(props) =>
      props.clickable && (props.active ? "#dff0f8" : "#f8f8f8")};
  }

  & .ant-list-item-action {
    opacity: ${(props) =>
      props.showActionsOnHover && !props.hovering ? "0" : "1"} !important;
    transition: opacity 300ms ease;
    transition-delay: 200ms;
  }

  & .list-item-children-container {
    opacity: ${(props) =>
      props.showChildrenOnHover && !props.hovering ? "0" : "1"} !important;
    transition: opacity 300ms ease;
    transition-delay: 200ms;

    & > *:first-child {
      margin-left: 16px; // pad first direct child to create separation from item meta
    }
  }
  & .ant-list-item-meta-title {
    white-space: nowrap;
  }
  & .ant-list-item-extra {
    opacity: ${(props) =>
      props.showExtraOnHover && !props.hovering ? "0" : "1"} !important;
    transition: opacity 300ms ease;
    transition-delay: 200ms;
  }

  & .title-actions {
    opacity: ${(props) =>
      props.showTitleActionsOnHover && !props.hovering ? "0" : "1"} !important;
    transition: opacity 300ms ease;
    transition-delay: 200ms;
  }
`;

const TitleActions = ({ title, actions }) => (
  <Flex justifyContent="space-between" alignItems="center">
    {title}
    <span className="title-actions">{actions}</span>
  </Flex>
);

const ListItem = ({
  actions,
  clickable,
  loading,
  avatar,
  title,
  subtitle,
  titleActions,
  description,
  flag = false,
  flagTitle,
  children,
  showActionsOnHover,
  showChildrenOnHover,
  showExtraOnHover,
  showTitleActionsOnHover,
  ...rest
}) => {
  const [hovering, , hoverOff, hoverOn] = useToggle(false);
  return (
    <StyledListItem
      actions={actions}
      clickable={clickable}
      hovering={hovering}
      showActionsOnHover={showActionsOnHover}
      showChildrenOnHover={showChildrenOnHover}
      showExtraOnHover={showExtraOnHover}
      showTitleActionsOnHover={showTitleActionsOnHover}
      onMouseEnter={hoverOn}
      onMouseLeave={hoverOff}
      {...rest}
    >
      <Skeleton active loading={loading} paragraph={false}>
        <AntList.Item.Meta
          avatar={avatar}
          title={[
            titleActions ? (
              <TitleActions title={title} actions={titleActions} />
            ) : (
              title
            ),
            subtitle ? (
              <span
                style={{
                  marginLeft: "8px",
                  fontSize: "0.75rem",
                  color: "rgba(0,0,0,0.45)",
                }}
              >
                {subtitle}
              </span>
            ) : undefined,
          ]}
          description={description}
        />
        <span className="list-item-children-container">{children}</span>
      </Skeleton>
      <FlagDot reason={flagTitle} show={flag} />
    </StyledListItem>
  );
};

ListItem.propTypes = {
  avatar: PropTypes.node,
  actions: PropTypes.node,
  flag: PropTypes.bool,
  flagTitle: PropTypes.string,
  title: PropTypes.node,
  description: PropTypes.node,
  clickable: PropTypes.bool,
};

const StyledHeader = styled(AntList.Item)`
  background-color: #fafafa;
  border-bottom: 1px solid #eaeaea;
  padding: 17px 20px !important;

  & > .ant-list-item-meta {
    align-items: center;

    & .ant-list-item-meta-title {
      margin-bottom: 0;
    }
  }
`;

const Header = ({ avatar, title, description, ...props }) => (
  <StyledHeader {...props}>
    <AntList.Item.Meta
      avatar={avatar}
      title={title}
      description={description}
    />
  </StyledHeader>
);

const List = ({ children, ...props }) => {
  return <AntList renderItem={(item) => children(item)} {...props} />;
};

List.Item = ListItem;
List.Header = Header;
List.Item.Meta = AntList.Item.Meta;

export default List;

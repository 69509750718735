import gql from "graphql-tag";

const resultsKey = "confirmPasswordReset";

export default gql`
  mutation ConfirmPasswordReset( $password: String!, $confirmationPassword: String!, $token: String!, $uid: String! ) {
    ${resultsKey}(input: { 
        password: $password,
        confirmationPassword: $confirmationPassword,
        token: $token,
        uid: $uid
    }) {
  message
    }
  }
`;

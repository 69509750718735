import gql from "graphql-tag";

export default gql`
  query DashboardBreadcrumbQuery($id: ID!) {
    item: dashboard(id: $id) {
      id
      description: name
    }
  }
`;

import gql from "graphql-tag";
import { lensPath, view } from "../misc/fp";

const messageLens = lensPath(["logout", "message"]);

export const getMessage = view(messageLens);

export default gql`
  mutation Logout {
    logout {
      message
    }
  }
`;

// These functions extend the default json parsing library to account for infinity and
// negative infinity.
// Also gives them the arity of 1 which makes them usable in chains and composes

const replacer = (key, value) => {
  switch (value) {
    case Infinity:
      return "Infinity";
    case -Infinity:
      return "-Infinity";
    default:
      return value;
  }
};

const reviver = (key, value) => {
  switch (value) {
    case "Infinity":
      return Infinity;
    case "-Infinity":
      return -Infinity;
    default:
      return value;
  }
};

export const stringify = (v) => window.JSON.stringify(v, replacer);
export const parse = (v) => window.JSON.parse(v, reviver);

export default {
  stringify,
  parse,
};

import React from "react";
import PropTypes from "prop-types";
import Input from "../../Input.jsx";
import { SearchOutlined } from "@ant-design/icons";
import useState from "../../../hooks/useState";
import { isNotNil } from "../../../misc/fp";
import styled from "styled-components";

import { PRIMARY_COLOR } from "./constants";

const StyledSearchInput = styled(Input)`
  border-radius: 12px;
  border-color: ${(props) => props.hasValue && PRIMARY_COLOR};
  & > input {
    color: ${(props) => props.hasValue && PRIMARY_COLOR};
  }
`;

const SearchInput = React.forwardRef(
  ({ handleChange, value, ...props }, ref) => {
    const [hasValue, setHasValue] = useState(isNotNil(value));
    return (
      <StyledSearchInput
        suffix={
          <SearchOutlined
            style={{ color: hasValue ? PRIMARY_COLOR : "rgba(0,0,0,.45)" }}
          />
        }
        {...props}
        ref={ref}
        hasValue={hasValue}
        onChange={(e) => {
          handleChange(e);
          setHasValue(e.target.value);
        }}
      />
    );
  }
);

SearchInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  hasValue: PropTypes.bool.isRequired,
  setHasValue: PropTypes.func.isRequired,
};

export default SearchInput;

import React from "react";
import styled from "styled-components";
import { Spin } from "antd";
import LoadingIcon from "../LoadingIcon";

import SpinCenter from "./SpinCenter";
import SpinCenterHorizontal from "./SpinCenterHorizontal";
import SpinCenterOfPageLarge from "./SpinCenterOfPageLarge";
import SpinMessage from "./SpinMessage";

const DEFAULT_DELAY = 500; // ms

Spin.setDefaultIndicator(<LoadingIcon />);
const SpinBottom = styled(Spin)`
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
`;

SpinBottom.defaultProps = {
  delay: DEFAULT_DELAY,
};
SpinCenter.defaultProps = {
  delay: DEFAULT_DELAY,
};
SpinCenterHorizontal.defaultProps = {
  delay: DEFAULT_DELAY,
};
SpinCenterOfPageLarge.defaultProps = {
  delay: DEFAULT_DELAY,
};
SpinMessage.defaultProps = {
  delay: DEFAULT_DELAY,
};
SpinBottom.defaultProps = {
  delay: DEFAULT_DELAY,
};

Spin.Center = SpinCenter;
Spin.Horizontal = SpinCenterHorizontal;
Spin.Page = SpinCenterOfPageLarge;
Spin.Message = SpinMessage;
Spin.Bottom = SpinBottom;

export default Spin;

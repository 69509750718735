import gql from "graphql-tag";

const resultsKey = "resetPassword";

export default gql`
  mutation ResetPassword( $email: String! ) {
    ${resultsKey}(input: { 
        email: $email
    }) {
  message
    }
  }
`;

import "font-awesome/css/font-awesome.min.css";
import React from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import {urlPrefix} from "./constants";
import EYK from "./apps/EYK";
import RegisterPage, * as registerPage from "./screens/RegisterPage";
import ResetPasswordPage, * as resetPasswordPage from "./screens/ResetPasswordPage";
import ErrorBoundary from "./shared/components/ErrorBoundary";
import Route from "./shared/components/Route";
import Providers from "./shared/containers/Providers";
import "antd/dist/antd.less"

const AppWithProviders = () => (
  <Providers>
    <BrowserRouter basename={urlPrefix}>
      <ErrorBoundary>
        <Switch>
          {/* ensure all paths without trailing slash are redirected to one */}
          <Route
            path="/:url*"
            exact
            strict
            render={(routeProps) => (
              <Redirect
                to={`${routeProps.location.pathname}/${routeProps.location.search}`}
              />
            )}
          />
          <Route path={registerPage.path}>
            <RegisterPage />
          </Route>
          <Route path={resetPasswordPage.path}>
            <ResetPasswordPage />
          </Route>
          <Route>
            <EYK />
          </Route>
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  </Providers>
);

export default AppWithProviders;

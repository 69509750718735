import PageTemplate from "../shared/templates/PageTemplate";
import PageHeader from "../shared/components/PageHeader";
import Spin from "../shared/components/Spin";
import React from "react";

const LoadingPage = () => (
  <PageTemplate header={<PageHeader loading />} content={<Spin.Page />} />
);

export default LoadingPage;

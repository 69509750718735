import PropTypes from "prop-types";
import React from "react";
import { ApolloProvider } from "react-apollo";

import apolloClient from "../../../services/client";

const StoreProvider = ({ children }) => (
  <ApolloProvider client={apolloClient}>
    {React.Children.only(children)}
  </ApolloProvider>
);

StoreProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default StoreProvider;

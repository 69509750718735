import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Layout from "../components/Layout";
import Spin from "../components/Spin";

const { Content } = Layout;

export const PAGE_CONTENT_PADDING = 24;
const HeaderWrapper = styled.div``;
const FooterWrapper = styled.div``;
const ContentWrapper = styled.div`
  padding: ${PAGE_CONTENT_PADDING}px;
  display: flex;
  flex: 1;
  position: relative;

  & > * {
    flex: auto;
    width: 100%;
  }
`;
const ScrollableContent = styled(Content)`
  min-height: ${props => (props.scrollContent ? "min-content" : 0)};
  overflow: ${props => (props.scrollContent ? "auto" : "hidden")};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PageTemplate = ({ header, content, footer, scrollContent, loading }) => (
  <Layout style={{ height: "100%" }}>
    <ScrollableContent scrollContent={scrollContent}>
      <HeaderWrapper>{header}</HeaderWrapper>
      <ContentWrapper>{loading ? <Spin.Horizontal /> : content}</ContentWrapper>
      <FooterWrapper>{footer}</FooterWrapper>
    </ScrollableContent>
  </Layout>
);
PageTemplate.propTypes = {
  header: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  scrollContent: PropTypes.bool,
  loading: PropTypes.bool,
};

PageTemplate.defaultProps = {
  scrollContent: true,
  loading: false,
};

export default PageTemplate;

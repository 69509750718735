import {
  MonitorOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React from "react";
import { FormattedMessage } from "react-intl";
import { getAccountLink } from "../../shared/components/AccountLink";
import {
  COLOR_PALETTE,
  DATA_TYPE_UUID,
  DATASET_TYPE_UUID,
  ICON_TYPE_BY_DATA_TYPE,
} from "../../shared/misc/constants";

export const SIZE_MARGIN = 5;
export const SIZE_SMALL = 200;
export const SIZE_LARGE = 2 * SIZE_SMALL + 2 * SIZE_MARGIN;
export const COLUMN_SMALL = SIZE_SMALL + 2 * SIZE_MARGIN;
export const COLUMN_LARGE = SIZE_LARGE + 2 * SIZE_MARGIN;

export const FACES = {
  AssetOverview: {
    type: "AssetOverviewFace",
    description: (
      <FormattedMessage
        id="faces.assetOverview"
        description="faces.assetOverview"
        defaultMessage="Assets"
      />
    ),
    subDescription: (
      <FormattedMessage
        id="faces.assetOverview.subDescription"
        defaultMessage="An overview of measured entities"
      />
    ),
    icon: ICON_TYPE_BY_DATA_TYPE[DATA_TYPE_UUID.ASSET],
    large: true,
    focus: true,
    action: { to: "/assets/" },
    background: "/core/images/AssetOverviewFace.png",
  },
  Datasets: {
    type: "DatasetsFace",
    description: (
      <FormattedMessage
        id="faces.datasets"
        description="faces.datasets"
        defaultMessage="Datasets"
      />
    ),
    subDescription: (
      <FormattedMessage
        id="faces.datasetOverview.subDescription"
        defaultMessage="Add, manage or analyse data"
      />
    ),
    large: true,
    focus: true,
    icon: ICON_TYPE_BY_DATA_TYPE[DATA_TYPE_UUID.DATASET],
    color: COLOR_PALETTE["primary-1"],
    action: { to: "/datasets/" },
  },
  TimeSeries: {
    type: "TimeSeriesFace",
    description: (
      <FormattedMessage
        id="faces.timeseries"
        description="faces.timeseries"
        defaultMessage="Time series"
      />
    ),
    subDescription: (
      <FormattedMessage
        id="faces.timeSeries.subDescription"
        defaultMessage="Browse time series across datasets"
      />
    ),
    focus: true,
    icon: ICON_TYPE_BY_DATA_TYPE[DATA_TYPE_UUID.TIME_SERIES],
    color: COLOR_PALETTE["primary-3"],
    action: { to: "/time-series/" },
  },
  QuakeMap: {
    type: "QuakeMapFace",
    description: (
      <FormattedMessage
        id="faces.quakes"
        description="faces.quakes"
        defaultMessage="Earthquakes"
      />
    ),
    subDescription: (
      <FormattedMessage
        id="faces.quakes.subDescription"
        defaultMessage="View earthquakes across datasets"
      />
    ),
    focus: true,
    icon: ICON_TYPE_BY_DATA_TYPE[DATA_TYPE_UUID.EARTHQUAKE],
    color: COLOR_PALETTE["primary-0"],
    action: { to: "/quakes/" },
    datasetType: DATASET_TYPE_UUID.EARTHQUAKE,
  },
  ImageMap: {
    type: "ImageMapFace",
    description: (
      <FormattedMessage
        id="faces.imageMap"
        description="faces.imageMap"
        defaultMessage="Image map"
      />
    ),
    icon: ICON_TYPE_BY_DATA_TYPE[DATA_TYPE_UUID.IMAGE],
    color: COLOR_PALETTE["complement-1"],
    action: { to: "/imageMap/" },
    datasetType: DATASET_TYPE_UUID.IMAGE,
    // },
    // BusMap: {
    //   type: "BusMapFace",
    //   description: (
    //     <FormattedMessage
    //       id="faces.busMap"
    //       description="faces.busMap"
    //       defaultMessage="Bus map"
    //     />
    //   ),
    //   icon: "bus",
    //   color: COLOR_PALETTE["primary-4"],
    //   action: { to: "/busSpeed/" }
  },
  DataConnections: {
    type: "DataConnectionFace",
    description: (
      <FormattedMessage
        id="faces.dataConnection"
        defaultMessage="Data connections"
      />
    ),
    icon: ICON_TYPE_BY_DATA_TYPE[DATA_TYPE_UUID.DATA_CONNECTION],
    color: COLOR_PALETTE["complement-0"],
    action: { to: "/data-connections/" },
  },
  ExternalDatasets: {
    type: "ExternalDatasetsFace",
    description: (
      <FormattedMessage
        id="faces.externalDatasets"
        description="faces.externalDatasets"
        defaultMessage="External datasets"
      />
    ),
    icon: ICON_TYPE_BY_DATA_TYPE[DATA_TYPE_UUID.EXTERNAL_DATASET],
    color: COLOR_PALETTE["primary-4"],
    action: { to: "/external-datasets/" },
  },
  DataMonitor: {
    type: "DashboardsFace",
    description: (
      <FormattedMessage
        id="faces.dashboards"
        description="faces.dashboards"
        defaultMessage="Dashboards"
      />
    ),
    icon: ICON_TYPE_BY_DATA_TYPE[DATA_TYPE_UUID.DASHBOARD],
    color: COLOR_PALETTE["complement-0"],
    subDescription: (
      <FormattedMessage
        id="faces.dashboards.subDescription"
        defaultMessage="Live data dashboards"
      />
    ),
    large: true,
    focus: true,
    action: { to: "/dashboards/" },
  },
  Charts: {
    type: "ChartsFace",
    description: (
      <FormattedMessage
        id="faces.charts"
        description="faces.charts"
        defaultMessage="Charts"
      />
    ),
    subDescription: (
      <FormattedMessage
        id="faces.charts.subDescription"
        defaultMessage="Create and save your custom charts"
      />
    ),
    large: true,
    focus: true,
    icon: ICON_TYPE_BY_DATA_TYPE[DATA_TYPE_UUID.CHART],
    color: COLOR_PALETTE["primary-4"],
    action: { to: "/charts/" },
  },
  RegistryOverview: {
    type: "RegistryOverviewFace",
    description: (
      <FormattedMessage
        id="faces.registries"
        description="faces.registries"
        defaultMessage="Registries"
      />
    ),
    // subDescription: (
    //   <FormattedMessage
    //     id="faces.registries.subDescription"
    //     defaultMessage="An overview of all registries"
    //   />
    // ),
    icon: ICON_TYPE_BY_DATA_TYPE[DATA_TYPE_UUID.REGISTRY],
    color: COLOR_PALETTE["complement-0"],
    action: { to: "/registries/" },
  },
  DataInput: {
    type: "DataInputFace",
    description: (
      <FormattedMessage
        id="faces.addData"
        description="faces.addData"
        defaultMessage="Upload"
      />
    ),
    // subDescription: (
    //   <FormattedMessage
    //     id="faces.dataInput.subDescription"
    //     defaultMessage="An overview of all registries"
    //   />
    // ),
    icon: <UploadOutlined />,
    color: COLOR_PALETTE["primary-2"],
    action: { to: "/add-data/" },
  },
  Account: {
    type: "UserOverviewFace",
    description: (
      <FormattedMessage
        id="faces.userOverview"
        description="faces.account"
        defaultMessage="Account"
      />
    ),
    icon: <UserOutlined />,
    color: COLOR_PALETTE["primary-0"],
    action: { to: getAccountLink() },
  },
  Monitors: {
    type: "MonitorFace",
    description: (
      <FormattedMessage
        id="faces.monitors"
        description="faces.monitors"
        defaultMessage="Monitors"
      />
    ),
    icon: <MonitorOutlined />,
    color: COLOR_PALETTE["primary-0"],
    action: { to: "/monitor/" },
  },
};

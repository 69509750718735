import React from "react";

import PARAMETER_BREADCRUMB_QUERY from "../../shared/queries/ParameterProfilePageBreadcrumbQuery";
import SAMPLE_BREADCRUMB_QUERY from "../../shared/queries/SampleBreadcrumbQuery";
import TIME_SERIES_BREADCRUMB_QUERY from "../../shared/queries/TimeSeriesBreadcrumbQuery";
import WELL_TEST_BREADCRUMB_QUERY from "../../shared/queries/WellTestBreadcrumbQuery";
import breadcrumbTranslations from "./breadcrumbTranslations";

const AssetOverview = React.lazy(() => import("../../screens/AssetOverview"));
const ImageMap = React.lazy(() => import("../../screens/ImageMap"));
const WellTestChartPage = React.lazy(() =>
  import("../../screens/WellTestsChartPage")
);
const WellTestsPage = React.lazy(() => import("../../screens/WellTestsPage"));
const ParameterValueDataTablePage = React.lazy(() =>
  import("../../screens/ParameterValueDataTablePage")
);
const ParametersPage = React.lazy(() =>
  import("../../shared/components/ParametersPage")
);
const QuakeMap = React.lazy(() => import("../../screens/QuakeMap"));
const SampleProfile = React.lazy(() => import("../../screens/SampleProfile"));
const SampleTemplatesPage = React.lazy(() =>
  import("../../shared/components/SampleTemplatesPage")
);
const SamplesPage = React.lazy(() =>
  import("../../shared/components/SamplesPage")
);
const SamplesParameterProfile = React.lazy(() =>
  import("../../screens/SamplesParameterProfile")
);
const SamplesParametersChartPage = React.lazy(() =>
  import("../../screens/SamplesParametersChartPage")
);
const SamplesParametersPage = React.lazy(() =>
  import("../../shared/components/SamplesParametersPage")
);
const TimeSeriesChartPage = React.lazy(() =>
  import("../../screens/TimeSeriesChartPage")
);
const TimeSeriesPage = React.lazy(() =>
  import("../../shared/components/TimeSeriesPage")
);
const TimeSeriesProfile = React.lazy(() =>
  import("../../screens/TimeSeriesProfile")
);
const TracerParametersChartPage = React.lazy(() =>
  import("../../screens/TracerParametersChartPage")
);
const TracerParametersPage = React.lazy(() =>
  import("../../shared/components/TracerParametersPage")
);
const WellTestProfile = React.lazy(() =>
  import("../../screens/WellTestProfile")
);

export const measurementSeriesModule = (basePath) => [
  {
    path: `${basePath}chart/`,
    breadcrumb: {
      description: breadcrumbTranslations.chart,
    },
    route: {
      component: WellTestChartPage,
    },
  },
  {
    path: `${basePath}:wellTestId`,
    breadcrumb: {
      descriptionQuery: WELL_TEST_BREADCRUMB_QUERY,
      queryVariables: { id: "wellTestId" },
      fallbackDescription: "wellTests",
    },
    route: {
      component: WellTestProfile,
    },
  },
];

export const measurementSeriesDataset = (basePath) => [
  ...measurementSeriesModule(`${basePath}series/`),
  {
    path: `${basePath}series/`,
    breadcrumb: {
      description: breadcrumbTranslations.wellTests,
    },
    route: {
      component: WellTestsPage,
    },
  },
];

export const timeSeriesModule = (basePath) => [
  {
    path: `${basePath}chart/`,
    breadcrumb: {
      description: breadcrumbTranslations.chart,
    },
    route: {
      component: TimeSeriesChartPage,
    },
  },
  {
    path: `${basePath}:timeSeriesId`,
    breadcrumb: {
      descriptionQuery: TIME_SERIES_BREADCRUMB_QUERY,
      queryVariables: { id: "timeSeriesId" },
      fallbackDescription: "timeSeries",
    },
    route: {
      component: TimeSeriesProfile,
    },
  },
];

export const timeSeriesDataset = (basePath) => [
  ...timeSeriesModule(`${basePath}series/`),
  {
    path: `${basePath}series/`,
    breadcrumb: {
      description: breadcrumbTranslations.timeSeries,
    },
    route: {
      component: TimeSeriesPage,
    },
  },
  {
    path: `${basePath}parameters/chart/`,
    breadcrumb: {
      description: breadcrumbTranslations.chart,
    },
    route: {
      component: TimeSeriesChartPage,
    },
  },
  {
    path: `${basePath}parameters/`,
    breadcrumb: {
      description: breadcrumbTranslations.parameters,
    },
    route: {
      component: ParametersPage,
    },
  },
];

export const sampleModule = (basePath) => [
  {
    path: `${basePath}parameters/chart/`,
    breadcrumb: {
      description: breadcrumbTranslations.chart,
    },
    route: {
      component: SamplesParametersChartPage,
    },
  },
  {
    path: `${basePath}table/`,
    breadcrumb: {
      description: breadcrumbTranslations.table,
    },
    route: {
      component: ParameterValueDataTablePage,
    },
  },
  {
    path: `${basePath}parameters/`,
    breadcrumb: {
      description: breadcrumbTranslations.parameters,
    },
    route: {
      component: SamplesParametersPage,
    },
  },
  {
    path: `${basePath}:sampleId/`,
    breadcrumb: {
      descriptionQuery: SAMPLE_BREADCRUMB_QUERY,
      queryVariables: { id: "sampleId" },
      fallbackDescription: "Sample",
    },
    route: {
      component: SampleProfile,
    },
  },
];

export const sampleDataset = (basePath) => [
  ...sampleModule(`${basePath}samples/`),
  {
    path: `${basePath}samples/`,
    breadcrumb: {
      description: breadcrumbTranslations.samples,
    },
    route: {
      component: SamplesPage,
    },
  },
  {
    path: `${basePath}parameters/chart/`,
    breadcrumb: {
      description: breadcrumbTranslations.chart,
    },
    route: {
      component: SamplesParametersChartPage,
    },
  },
  {
    path: `${basePath}parameters/:parameterId/`,
    breadcrumb: {
      descriptionQuery: PARAMETER_BREADCRUMB_QUERY,
      queryVariables: { id: "parameterId" },
      fallbackDescription: "Parameter",
    },
    route: {
      component: SamplesParameterProfile,
    },
  },
  {
    path: `${basePath}parameters/`,
    breadcrumb: {
      description: breadcrumbTranslations.parameters,
    },
    route: {
      component: SamplesParametersPage,
    },
  },
  {
    path: `${basePath}templates/`,
    breadcrumb: {
      description: breadcrumbTranslations.templates,
    },
    route: { component: SampleTemplatesPage },
  },
  {
    path: `${basePath}tracer-parameters/chart/`,
    breadcrumb: {
      description: breadcrumbTranslations.chart,
    },
    route: {
      component: TracerParametersChartPage,
    },
  },
  {
    path: `${basePath}tracer-parameters/`,
    breadcrumb: {
      description: breadcrumbTranslations.tracerparameters,
    },
    route: {
      component: TracerParametersPage,
    },
  },
];

export const imageDataset = (basePath) => [
  {
    path: `${basePath}images/`,
    breadcrumb: {
      description: breadcrumbTranslations.images,
    },
    route: {
      component: ImageMap,
    },
  },
];

export const quakeDataset = (basePath) => [
  {
    path: `${basePath}quakes/`,
    breadcrumb: {
      description: breadcrumbTranslations.quakes,
    },
    route: {
      component: QuakeMap,
    },
  },
];

export const assetDataset = (basePath) => [
  {
    path: `${basePath}assets/`,
    breadcrumb: {
      description: breadcrumbTranslations.assets,
    },
    route: {
      component: AssetOverview,
    },
  },
];
